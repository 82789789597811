import {
  TableCell,
  TableRow,
  Typography,
  Chip,
  Button,
  MenuItem,
  Checkbox,
} from "@mui/material";
import classes from "./student.module.css";
import React, { useState, useEffect } from "react";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  EditPenIcon,
  InactiveItemIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { StyledMenu } from "../../../Components/CommonMenu";
import SchoolActionModals from "../../../Components/SchoolActionModals";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  changeStudentStatus,
  deleteStudent,
  getAllStudents,
} from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";

const StudentTableListItem = ({ item, value, from, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState(0);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const open = Boolean(anchorEl);
  const { error, success, piechartData, teacherStudentPieChart } = useSelector(
    (state) => state.studentDatas
  );
  const { userInfo,userRole } = useSelector((state) => state.userLogin);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleActive = (event) => {
    handleClose(event);
    setType("active/inactive");
    setHeight("max-content");
    setModalToggle(true);
  };

  const handleDelete = (event) => {
    handleClose(event);
    setType("delete");
    setHeight("max-content");
    setModalToggle(true);
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleEditStudentDetails = (e) => {
    handleClose(e);
    dispatch(setHeaderName("edit-student-details"));
    navigate(`/edit-student-details/${item._id}`);
  };

  const handleNavDetailsPage = (e) => {
    if (e.target.type !== "checkbox") {
      dispatch(setHeaderName("view-student-details"));
      navigate(`/view-student-details/${item._id}`);
    }
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
    const userEntry = {
      schools: [userInfo?.schoolInfo?._id],
    };
    dispatch(getAllStudents(setErrorAlert, "", userEntry));
  };

  const handleChangeStatus = () => {
    const userEntry = {
      Id: item._id,
      isActive: !item.isActive,
    };
    console.log("UserEntry ==> ", userEntry);
    dispatch(pageLoader(true));
    const userEntry1 = {
      schools: [userInfo?.schoolInfo?._id],
    };
    dispatch(
      changeStudentStatus(
        item._id,
        userEntry,
        setErrorAlert,
        setSuccessAlert,
        piechartData,
        userEntry1,
        teacherStudentPieChart
      )
    );
    modalClose();
  };

  const handleDeleteStudent = (e) => {
    setModalToggle(false);
    dispatch(pageLoader(true));
    const userEntry1 = {
      schools: [userInfo?.schoolInfo?._id],
    };
    dispatch(
      deleteStudent(
        item._id,
        setSuccessAlert,
        setErrorAlert,
        userEntry1,
        piechartData,
        teacherStudentPieChart
      )
    );
    modalClose();
  };

  const handleChange = (event) => {
    event.stopPropagation();
    setChecked(event.target.checked);
  };

  useEffect(() => {
    // console.log(status);
    setChecked(status);
  }, [status]);

  return (
    <React.Fragment>
      <TableRow
        key={item._id}
        className={classes.TableBodyRow}
        onClick={handleNavDetailsPage}
      >
        {from === "TEACHER" && (
          <TableCell flex={1} className={classes.firstCell}>
            <Checkbox
              checked={checked}
              onChange={(e) => handleChange(e)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TableCell>
        )}
        <TableCell flex={1} className={classes.firstCell}>
          <Typography className={classes.TableBodyText}>
            {`${item.first_name} ${item.last_name}`}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
          >{`${item.age} Years`}</Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {item.gradeInfo?.grade?.charAt(0).toUpperCase() +
              item.gradeInfo?.grade
                ?.slice(1)
                .toLowerCase()
                .replaceAll("_", " ")}
          </Typography>
        </TableCell>
        {from !== "TEACHER" && (
          <TableCell flex={1}>
            <Typography className={classes.TableBodyText}>
              {value === 0 ? item.schoolInfo?.name : item.parentName}
            </Typography>
          </TableCell>
        )}
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {item.section?.charAt(0).toUpperCase() +
              item.section?.slice(1).toLowerCase().replaceAll("_", " ")}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item.parent.phone}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item.parent.email}
          </Typography>
        </TableCell>
        {from === "TEACHER" && (
          <TableCell flex={1}>
            <Typography className={classes.TableBodyText}>
              {item.subscriptionInfo.subscription_name}
            </Typography>
          </TableCell>
        )}
        <TableCell flex={1}>
          <Chip
            icon={item.isActive ? <ActiveItemIcon /> : <InactiveItemIcon />}
            style={{
              background: item.isActive
                ? "var(--active-chip-background)"
                : "var(--inactive-chip-background)",
            }}
            label={item.isActive ? "Active" : "Inactive"}
            className={classes.StatusChip}
          />
        </TableCell>
        <TableCell flex={1}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            { ( userRole === "SCHOOL" || userInfo?.permissions?.includes("student:edit")) &&
              <MenuItem
              onClick={handleEditStudentDetails}
              disableRipple
              style={{ height: "40px" }}
            >
              <EditPenIcon />
              <span className={classes.MenuItemText}>Edit Details</span>
            </MenuItem>}
          {  ( userRole === "SCHOOL" || userInfo?.permissions?.includes("student:delete")) &&
            <MenuItem
              onClick={handleDelete}
              disableRipple
              style={{ height: "40px" }}
            >
              <DeleteBinIcon />
              <span className={classes.MenuItemText}>Delete</span>
            </MenuItem>}
            <MenuItem
              onClick={handleActive}
              disableRipple
              style={{ height: "40px" }}
            >
              <SwitchIcon />
              <span className={classes.MenuItemText}>Active/Inactive</span>
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          modalClose={modalClose}
          item={item}
          name={`${item.first_name} ${item.last_name}`}
          callback={
            type === "delete" ? handleDeleteStudent : handleChangeStatus
          }
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </React.Fragment>
  );
};

export default StudentTableListItem;
