import React, { useEffect, useState } from "react";
import classes from "./teacher.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TableRow,
  TableCell,
  Typography,
  Chip,
  Button,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { StyledMenu } from "../../../Components/CommonMenu";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  EditPenIcon,
  InactiveItemIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import SchoolActionModals from "../../../Components/SchoolActionModals";
import AlertBox from "../../../Components/AlertBox";
import {
  changeTeacherStatus,
  deleteTeacher,
  getAllTeachers,
} from "../../../Redux/actions/teacherActions";

const TeacherTableListItem = ({ item, key, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [type, setType] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [height, setHeight] = useState(0);
  const open = Boolean(anchorEl);
  const [checked, setChecked] = useState(false);
  const { error, success, piechartData } = useSelector(
    (state) => state.teacherDatas
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    console.log(status);
    setChecked(status);
  }, [status]);

  const handleChange = (event) => {
    event.stopPropagation();
    setChecked(event.target.checked);
  };

  const handleActive = (event) => {
    handleClose(event);
    setType("active/inactive");
    setHeight(205);
    setModalToggle(true);
  };

  const handleDelete = (event) => {
    handleClose(event);
    setType("delete");
    setHeight(205);
    setModalToggle(true);
  };

  const handleChangeStatus = () => {
    const userEntry = {
      Id: item._id,
      isActive: !item.isActive,
    };
    console.log("UserEntry ==> ", userEntry);
    dispatch(pageLoader(true));
    dispatch(
      changeTeacherStatus(
        userEntry,
        setErrorAlert,
        setSuccessAlert,
        piechartData,
        userInfo?.schoolInfo?._id
      )
    );
    modalClose();
  };

  const handleEditTeacherDetails = (e) => {
    handleClose(e);
    dispatch(setHeaderName("edit-teacher-details"));
    navigate(`/edit-teacher-details/${item._id}`);
  };

  const handleDeleteTeacher = (e) => {
    dispatch(pageLoader(true));
    dispatch(
      deleteTeacher(
        item._id,
        setSuccessAlert,
        setErrorAlert,
        piechartData,
        userInfo?.schoolInfo?._id
      )
    );
    modalClose();
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
    dispatch(
      getAllTeachers(setErrorAlert, "", userInfo?.schoolInfo?._id, "select")
    );
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleClickRow = (e) => {
    if (e.target.type !== "checkbox") {
      dispatch(setHeaderName("view-teacher-details"));
      navigate(`/view-teacher-details/${item._id}`);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        key={item.id}
        className={classes.TableBodyRow}
        onClick={handleClickRow}
      >
        <TableCell flex={1} className={classes.firstCell}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.name}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.designationInfo?.name?.charAt(0).toUpperCase() +
              item?.designationInfo?.name
                ?.slice(1)
                .toLowerCase()
                .replaceAll("_", ".")}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.schoolInfo?.name}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.userInfo?.phone}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.userInfo?.email}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Chip
            icon={item.isActive ? <ActiveItemIcon /> : <InactiveItemIcon />}
            style={{
              background: item.isActive
                ? "var(--active-chip-background)"
                : "var(--inactive-chip-background)",
            }}
            label={item.isActive ? "Active" : "Inactive"}
            className={classes.StatusChip}
          />
        </TableCell>
        <TableCell flex={1}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleEditTeacherDetails}
              disableRipple
              style={{ height: "40px" }}
            >
              <EditPenIcon />
              <span className={classes.MenuItemText}>Edit Details</span>
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              disableRipple
              style={{ height: "40px" }}
            >
              <DeleteBinIcon />
              <span className={classes.MenuItemText}>Delete</span>
            </MenuItem>
            <MenuItem
              onClick={handleActive}
              disableRipple
              style={{ height: "40px" }}
            >
              <SwitchIcon />
              <span className={classes.MenuItemText}>Active/Inactive</span>
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          modalClose={modalClose}
          item={item}
          name={item.name}
          callback={
            type === "delete" ? handleDeleteTeacher : handleChangeStatus
          }
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </React.Fragment>
  );
};

export default TeacherTableListItem;
