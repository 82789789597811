import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import FunctionalHeader from "./functionalHeader";
import classes from "./layout.module.css";

const FunctioanlLayout = () => {
  return (
    <div>
      <div className={classes.functionalheaderMaindiv}>
        <FunctionalHeader />
      </div>
      <div className={classes.functionalBodyMaindiv}>
        <Outlet />
      </div>
    </div>
  );
};

export default FunctioanlLayout;
