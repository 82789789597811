module.exports = {
    ADD_TEACHER_SUCCESS: "ADD_TEACHER_SUCCESS",
    ADD_TEACHER_FAIL: "ADD_TEACHER_FAIL",
    GET_TEACHERS_SUCCESS: "GET_TEACHERS_SUCCESS",
    GET_TEACHERS_FAIL: "GET_TEACHERS_FAIL",
    GET_ONE_TEACHER_SUCCESS: "GET_ONE_TEACHER_SUCCESS",
    GET_ONE_TEACHER_FAIL: "GET_ONE_TEACHER_FAIL",
    TEACHER_PIECHART_SUCCESS: "TEACHER_PIECHART_SUCCESS",
    TEACHER_PIECHART_FAIL: "TEACHER_PIECHART_FAIL",
    GET_ALL_DESIGNATIONS_SUCCESS: "GET_ALL_DESIGNATIONS_SUCCESS",
    GET_ALL_DESIGNATIONS_FAIL: "GET_ALL_DESIGNATIONS_FAIL",
    ADD_DESIGNATION_SUCCESS: "ADD_DESIGNATION_SUCCESS",
    ADD_DESIGNATION_FAIL: "ADD_DESIGNATION_FAIL",
    GET_SCHOOL_ROLES_SUCCESS: "GET_SCHOOL_ROLES_SUCCESS",
    GET_SCHOOL_ROLES_FAIL: "GET_SCHOOL_ROLES_FAIL",
};