import { Elements } from "@stripe/react-stripe-js";
import PaymentIndex from "./paymentIndex";
import { loadStripe } from "@stripe/stripe-js";


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHER_KEY_TEST);

function PaymentWrapper() {
    return(
        <div>
        {/* <Elements stripe={stripePromise}> */}
            <PaymentIndex />
        {/* </Elements> */}
        </div>
    )
};

export default PaymentWrapper;