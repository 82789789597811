import { ActiveSchoolsIcon, ActiveTeacherStatIcon, InactiveSchoolsIcon, InactiveTeacherStatIcon, SchoolTeacherIcon, TotalTeachersStatIcon } from "../../Assets/Icons/icons";
import { ADD_DESIGNATION_FAIL, ADD_DESIGNATION_SUCCESS, ADD_TEACHER_FAIL, ADD_TEACHER_SUCCESS, GET_ALL_DESIGNATIONS_FAIL, GET_ALL_DESIGNATIONS_SUCCESS, GET_ONE_TEACHER_FAIL, GET_ONE_TEACHER_SUCCESS, GET_SCHOOL_ROLES_FAIL, GET_SCHOOL_ROLES_SUCCESS, GET_TEACHERS_FAIL, GET_TEACHERS_SUCCESS, TEACHER_PIECHART_FAIL, TEACHER_PIECHART_SUCCESS } from "../contants/teacherConstants";

const initialState = {
    error: null,
    success: null,
    teachersList: [],
    oneTeacher: null,
    totalCount: 0,
    designations: [],
    schoolRoles: [],
    piechartData: [
        {
            icon: <SchoolTeacherIcon />,
            title: "Total Teachers",
            number: "0",
            key: "totalTeachers",
        },
        {
            icon: <ActiveSchoolsIcon />,
            title: "Active Teachers",
            number: "0",
            key: "activeTeachers",
        },
        {
            icon: <InactiveSchoolsIcon />,
            title: "Inactive Teachers",
            number: "0",
            key: "inactiveTeachers",
        },
    ]
};

export const teacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TEACHER_FAIL:
            return { ...state, error: action.payload };
        case ADD_TEACHER_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_TEACHERS_SUCCESS:
            return { ...state, error: null, teachersList: action.payload.teachers, totalCount: action.payload.totalCount };
        case GET_TEACHERS_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_TEACHER_SUCCESS:
            return { ...state, error: null, oneTeacher: action.payload };
        case GET_ONE_TEACHER_FAIL:
            return { ...state, error: action.payload };
        case TEACHER_PIECHART_FAIL:
            return { ...state, error: action.payload };
        case TEACHER_PIECHART_SUCCESS:
            return { ...state, error: null, piechartData: action.payload };
        case GET_ALL_DESIGNATIONS_SUCCESS:
            return { ...state, error: null, designations: action.payload };
        case GET_ALL_DESIGNATIONS_FAIL:
            return { ...state, error: action.payload };
        case ADD_DESIGNATION_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case ADD_DESIGNATION_FAIL:
            return { ...state, error: action.payload };
        case GET_SCHOOL_ROLES_SUCCESS:
            return { ...state, error: null, schoolRoles: action.payload };
        case GET_SCHOOL_ROLES_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};