import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Popper,
  Fade,
  Paper,
  TextField,
  Autocomplete,
  List,
  ListSubheader,
  Divider,
} from "@mui/material";
import {
  CloseIcon,
  EventModalCalendarIcon,
  EventModalTimePickerIcon,
} from "../../../Assets/Icons/icons";
import classes from "./calendar.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  AlphaNumeric,
  generateRandomLightColor,
} from "../../../Utilities/commonFunctions";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewEvent,
  updateExistEvent,
} from "../../../Redux/actions/eventsActions";

const EventModal = ({
  modalClose,
  position,
  events,
  width,
  height,
  isEdit,
  oneEvent,
  viewType,
  selectedDate,
  setSuccessAlert,
  setErrorAlert,
}) => {
  const dispatch = useDispatch();
  const open = Boolean(position);
  const arrowRef = useRef(null);
  const arrow1Ref = useRef(null);
  const [title, setTitle] = useState();
  const [date, setDate] = useState();
  const [dateError, setDateError] = useState(false);
  const [time, setTime] = useState();
  const [timeError, setTimeError] = useState(false);
  const [description, setDescription] = useState();
  const [arrowElement, setArrowElement] = useState(null);
  // const [successAlert, setSuccessAlert] = useState(false);
  // const [errorAlert, setErrorAlert] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    // Update arrow position based on the selected date
    // if (arrowRef.current && selectedDate) {
    //   const dayOfWeek = moment(selectedDate).day(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    //   const arrowPosition = 100 - (dayOfWeek + 1) * 20; // Adjust this value based on your styling
    //   arrowRef.current.style.right = `${arrowPosition}px`;
    //   arrow1Ref.current.style.right = `${arrowPosition}px`;
    //   arrowRef.current.style.top = `unset !important`;
    //   arrow1Ref.current.style.top = `unset !important`;
    //   arrowRef.current.style.borderColor =
    //     "transparent transparent transparent #fff";
    //   arrow1Ref.current.style.borderColor =
    //     "transparent transparent transparent #c2c2c29d";
    // }
  }, [selectedDate]);

  useEffect(() => {
    // Update the arrowRef when the component mounts
    setArrowElement(arrowRef.current);
  }, []);

  useEffect(() => {
    if (isEdit && oneEvent) {
      setTitle(oneEvent?.title);
      const getDate = dayjs(oneEvent?.date_time);
      console.log("Get Da", getDate);
      setDate(getDate);
      setTime(getDate);
      setDescription(oneEvent?.description);
    }
  }, [isEdit]);

  function closeModal(data) {
    modalClose(false);
  }

  const handleChangeDate = (newValue) => {
    // console.log(newValue);
    setDateError(false);
    setDate(newValue);
  };

  const handleChangeTime = (newValue) => {
    // console.log(newValue);
    setTimeError(false);
    setTime(newValue);
  };

  const handleSubmit = () => {
    console.log(time, date);
    if (!time || time === undefined) {
      console.log("time error");
      setTimeError(true);
    }
    if (!date || date === undefined) {
      console.log("date error");
      setDateError(true);
    }
    if (title && date && time && description) {
      const isoDate = moment(date.$d).format("YYYY-MM-DD");
      const isoTime = moment(time.$d).format("HH:mm:ss");
      const assigntime = new Date(isoDate + " " + isoTime);
      const event = {
        title: title,
        description: description,
      };
      event.date_time = new Date(assigntime);
      console.log(event);
      if (isEdit) {
        dispatch(
          updateExistEvent(oneEvent?._id, event, setSuccessAlert, setErrorAlert)
        );
      } else {
        event.bg_color = generateRandomLightColor();
        event.user = userInfo?._id;
        dispatch(addNewEvent(event, setSuccessAlert, setErrorAlert));
      }
      closeModal(true);
    }
  };

  return (
    <div>
      {
        <Popper
          // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
          open={open}
          placement={viewType === "dayGridMonth" ? "right" : "top"}
          anchorEl={position}
          disablePortal={false}
          transition
          // modifiers={[
          //   {
          //     name: "flip",
          //     enabled: true,
          //     options: {
          //       altBoundary: true,
          //       rootBoundary: "document",
          //       padding: 8,
          //     },
          //   },
          //   {
          //     name: "arrow",
          //     enabled: true,
          //     options: {
          //       element: arrowRef.current, // Use the arrowRef here
          //     },
          //   },
          //   {
          //     name: "arrow1",
          //     enabled: true,
          //     options: {
          //       element: arrow1Ref.current, // Use the arrowRef here
          //     },
          //   },
          // ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "relative",
                    bgcolor: "transparent", // Make the background transparent
                    borderRadius: 1,
                  }}
                  style={{
                    minWidth: width,
                    height: "max-content",
                    padding: "20px",
                    position: "relative",
                  }}
                >
                  {/* <div className={`${classes.arrow}`} ref={arrowRef}></div>
                  <div className={`${classes.arrow1}`} ref={arrow1Ref}></div> */}
                  <div className={`${classes.forgetPasswordmodalclose}`}>
                    <Button
                      variant="text"
                      sx={{ minWidth: "unset" }}
                      className={"closebtn"}
                      onClick={() => closeModal()}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleSubmit}
                    className={classes.formInputContainer}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Typography className={classes.modalHeading}>
                        New Event
                      </Typography>
                      {/* <Autocomplete
                        sx={{ height: "32px" }}
                        disableClearable
                        options={events?.map((option) => option.title)}
                        fullWidth
                        size="small"
                        value={title}
                        id="free-solo-demo"
                        freeSolo
                        className={`mt-3 ${classes.eventInput}`}
                        renderInput={(params) => ( */}
                      <TextValidator
                        placeholder="Add event"
                        value={title}
                        className={`mt-3 ${classes.eventInput1}`}
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={(e) => {
                          AlphaNumeric(e);
                        }}
                        validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                        errorMessages={[
                          "Designation is required",
                          "Not a valid Designation Name",
                        ]}
                      />
                      {/* )}
                      ListboxComponent=
                      {(props) => (
                        <List {...props}>
                          <ListSubheader className={classes.OptionsHEading}>
                            Available Options
                          </ListSubheader>
                          <Divider
                            style={{
                              margin: "5px 0",
                              borderColor: "var(--options-divider-color)",
                              borderWidth: "1px",
                            }}
                          />
                          {props.children}
                        </List>
                      )}
                      classes=
                      {{
                        option: `${classes.successModalSubHeading} ${classes.textRansform}`,
                      }}
                      getOptionLabel={(option) => option}
                      /> */}
                      <DatePicker
                        className={`mt-2 ${classes.datePickerInput} ${
                          dateError ? classes.errorBorder : ""
                        }`}
                        slotProps={{
                          textField: {
                            size: "small",
                            helperText: dateError ? "Date is Required" : "",
                            style: { color: dateError ? "red" : "inherit" },
                          },
                        }}
                        slots={{
                          openPickerIcon: EventModalCalendarIcon,
                        }}
                        value={date}
                        onChange={handleChangeDate}
                        required
                        error={dateError}
                      />
                      <TimePicker
                        className={`mt-2 ${classes.datePickerInput} ${
                          dateError ? classes.errorBorder : ""
                        }`}
                        slotProps={{
                          textField: {
                            size: "small",
                            helperText: timeError ? "Time is Required" : "",
                          },
                        }}
                        slots={{
                          openPickerIcon: EventModalTimePickerIcon,
                        }}
                        value={time}
                        onChange={handleChangeTime}
                        required
                        error={timeError}
                        // helperText={timeError ? "Time is Required" : ""}
                      />
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "15px",
                        }}
                        id="gameNameInput"
                        fullWidth
                        multiline
                        rows={2}
                        placeholder="Description"
                        className={`mt-2 ${classes.multiInputFeild}`}
                        value={description}
                        size="small"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        validators={["required"]} // Add this line
                        errorMessages={["Description is required"]}
                      />
                      <div className={`mt-3 ${classes.buttonContainer}`}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            backgroundColor:
                              "var(--forgot-password-text-color)",
                          }}
                          className={classes.saveBtn}
                        >
                          Save
                        </Button>
                      </div>
                    </LocalizationProvider>
                  </ValidatorForm>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      }
    </div>
  );
};

export default EventModal;
