import React, { useEffect } from "react";
import classes from "./teacher.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName } from "../../../Redux/actions/userActions";
import { BrudCrumsNextIcon, EditPenIcon } from "../../../Assets/Icons/icons";
import { Card, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { getOneTeacher } from "../../../Redux/actions/teacherActions";
import ResetPasswordModal from "../Student/resetPasswordModal";
import AlertBox from "../../../Components/AlertBox";

const TeacherViewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetModal, setResetModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { error, oneTeacher } = useSelector((state) => state.teacherDatas);
  const { success } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    dispatch(getOneTeacher(arr[arr.length - 1], setErrorAlert));
  }, [dispatch]);

  const handleNavigateTeacher = () => {
    dispatch(setHeaderName("teachers"));
    navigate("/teachers");
  };

  const handleEditTeacherDetails = () => {
    dispatch(setHeaderName("edit-teacher-details"));
    navigate(`/edit-teacher-details/${oneTeacher._id}`);
  };

  const handleResetPassword = () => {
    setResetModal(true);
  };

  const ModalClose = () => {
    setResetModal(false);
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  return (
    <div>
      <div className={`${classes.BrudcrumbContainer}`}>
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateTeacher()}
          >
            Teacher
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
            {oneTeacher?.name}
          </span>
        </div>
        <div
          className={classes.brudcrumbs}
          onClick={() => {
            handleEditTeacherDetails();
          }}
        >
          <span>
            <EditPenIcon />
          </span>
          <span className={`${classes.TableBodyText} ${classes.editText}`}>
            Edit Details
          </span>
        </div>
      </div>
      {oneTeacher && (
        <div>
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.MainHeading1}>
              Student Details
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Teacher name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneTeacher?.name}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Designation
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneTeacher?.designationInfo?.name
                      ?.charAt(0)
                      .toUpperCase() +
                      oneTeacher?.designationInfo?.name
                        ?.slice(1)
                        .toLowerCase()
                        .replaceAll("_", ".")}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Years of Experience
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneTeacher?.experience} Years
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Email ID
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneTeacher?.userInfo?.email}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Contact Number
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneTeacher?.userInfo?.phone}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Assign Grade
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneTeacher?.classInfo?.grade?.charAt(0).toUpperCase() +
                      oneTeacher?.classInfo?.grade
                        ?.slice(1)
                        .toLowerCase()
                        .replaceAll("_", " ")}
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.MainHeading1}>
              Account Details
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Account Email ID
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneTeacher?.userInfo?.email}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Password
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    XXXXXXXX{" "}
                    <span
                      className={classes.resetPasswordText}
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </span>
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      )}
      {resetModal && (
        <ResetPasswordModal
          modalClose={ModalClose}
          height={"434px"}
          width={"368px"}
          email={oneTeacher?.userInfo?.email}
          setSuccessAlert={setSuccessAlert}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default TeacherViewPage;
