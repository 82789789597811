module.exports = {
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
  HEADER_NAME: "HEADER_NAME",
  PAGE_LOADER: "PAGE_LOADER",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_FAIL: "USER_FORGOT_PASSWORD_FAIL",
  SHOW_ERROR: "SHOW_ERROR",
  USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  USER_RESET_PASSWORD_FAIL: "USER_RESET_PASSWORD_FAIL",
  SORT_BY_VALUE: "SORT_BY_VALUE",
  PAGINATION_VALUE: "PAGINATION_VALUE",
  FORGOT_PASSWORD_OTP: "FORGOT_PASSWORD_OTP",
  ADD_ONE_SCHOOL_PIECHART_SUCCESS: "ADD_ONE_SCHOOL_PIECHART_SUCCESS",
  ADD_ONE_SCHOOL_PIECHART_FAIL: "ADD_ONE_SCHOOL_PIECHART_FAIL",
  LOGGEDIN_USER: "LOGGEDIN_USER",
  USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
  USER_CHANGE_PASSWORD_FAIL: "USER_CHANGE_PASSWORD_FAIL",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAIL: "PAYMENT_FAIL"
};
