import React, { useRef, useState } from "react";
import classes from "./payment.module.css";
import { Modal, Button, Box, Typography } from "@mui/material";
import { CloseIcon, ModalVectorDesign } from "../../../Assets/Icons/icons";
import successImage from "../../../Assets/Images/successIconImage.png";

const PaymentSuccessModel = ({ modalClose, name }) => {
  const [modalToggle, setModalToggle] = useState(true);
  const rootRef = useRef(null);
  const closeModal = () => {
    setModalToggle(false);
    modalClose();
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ height: 410, width: 685, padding: "30px" }}
          className={classes.SchoolActionModalsWidth}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={"closebtn"}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </Button>
          </div>
          <span
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <ModalVectorDesign />
          </span>
          <div className={classes.MainContainer}>
            <img
              src={successImage}
              alt="SuccessImage"
              style={{ width: 168, height: 168 }}
            />
            <Typography className={classes.cardHeader2}>
              Your Payment is Successfully Done
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentSuccessModel;
