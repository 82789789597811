import { ActiveSchoolsIcon, BigActiveIcon, BigInactiveIcon, DashboardStudentIcon, InactiveSchoolsIcon, TeacherStudentIcon } from "../../Assets/Icons/icons";
import {
    ADD_BULK_STUDENT_SUCCESS,
    ADD_STUDENT_FAIL,
    ADD_STUDENT_PIECHART_FAIL,
    ADD_STUDENT_PIECHART_SUCCESS,
    ADD_STUDENT_SUCCESS,
    GET_ONE_STUDENT_FAIL,
    GET_ONE_STUDENT_SUCCESS,
    GET_STUDENTS_FAIL,
    GET_STUDENTS_SUCCESS,
    SAVE_SELECTED_FILE,
    SUBSCRIPTION_LIST_FAIL,
    SUBSCRIPTION_LIST_SUCCESS,
    TEACHER_STUDENT_PIECHART_FAIL,
    TEACHER_STUDENT_PIECHART_SUCCESS
} from "../contants/studentConstants";

const initialState = {
    error: null,
    success: null,
    studentsList: [],
    oneStudent: null,
    subscriptionList: [],
    studentsArray: [],
    studentFile: null,
    totalCount: 0,
    bulkUpdateList: [],
    piechartData: [
        {
            icon: <DashboardStudentIcon />,
            title: "Total Students",
            number: "0",
            key: "totalStudents",
        },
        {
            icon: <ActiveSchoolsIcon />,
            title: "Active Students",
            number: "0",
            key: "activeStudents",
        },
        {
            icon: <InactiveSchoolsIcon />,
            title: "Inactive Students",
            number: "0",
            key: "inactiveStudents",
        },
    ],
    teacherStudentPieChart: [
        {
            icon: <TeacherStudentIcon />,
            title: "Total Students",
            number: "0",
            key: "totalStudents",
            color: "#E3E6FF"
        },
        {
            icon: <BigActiveIcon />,
            title: "Active Students",
            number: "0",
            key: "activeStudents",
            color: "#E5F6F2"
        },
        {
            icon: <BigInactiveIcon />,
            title: "Inactive Students",
            number: "0",
            key: "inactiveStudents",
            color: "#FDEEEE"
        },
    ]
};

export const studentReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_STUDENT_FAIL:
            return { ...state, error: action.payload };
        case ADD_STUDENT_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_STUDENTS_SUCCESS:
            return { ...state, error: null, studentsList: action.payload.students, totalCount: action.payload.totalCount };
        case GET_STUDENTS_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_STUDENT_SUCCESS:
            return { ...state, error: null, oneStudent: action.payload };
        case GET_ONE_STUDENT_FAIL:
            return { ...state, error: action.payload };
        case ADD_STUDENT_PIECHART_FAIL:
            return { ...state, error: action.payload };
        case ADD_STUDENT_PIECHART_SUCCESS:
            return { ...state, error: null, piechartData: action.payload };
        case SUBSCRIPTION_LIST_FAIL:
            return { ...state, error: action.payload };
        case SUBSCRIPTION_LIST_SUCCESS:
            return { ...state, error: null, subscriptionList: action.payload.subscriptions };
        case SAVE_SELECTED_FILE:
            return { ...state, error: null, studentFile: action.payload.file, studentsArray: action.payload.array };
        case ADD_BULK_STUDENT_SUCCESS:
            return { ...state, error: null, bulkUpdateList: action.payload };
        case TEACHER_STUDENT_PIECHART_FAIL:
            return { ...state, error: action.payload };
        case TEACHER_STUDENT_PIECHART_SUCCESS:
            return { ...state, error: null, teacherStudentPieChart: action.payload };
        default:
            return state;
    }
};