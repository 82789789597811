import React, { useState } from "react";
import classes from "./dashboard.module.css";
import { Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Avatar,
  Card,
  Typography,
  Button,
  Chip,
  MenuItem,
  Divider,
} from "@mui/material";
import image1 from "../../../Assets/Images/teacherDashboardImage.png";
import {
  dashboardConstants,
  teacherDashboardGraph,
} from "../../../Utilities/CommonConstants";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  DiamondIcon,
  EditPenIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import elephant from "../../../Assets/Images/elephant.png";
import deerbackground from "../../../Assets/Images/deerbackground.png";
import deer from "../../../Assets/Images/deer.png";
import giraffebackground from "../../../Assets/Images/giraffeBackground.png";
import giraffe from "../../../Assets/Images/giraffe.png";
import { StyledMenu } from "../../../Components/CommonMenu";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { userInfo } from "os";
import { useSelector } from "react-redux";

const TeacherDashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(dayjs(new Date()));
  const { userInfo, userRole } = useSelector((state) => state.userLogin);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  console.log("userInfo =",userInfo)
  return (
    <div>
      <Row>
        <Col md={9}>
          <Card className={classes.teacherDashboardCard1}>
            <div>
              <Typography className={classes.teacherDashboardHeader}>
                Welcome Back, <b>{userInfo?.teacherInfo?.name}</b>
              </Typography>
              <Typography
                className={`mt-2 ${classes.teacherDashboardSubHeading}`}
              >
                Your students completed <b>80%</b> of goals. Progress is{" "}
                <b>very good</b>
              </Typography>
            </div>
            <img
              src={image1}
              alt="teacherDashboardImage"
              className={classes.teacherDashboardImage}
            />
          </Card>
          <Row className={`mt-3`}>
            <Col md={6}>
              <Card style={{ padding: "15px" }}>
                <Typography className={classes.cardMainHeading}>
                  Statistic for overall game time
                </Typography>
                <ReactApexChart
                  className={classes.secondGraphChart}
                  options={teacherDashboardGraph.options}
                  series={teacherDashboardGraph.series}
                  type="area"
                  height={260}
                />
              </Card>
            </Col>
            <Col md={6}>
              <Card style={{ padding: "15px" }}>
                <div className={`d-flex justify-content-between`}>
                  <Typography className={classes.cardMainHeading}>
                    Students Progress
                  </Typography>
                  <Typography className={classes.viewAllBtn}>
                    View All
                  </Typography>
                </div>
                <div className={`mt-3 ${classes.progressBarContainer}`}>
                  {dashboardConstants?.map((item, index) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex align-items-center"
                          style={{ columnGap: "10px" }}
                        >
                          <Avatar src={item?.profile} height={32} width={32} />
                          <Typography className={classes.memberNameText}>
                            {item.name}
                          </Typography>
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ columnGap: "10px" }}
                        >
                          <ProgressBar
                            now={item.progress}
                            className={classes.progressBarStyles}
                          />
                          <Typography className={classes.someOtherText}>
                            {item.progress}%
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={3} className={classes.dashboardCalendarContainer}>
          <Typography className={`mt-3 ${classes.cardMainHeading}`}>
            Calendar
          </Typography>
          <Card className={classes.calendarCard}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                views={["day"]}
                style={{ width: "100%", height: "290px" }}
                // value={value}
                // onChange={(newValue) => setValue(newValue)}
              />
            </LocalizationProvider>
          </Card>
          <Divider className={classes.calendarDivider} />
          <div style={{ position: "relative" }}>
            <Typography className={`mt-2   ${classes.cardMainHeading}`}>
              Schedule
            </Typography>
            <Typography className={classes.teacherDashboardviewAllBtn}>
              View All
            </Typography>
            <div className="mt-2">
              <Card className={classes.scheduleCard}>
                <div
                  className={classes.seduleSubcontainer1}
                  style={{ width: "25%" }}
                >
                  <Typography className={classes.chartLabel}>
                    10.00 Am
                  </Typography>
                  <Typography className={classes.chartLabel}>to</Typography>
                  <Typography className={classes.chartLabel}>
                    11.00 Am
                  </Typography>
                </div>
                <div className={`${classes.scheduleDivider}`}></div>
                <div className={classes.seduleSubcontainer1}>
                  <Typography className={classes.sheduleBigtext}>
                    Upload New Game
                  </Typography>
                  <Typography className={classes.sheduleSmalltext}>
                    <span>Grade 2</span>
                    <span>Class B</span>
                  </Typography>
                </div>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <Typography className={`mt-4 ${classes.cardMainHeading}`}>
          Games Uploaded
        </Typography>
        <Row className="mt-3" style={{ rowGap: "15px" }}>
          <Col md={4}>
            <Card className={classes.gameCard}>
              <Typography className={classes.GameHeading}>
                Picture puzzles
              </Typography>
              <Typography className={`mt-3 ${classes.GameDiamond}`}>
                Total Diamond: <span style={{ fontWeight: 700 }}>150</span>{" "}
                <DiamondIcon />{" "}
              </Typography>
              <Typography className={`mt-3 ${classes.GameDiamond}`}>
                No. of Level:: <span style={{ fontWeight: 700 }}>4</span>
              </Typography>
              <Chip
                icon={<ActiveItemIcon />}
                style={{
                  background: "var(--active-chip-background)",
                  width: "75px",
                  height: "24px",
                }}
                label={"Active"}
                className={`mt-3 ${classes.StatusChip}`}
              />
              <img
                src={elephant}
                alt={"Elphant"}
                style={{ width: "175px", height: "166px" }}
                className={classes.GameMainImage}
              />
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                className={classes.MenuButton}
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                variant="text"
                style={{
                  minWidth: 0,
                }}
              >
                <ThreeDotMenuIcon />
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => console.log("Edit Details")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <EditPenIcon />
                  <span className={classes.MenuItemText}>Edit Details</span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log("Delete Details")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <DeleteBinIcon />
                  <span className={classes.MenuItemText}>Delete</span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log("Active/Inactive")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <SwitchIcon />
                  <span className={classes.MenuItemText}>Active/Inactive</span>
                </MenuItem>
              </StyledMenu>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={classes.gameCard}>
              <Typography className={classes.GameHeading}>
                Join the dots
              </Typography>
              <Typography className={`mt-3 ${classes.GameDiamond}`}>
                Total Diamond: <span style={{ fontWeight: 700 }}>150</span>{" "}
                <DiamondIcon />{" "}
              </Typography>
              <Typography className={`mt-3 ${classes.GameDiamond}`}>
                No. of Level:: <span style={{ fontWeight: 700 }}>5</span>
              </Typography>
              <Chip
                icon={<ActiveItemIcon />}
                style={{
                  background: "var(--active-chip-background)",
                  width: "75px",
                  height: "24px",
                }}
                label={"Active"}
                className={`mt-3 ${classes.StatusChip}`}
              />
              <img
                src={deerbackground}
                alt={"Deer"}
                style={{ width: "175px", height: "142px" }}
                className={classes.GameMainImage}
              />
              <img
                src={deer}
                alt={"Deer"}
                style={{ width: "121px", height: "170px" }}
                className={classes.GameMainImage}
              />
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                className={classes.MenuButton}
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                variant="text"
                style={{
                  minWidth: 0,
                }}
              >
                <ThreeDotMenuIcon />
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => console.log("Edit Details")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <EditPenIcon />
                  <span className={classes.MenuItemText}>Edit Details</span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log("Delete Details")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <DeleteBinIcon />
                  <span className={classes.MenuItemText}>Delete</span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log("Active/Inactive")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <SwitchIcon />
                  <span className={classes.MenuItemText}>Active/Inactive</span>
                </MenuItem>
              </StyledMenu>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={classes.gameCard}>
              <Typography className={classes.GameHeading}>
                Picture puzzles
              </Typography>
              <Typography className={`mt-3 ${classes.GameDiamond}`}>
                Total Diamond: <span style={{ fontWeight: 700 }}>150</span>{" "}
                <DiamondIcon />{" "}
              </Typography>
              <Typography className={`mt-3 ${classes.GameDiamond}`}>
                No. of Level:: <span style={{ fontWeight: 700 }}>4</span>
              </Typography>
              <Chip
                icon={<ActiveItemIcon />}
                style={{
                  background: "var(--active-chip-background)",
                  width: "75px",
                  height: "24px",
                }}
                label={"Active"}
                className={`mt-3 ${classes.StatusChip}`}
              />
              <img
                src={giraffebackground}
                alt={"Giraffe"}
                style={{ width: "175px", height: "142px" }}
                className={classes.GameMainImage}
              />
              <img
                src={giraffe}
                alt={"Giraffe"}
                style={{ width: "134px", height: "164px" }}
                className={classes.GameMainImage}
              />
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                className={classes.MenuButton}
                onClick={handleClick}
                variant="text"
                style={{
                  minWidth: 0,
                }}
              >
                <ThreeDotMenuIcon />
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => console.log("Edit Details")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <EditPenIcon />
                  <span className={classes.MenuItemText}>Edit Details</span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log("Delete Details")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <DeleteBinIcon />
                  <span className={classes.MenuItemText}>Delete</span>
                </MenuItem>
                <MenuItem
                  onClick={() => console.log("Active/Inactive")}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <SwitchIcon />
                  <span className={classes.MenuItemText}>Active/Inactive</span>
                </MenuItem>
              </StyledMenu>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TeacherDashboard;
