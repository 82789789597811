import React, { useEffect, useRef, useState } from "react";
import classes from "./notification.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Typography, Card, Button } from "@mui/material";
import {
  deleteAllNotification,
  getAllNotification,
} from "../../../Redux/actions/notificationActions";
import { DeleteBinIconSmall } from "../../../Assets/Icons/icons";
import NotificationCard from "./notificationCard";

const NotificationList = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [newList, setNewList] = useState([]);
  const [lastWeekList, setLastWeekList] = useState([]);
  const [lastMonthList, setLastMonthList] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const { success, error, notificationsList } = useSelector(
    (state) => state.notificationsData
  );

  // useEffect(() => {
  //   if (userInfo && userInfo.teacherInfo && userInfo.teacherInfo._id) {
  // Emit an event to the server with the user ID
  //     socket.emit("userConnected", userInfo.teacherInfo._id);
  //   }
  // }, [userInfo]);

  // Listen for notifications from the server
  // useEffect(() => {
  //   socket.on("notification", (data) => {
  // Handle received notifications from the server
  // Update the component state accordingly
  //     console.log("Received notification:", data);
  // You can update notifications and unseenCount here
  //   });

  // Clean up the event listener when the component unmounts
  //   return () => {
  //     socket.off("notification");
  //   };
  // }, []);

  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getAllNotification(userInfo._id, setErrorAlert));
    }
  }, [dispatch, userInfo]);

  // const handleUpdateAllNotificationStatus = () => {
  //   const data = {
  //     seen: true,
  //   };
  //   dispatch(
  //     updateAllNotificationStatus(
  //       userInfo.teacherInfo._id,
  //       data,
  //       () => {
  //         // Update local state after successful dispatch
  //         setNotifications((prevNotifications) =>
  //           prevNotifications.map((notification) => ({
  //             ...notification,
  //             seen: true,
  //           }))
  //         );
  //         setUnseenCount(0);
  //         setSuccessAlert(true);
  //       },
  //       setErrorAlert
  //     )
  //   );
  // };
  // const handleUpdateNotificationStatus = (id) => {
  //   const data = {
  //     seen: true,
  //   };
  //   dispatch(
  //     updateNotificationStatus(
  //       id,
  //       data,
  //       () => {
  //         // Update local state after successful dispatch
  //         setNotifications((prevNotifications) =>
  //           prevNotifications.map((notification) =>
  //             notification._id === id
  //               ? { ...notification, seen: true }
  //               : notification
  //           )
  //         );
  //         // Update unseenCount directly
  //         setUnseenCount((prevUnseenCount) => Math.max(0, prevUnseenCount - 1));
  //         setSuccessAlert(true);
  //       },
  //       setErrorAlert
  //     )
  //   );
  // };

  const handleClearAllNotification = () => {
    dispatch(
      deleteAllNotification(userInfo._id, setSuccessAlert, setErrorAlert)
    );
  };

  return (
    <div>
      <Card>
        <div className={classes.headerMainContainer}>
          <div className={classes.headerSubContainer}>
            <Typography className={classes.headerTitle}>
              Notifications
            </Typography>
            <Typography>{unseenCount}</Typography>
          </div>
          <div className={classes.headerSubContainer}>
            <Button
              // onClick={handleClearAllNotification}
              variant="text"
              className={classes.headerClearAllbtn}
            >
              Mark as Read
            </Button>
            <Button
              onClick={handleClearAllNotification}
              variant="text"
              className={classes.headerClearAllbtn}
            >
              <DeleteBinIconSmall />
              <span>Clear All</span>
            </Button>
          </div>
        </div>
        <Divider />
        <div className={classes.notificationBodyContainer}>
          {notificationsList && notificationsList?.new?.length > 0 && (
            <div>
              <Typography className={classes.headerTitle}>New</Typography>
              {notificationsList?.new?.map((notification, index) => {
                return (
                  <NotificationCard notification={notification} key={index} />
                );
              })}
            </div>
          )}
          {notificationsList && notificationsList?.lastweek?.length > 0 && (
            <div>
              <Typography className={classes.headerTitle}>Last Week</Typography>
              {notificationsList?.lastweek?.map((notification, index) => (
                <NotificationCard notification={notification} key={index} />
              ))}
            </div>
          )}
          {notificationsList && notificationsList?.lastmonth?.length > 0 && (
            <div>
              <Typography className={classes.headerTitle}>
                Last Month
              </Typography>
              {notificationsList?.lastmonth?.map((notification, index) => (
                <NotificationCard notification={notification} key={index} />
              ))}
            </div>
          )}
          {notificationsList && notificationsList?.older?.length > 0 && (
            <div>
              <Typography className={classes.headerTitle}>Older</Typography>
              {notificationsList?.older?.map((notification, index) => (
                <NotificationCard notification={notification} key={index} />
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default NotificationList;
