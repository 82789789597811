import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SchoolAdminDashboard from "./schoolAdminDashboard";
import TeacherDashboard from "./teacherDashboard";

const Dashboard = () => {
  // const navigate = useNavigate();
  const { userRole, userInfo } = useSelector((state) => state.userLogin);
  return (
    <div>
      {userRole === "TEACHER" ? <TeacherDashboard /> : <SchoolAdminDashboard />}
    </div>
  );
};

export default Dashboard;
