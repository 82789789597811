import { authApi, authImageApi, getAuthorizationHeader } from "../../Api/api";
import { GET_ONE_CLASS_FAIL } from "../contants/classConstants";
import {
  ADD_COURSE_FAIL,
  ADD_COURSE_SUCCESS,
  ADD_SCHOOL_COURSE_FAIL,
  ADD_SCHOOL_COURSE_SUCCESS,
  GET_COURSES_FAIL,
  GET_COURSES_SUCCESS,
  GET_COURSE_PIECHART_FAIL,
  GET_COURSE_PIECHART_SUCCESS,
  GET_GAMES_LIST_FAIL,
  GET_GAMES_LIST_SUCCESS,
  GET_ONE_COURSE_SUCCESS,
  GET_SCHOOL_COURSES_FAIL,
  GET_SCHOOL_COURSES_SUCCESS,
} from "../contants/courseConstants";
import { pageLoader } from "./userActions";

export const addNewCourse =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/courses/create-course", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateCourse =
  (id, userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/courses/update-course/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const deleteCourse =
  (id, topic, setErrorAlert, setSuccessAlert, userId, array, array1) =>
    async (dispatch) => {
      try {
        const { data } = await authApi.delete(
          `/games/delete-schoolgames/${id}`,
          {},
          {
            headers: {
              Authorization: getAuthorizationHeader(),
            },
          }
        );
        dispatch(pageLoader(false));
        if (data.success) {
          dispatch({
            type: ADD_COURSE_SUCCESS,
            payload: data.message,
          });
          setSuccessAlert(true);
          dispatch(getAllCourses(setErrorAlert, topic, userId));
          dispatch(getCoursePiechart(setErrorAlert, userId, array, array1));
        }
      } catch (error) {
        dispatch({
          type: ADD_COURSE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
      }
    };

export const changeCourseStatus =
  (id, topic, setSuccessAlert, setErrorAlert, userId, array, array1) =>
    async (dispatch) => {
      try {
        const { data } = await authApi.patch(
          `/games/update-active-status/${id}`,
          {
            headers: {
              Authorization: getAuthorizationHeader(),
            },
          }
        );
        dispatch(pageLoader(false));
        if (data.success) {
          dispatch({
            type: ADD_COURSE_SUCCESS,
            payload: data.message,
          });
          setSuccessAlert(true);
          dispatch(getAllCourses(setErrorAlert, topic, userId));
          dispatch(getCoursePiechart(setErrorAlert, userId, array, array1));
        }
      } catch (error) {
        dispatch({
          type: ADD_COURSE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
      }
    };

export const getAllCourses =
  (setErrorAlert, topic, id, page, sort, search, isActive, gradeLevel) =>
    async (dispatch) => {
      try {
        const { data } = await authApi.get(
          `/games/get-schoolgames/${id}/${topic}?page=${page || 1
          }&limit=${10}&sort=${sort || ""}&search=${search || ""}&status=${isActive !== "all" ? isActive : ""
          }&grade=${gradeLevel !== "select" ? gradeLevel : ""}`,
          {
            headers: {
              Authorization: getAuthorizationHeader(),
            },
          }
        );
        dispatch(pageLoader(false));
        if (data.success) {
          dispatch({
            type: GET_COURSES_SUCCESS,
            payload: data.data,
          });
        }
      } catch (error) {
        dispatch({
          type: GET_COURSES_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
        setErrorAlert(true);
      }
    };

export const getOneCourse = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/courses/courses-me/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_ONE_COURSE_SUCCESS,
        payload: data.data[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_CLASS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const FileUpload = (file) => async (dispatch) => {
  const formData = new FormData();

  formData.append("file", file);
  const { data } = await authImageApi.post("/courses/upload-file", formData);
  return data.data;
};

export const addNewGame =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/games/create-game", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllGamesList = () => async (dispatch) => {
  try {
    const { data } = await authApi.get("/games/games-all");
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_GAMES_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_GAMES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const getCoursePiechart =
  (setErrorAlert, id, array, array2) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/games/course-piechart/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      let newData = [];
      let newData2 = [];
      Object.keys(data.data)?.map((item) => {
        const obj = array.find((element) => {
          if (element.key === item) {
            return element;
          } else {
            return null;
          }
        });
        newData.push({
          ...obj,
          number: data.data[item],
        });
        return null;
      });

      Object.keys(data.data)?.map((item) => {
        const obj = array2?.find((element) => {
          if (element.key === item) {
            return element;
          } else {
            return null;
          }
        });
        newData2.push({
          ...obj,
          number: data.data[item],
        });
        return null;
      });

      const response = {
        newData,
        newData2,
      }

      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GET_COURSE_PIECHART_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COURSE_PIECHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getSchoolCoureses =
  (id, topic, setErrorAlert, search, page, sortBy, grade, subscriptionLevel) =>
    async (dispatch) => {
      try {
        const { data } = await authApi.get(
          `/courses/school-courses/${topic}/${id}?page=${page ? page : 1
          }&limit=10&search=${search || ""}&grade=${grade !== "select" ? grade : ""
          }&subscription_id=${subscriptionLevel !== "select" ? subscriptionLevel : ""
          }&sort=${sortBy || ""}`,
          {
            headers: {
              Authorization: getAuthorizationHeader(),
            },
          }
        );
        console.log(data);
        dispatch(pageLoader(false));
        if (data.success) {
          dispatch({
            type: GET_SCHOOL_COURSES_SUCCESS,
            payload: data.data,
          });
        }
      } catch (error) {
        dispatch({
          type: GET_SCHOOL_COURSES_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
      }
    };

export const AddCourseToSchool =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post(
        "/games/create-schoolgames",
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SCHOOL_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_SCHOOL_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };
