import React, { useEffect, useState } from "react";
import classes from "./payment.module.css";
import { Card, Typography, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { CheckCircleIcon } from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateOrder,
  getAllSubscriptionList,
} from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";

const SubscriptionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const { subscriptionList, error } = useSelector(
    (state) => state.subscriptionData
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  const colours = ["#FFADDB", "#FFC700", "#BA9BFF", "#6AD7E0"];

  useEffect(() => {
    dispatch(getAllSubscriptionList(setErrorAlert));
  }, []);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleChoosePack = (item) => {
    const userEntry = {
      user_id: userInfo?._id,
      subscription_id: item?._id,
      subscribed_by: "SCHOOL",
      type: "new",
    };
    dispatch(GenerateOrder(userEntry, setErrorAlert, navigate));
  };

  return (
    <div className={classes.subscriptionListMainContainer}>
      <Typography className={classes.subscMainHeading}>
        Subscription & Payment
      </Typography>
      <Row className={`mt-4 ${classes.subscriptionListContainer}`}>
        {subscriptionList.map((item, index) => {
          return (
            <Col md={3} style={{ position: "relative" }}>
              <Card className={classes.subscrtionsListCardContainer}>
                <div
                  className={classes.subscrtionsListCardHeader}
                  style={{ backgroundColor: colours[index % 4] }}
                >
                  <Typography className={classes.cardHeader2}>
                    {item?.subscription_name}
                  </Typography>
                </div>
                <div style={{ padding: "15px" }}>
                  <Typography className={classes.ReceiptMainHeading}>
                    £{item?.price}{" "}
                    <span className={classes.SomeImportantText}>
                      / {item?.duration}
                    </span>
                  </Typography>
                  <Typography className={`mt-3 ${classes.cardHeader1}`}>
                    What Inclued?
                  </Typography>
                  <div className={`mt-4 ${classes.descriptionList}`}>
                    {[1, 2, 3, 4, 5].map((item) => {
                      return (
                        <Typography className={classes.headerSubText}>
                          <span style={{ marginRight: "10px" }}>
                            <CheckCircleIcon />
                          </span>
                          3 Unlocked Topics
                        </Typography>
                      );
                    })}
                  </div>
                </div>
              </Card>
              <Button
                className={classes.subscriptionbtn}
                variant="contained"
                style={{
                  backgroundColor: "var(--forgot-password-text-color)",
                }}
                onClick={() => handleChoosePack(item)}
              >
                Choose Level
              </Button>
            </Col>
          );
        })}
      </Row>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default SubscriptionList;
