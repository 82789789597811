import { SchoolCourseIcon, SchoolStudentIcon, SchoolTeacherIcon } from "../../Assets/Icons/icons";
import { ADD_ONE_SCHOOL_PIECHART_FAIL, ADD_ONE_SCHOOL_PIECHART_SUCCESS } from "../contants/userConstants";

const initialState = {
    schoolPieChart: [
        {
            icon: <SchoolStudentIcon />,
            title: "Total Students",
            number: "0",
            key: "totalStudents",
            navigate: "/students",
        },
        {
            icon: <SchoolTeacherIcon />,
            title: "Teachers",
            number: "0",
            key: "totalTeachers",
            navigate: "/teachers",
        },
        {
            icon: <SchoolCourseIcon />,
            title: "Games/ Courses added",
            number: "0",
            key: "totalCourses",
            navigate: "/cources",
        },
    ],
};

export const schoolReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ONE_SCHOOL_PIECHART_SUCCESS:
            return { ...state, error: null, schoolPieChart: action.payload };
        case ADD_ONE_SCHOOL_PIECHART_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};