import {
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import classes from "./student.module.css";
import React, { useEffect } from "react";
import {
  ChipCancelIcon,
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { useState } from "react";
import StudentTableListItem from "./StudentTableListItem";
import DefaultPagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudents } from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import NodataFound from "../../../Components/nodataFound";
import {
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import MultiSelectSchoolDropdown from "../../../Components/MultiSelectSchoolDropdown";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";

const StudentsTable = ({ value }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [isActive, setIsActive] = useState("all");
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const { schoolsNameList } = useSelector((state) => state.schoolDatas);
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, studentsList, totalCount } = useSelector(
    (state) => state.studentDatas
  );
  const { classesList } = useSelector((state) => state.classDatas);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const { sortBy, page, userInfo, toggleSideBar } = useSelector(
    (state) => state.userLogin
  );

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleChange = (e) => {
    setIsActive(e.target.value);
  };

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo && userInfo?.schoolInfo?._id) {
        const userEntry = {
          schools: [userInfo?.schoolInfo?._id],
          classes: selectedClasses,
          age: selectedSection,
        };
        dispatch(
          getAllStudents(setErrorAlert, search, userEntry, sortBy, page)
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [
    dispatch,
    userInfo,
    selectedClasses,
    sortBy,
    page,
    search,
    selectedSection,
  ]);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, [search]);

  // const handleCheckboxChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   console.log(value);
  //   setSelectedSchools(value);
  //   const arr = value.map((item) => {
  //     return item._id;
  //   });
  //   setSelectedSchoolIds(arr);
  // };

  useEffect(() => {
    getClassesList();
  }, [classesList]);

  useEffect(() => {
    if (studentsList && sectionList.length === 0) {
      getAgeList();
    }
  }, [studentsList]);

  const handleClassSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setSelectedClasses(value);
  };

  const handleSectionSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setSelectedSection(value);
  };

  const getClassesList = () => {
    const array = classesList.map((item) => {
      return item.grade;
    });
    const resultArray = array.filter((value, index, self) => {
      return (
        value !== undefined && value !== "" && self.indexOf(value) === index
      );
    });
    setClassList(resultArray);
  };

  const getAgeList = () => {
    const array = studentsList.map((item, index) => {
      return item.age;
    });
    const resultArray = array.filter((value, index, self) => {
      return (
        value !== undefined && value !== "" && self.indexOf(value) === index
      );
    });
    setSectionList(resultArray);
  };

  const handleAddStudentClick = () => {
    dispatch(setHeaderName("add-student"));
    navigate("/add-student/school/1");
  };

  return (
    <div>
      <div className={`${classes.FilterMainContainer} mt-3`}>
        <div className={`${classes.FilterSubContainer}`}>
          <div>
            <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              fullWidth
              placeholder="Search by Student Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={
                toggleSideBar
                  ? `${classes.searchBar} ${classes.toggleSearchBar}`
                  : classes.searchBar
              }
              id="firstNameinput"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
            />
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.searchButton}
              style={{
                width: "110px",
                height: "40px",
              }}
            >
              <span className={classes.nrmlBtnText}>Search</span>
            </Button>
          </div>
          <div>
            <MultiSelectSchoolDropdown
              value={selectedClasses}
              handleChange={handleClassSelect}
              multiple
              width={"185px"}
              list={classList}
              type={"Class"}
            />
          </div>
          <div>
            <MultiSelectSchoolDropdown
              value={selectedSection}
              handleChange={handleSectionSelect}
              multiple
              width={"185px"}
              list={sectionList}
              type={"Age"}
            />
          </div>
        </div>
        <div className={`${classes.FilterSubContainer}`}>
          <div
            className={`${classes.DividerClasses} ${classes.vertical}`}
          ></div>
          <div className={classes.addBtnContainer}>
            <Button
              variant="contained"
              className={`d-flex align-items-center ${classes.searchButton}`}
              onClick={() => handleAddStudentClick()}
            >
              <span style={{ marginRight: "10px" }}>
                <RoundAddIcon />
              </span>
              <span className={classes.mainBtnText}>Add New Student</span>
            </Button>
          </div>
        </div>
      </div>
      {totalCount && totalCount > 0 ? (
        <div>
          <div className={`mt-3 mb-3 ${classes.TableContainer}`}>
            <Table className={classes.TableClass}>
              <TableHead>
                <TableRow className={classes.TableHeaderRow}>
                  <TableCell
                    flex={1}
                    className={classes.firstCell}
                    style={{ minWidth: "135px" }}
                  >
                    <span className={classes.TableHeaderText}>
                      Student Name
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "first_name")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("first_name") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "80px" }}>
                    <span className={classes.TableHeaderText}>Age</span>
                    <span
                      onClick={() => handleSortClick(dispatch, sortBy, "age")}
                    >
                      {sortBy?.includes("desc") && sortBy?.includes("age") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "90px" }}>
                    <span className={classes.TableHeaderText}>Grade</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "gradeInfo.grade")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("gradeInfo.grade") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "270px" }}>
                    <span className={classes.TableHeaderText}>
                      {value === 0 ? "School Name" : " Parent Name"}
                    </span>
                    {value === 0 ? (
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "schoolInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("schoolInfo.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "parent.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("parent.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "95px" }}>
                    <span className={classes.TableHeaderText}>Section</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "section")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("section") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "185px" }}>
                    <span className={classes.TableHeaderText}>
                      Parent Contact number
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "parent.phone")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("parent.phone") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>
                      Parent Email ID
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "parent.email")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("parent.email") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>Status</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "isActive")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("isActive") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsList?.map((item, index) => {
                  return (
                    <StudentTableListItem
                      item={item}
                      key={index}
                      value={value}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <DefaultPagination count={studentsList?.length} />
        </div>
      ) : (
        <div>
          <NodataFound text={"Please add students to view"} />
        </div>
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default StudentsTable;
