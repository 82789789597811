import { authApi, getAuthorizationHeader } from "../../Api/api";
import {
  ADD_BULK_STUDENT_SUCCESS,
  ADD_STUDENT_FAIL,
  ADD_STUDENT_PIECHART_FAIL,
  ADD_STUDENT_PIECHART_SUCCESS,
  ADD_STUDENT_SUCCESS,
  DOWNLOAD_EMAIL_TEMPLATE_FAIL,
  DOWNLOAD_EMAIL_TEMPLATE_SUCCESS,
  GET_ONE_STUDENT_FAIL,
  GET_ONE_STUDENT_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_SUCCESS,
  SAVE_SELECTED_FILE,
  SUBSCRIPTION_LIST_FAIL,
  SUBSCRIPTION_LIST_SUCCESS,
  TEACHER_STUDENT_PIECHART_SUCCESS,
  TEACHER_STUDENT_PIECHART_FAIL,
} from "../contants/studentConstants";
import { pageLoader } from "./userActions";

export const addStudent =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/student-admin/create", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateStudent =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch("/student-admin/update", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllStudents =
  (setErrorAlert, search, userEntry, sort, page) => async (dispatch) => {
    try {
      const { data } = await authApi.post(
        `/student-admin/get-students/?search=${search || ""}&page=${
          page || 1
        }&limit=10&isSchool=${true}&sort=${sort || ""}&active=`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GET_STUDENTS_SUCCESS,
          payload: data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STUDENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getOneStudent = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/student-admin/get-student/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    // console.log("student data", data);
    if (data.success) {
      dispatch({
        type: GET_ONE_STUDENT_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_STUDENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const deleteStudent =
  (id, setSuccessAlert, setErrorAlert, userEntry, array, array1) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/student-admin/delete-student/?Id=${id}`,
        {},
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllStudents(setErrorAlert, "", userEntry));
        dispatch(
          getStudentPiechart(setErrorAlert, userEntry?.schools[0], array)
        );
        dispatch(
          getTeacherStudentPiechart(
            setErrorAlert,
            userEntry?.schools[0],
            array1
          )
        );
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getStudentPiechart =
  (setErrorAlert, id, array) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/school/student-piechart/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      //   console.log(data);
      //   console.log(array);
      let newData = [];
      Object.keys(data.data)?.map((item) => {
        const obj = array.find((element) => {
          if (element.key === item) {
            return element;
          } else {
            return null;
          }
        });
        newData.push({
          ...obj,
          number: data.data[item],
        });
        return null;
      });

      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_PIECHART_SUCCESS,
          payload: newData,
        });
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_PIECHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getTeacherStudentPiechart =
  (setErrorAlert, id, array) => async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/teacher-admin/student-piechart/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log("Student Data Piechart", data);
      //   console.log(array);
      let newData = [];
      Object.keys(data.data)?.map((item) => {
        const obj = array.find((element) => {
          if (element.key === item) {
            return element;
          } else {
            return null;
          }
        });
        newData.push({
          ...obj,
          number: data.data[item],
        });
        return null;
      });

      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: TEACHER_STUDENT_PIECHART_SUCCESS,
          payload: newData,
        });
      }
    } catch (error) {
      dispatch({
        type: TEACHER_STUDENT_PIECHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const changeStudentStatus =
  (id, userEntry, setErrorAlert, setSuccessAlert, array, userEntry1, array1) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/student-admin/student-status`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllStudents(setErrorAlert, "", userEntry1));
        dispatch(
          getStudentPiechart(setErrorAlert, userEntry1?.schools[0], array)
        );
        dispatch(
          getTeacherStudentPiechart(
            setErrorAlert,
            userEntry1?.schools[0],
            array1
          )
        );
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const downloadEmailTemplate =
  (setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/student/download-template`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      dispatch({
        type: DOWNLOAD_EMAIL_TEMPLATE_SUCCESS,
        payload: "Email template downloaded successfully",
      });
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      console.log(blob);

      // Create a URL for the blob
      const backendUrl = `${process.env.REACT_APP_API_URL}/student/download-template`;

      // Create a link element
      const a = document.createElement("a");
      a.href = backendUrl;
      a.download = "studentsTemplate.xlsx"; // Optional: Set the desired file name
      document.body.appendChild(a);

      // Trigger a click event on the link to initiate the download
      a.click();
      setSuccessAlert(true);
    } catch (error) {
      dispatch({
        type: DOWNLOAD_EMAIL_TEMPLATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const AddStudentsBulk =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post(
        `/student-admin/bulk-add-students`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_BULK_STUDENT_SUCCESS,
          payload: data.data,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getSubscriptionList = (setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/subscription/subscription-all?search=`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: SUBSCRIPTION_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const SaveSelectedFile = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SELECTED_FILE,
    payload: data,
  });
};
