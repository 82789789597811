import React from "react";
import { Card, Divider, Typography } from "@mui/material";
import classes from "./index.module.css";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const StatisticsCard = ({ data }) => {
  const navigate = useNavigate();
  function formatAsIndianCurrency(number) {
    const formatter = new Intl.NumberFormat("en-IN", {
      currency: "INR",
    });
    return formatter.format(number);
  }
  return (
    <div>
      <Card
        style={{
          padding: "20px",
        }}
      >
        <Row className="justify-content-md-between">
          {data?.map((item, index) => (
            <Col
              sm={12}
              md={12 / data.length}
              className={classes.dashboardStatsBar}
              key={index}
              onClick={() => item.navigate && navigate(item.navigate)}
            >
              {index !== 0 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.DividerClasses}
                />
              )}
              {item.icon}
              <div>
                <Typography className={classes.statisticsNumberText}>
                  {formatAsIndianCurrency(item.number)}
                </Typography>
                <Typography className={classes.statisticsTitleText}>
                  {item.title}
                </Typography>
              </div>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default StatisticsCard;
