import { Navigate, createBrowserRouter } from "react-router-dom";
import GuestLayout from "./Layouts/guestLayout";
import Login from "./Pages/Auth/Login";
import DefaultLayout from "./Layouts/defaultLayout";
import Dashboard from "./Pages/Home/Dashboard";
import ForgotPasswordIndex from "./Pages/Auth/forgotPasswordIndex";
import ClassManagementIndex from "./Pages/Home/classManagement/classManagementIndex";
import ClassDetailsViewPage from "./Pages/Home/classManagement/classDetailsViewPage";
import AddClassPage from "./Pages/Home/classManagement/addClass";
import StudentsIndex from "./Pages/Home/Student/studentsIndex";
import AddStudent from "./Pages/Home/Student/addStudent";
import StudentsViewPage from "./Pages/Home/Student/StudentsViewPage";
import TeachersIndex from "./Pages/Home/Teachers/teachersIndex";
import AddTeacher from "./Pages/Home/Teachers/addTeacher";
import TeacherViewPage from "./Pages/Home/Teachers/teacherViewPage";
import Profile from "./Pages/Home/headerNavPages/profile";
import ChangePassword from "./Pages/Home/headerNavPages/changePassword";
import FunctioanlLayout from "./Layouts/functioanlLayout";
import StudentsBulkPreview from "./Pages/Home/Student/StudentsBulkPreview";
import CousesGamesIndex from "./Pages/Home/CourseGames/CousesGamesIndex";
import RoleManagementIndex from "./Pages/Home/roleManagement/roleManagementIndex";
import AddNewRole from "./Pages/Home/roleManagement/addNewRole";
import ChatIndex from "./Pages/Home/Chat/chatIndex";
import CalendarIndex from "./Pages/Home/Calendar/calendarIndex";
import NotificationIndex from "./Pages/Home/Notifications/notificationIndex";
import PaymentIndex from "./Pages/Home/Payment/paymentIndex";
import PaymentMethod from "./Pages/Home/Payment/PaymentMethod";
import PaymentReceipt from "./Pages/Home/Payment/paymentReceipt";
import AddCourse from "./Pages/Home/CourseGames/addCourse";
import SubscriptionList from "./Pages/Home/Payment/subscriptionList";
import HelpPage from "./Pages/Home/headerNavPages/HelpPage";
import PaymentWrapper from "./Pages/Home/Payment/PaymentWrapper";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordIndex />,
      },
    ],
  },
  {
    path: "/",
    element: <FunctioanlLayout />,
    children: [
      {
        path: "/students-bulk-preview",
        element: <StudentsBulkPreview />,
      },
      {
        path: "/invoice/:id",
        element: <PaymentReceipt />,
      },
    ],
  },
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        path: "/class-management",
        element: <ClassManagementIndex />,
      },
      {
        path: "/view-class/:id",
        element: <ClassDetailsViewPage />,
      },
      {
        path: "/add-class/:id",
        element: <AddClassPage />,
      },
      {
        path: "/edit-class/:id",
        element: <AddClassPage />,
      },
      {
        path: "/students",
        element: <StudentsIndex />,
      },
      {
        path: "/add-student/school/:id",
        element: <AddStudent />,
      },
      {
        path: "/edit-student-details/:id",
        element: <AddStudent />,
      },
      {
        path: "/view-student-details/:id",
        element: <StudentsViewPage />,
      },
      {
        path: "/teachers",
        element: <TeachersIndex />,
      },
      {
        path: "/add-teacher",
        element: <AddTeacher />,
      },
      {
        path: "/edit-teacher-details/:id",
        element: <AddTeacher />,
      },
      {
        path: "/view-teacher-details/:id",
        element: <TeacherViewPage />,
      },
      {
        path: "/cources",
        element: <CousesGamesIndex />,
      },
      {
        path: "/add-game",
        element: <AddCourse />,
      },
      {
        path: "/role-management",
        element: <RoleManagementIndex />,
      },
      {
        path: "/add-new-role/:id",
        element: <AddNewRole />,
      },
      {
        path: "/edit-role/:id",
        element: <AddNewRole />,
      },
      {
        path: "/chat",
        element: <ChatIndex />,
      },
      {
        path: "/calendar",
        element: <CalendarIndex />,
      },
      {
        path: "/notification",
        element: <NotificationIndex />,
      },
      {
        path: "/help",
        element: <HelpPage />,
      },
      {
        path: "/select-subscription",
        element: <SubscriptionList />,
      },
      {
        path: "/payment-details",
        element: <PaymentWrapper />,
      },
      {
        path: "/payment-method",
        element: <PaymentMethod />,
      },
    ],
  },
]);

export default Router;
