import { Button, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import classes from "./index.module.css";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { ExcelIcon, FileDeleteBinIcon } from "../Assets/Icons/icons";
import { GenerateBulkPassword } from "../Utilities/commonFunctions";
import { Navigate, useNavigate } from "react-router";
import { SaveSelectedFile } from "../Redux/actions/studentAction";
import AlertBox from "./AlertBox";

const CommanDropDown = ({ image, callback, type, name }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState();
  const fileInputRef = useRef(null);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { subscriptionList } = useSelector((state) => state.studentDatas);
  const { classesList } = useSelector((state) => state.classDatas);
  const [errorAlert, setErrorAlert] = useState(false);
  const [defaultErrorMessage, setDefaultErrorMessage] = useState("");

  const CloseAlert = () => {
    setErrorAlert(false);
    setDefaultErrorMessage("");
  };

  const onDrop = (acceptedFiles) => {
    try {
      console.log(acceptedFiles[0]);
      callback(acceptedFiles[0]);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  const handlePreviewClick = () => {
    navigate("/students-bulk-preview");
  };

  const getSubscriptionId = (name) => {
    const subscription = subscriptionList?.find(
      (item) => item?.subscription_name?.toLowerCase() === name?.toLowerCase()
    );
    return subscription?._id;
  };

  const getGradeId = (name) => {
    const grade = classesList?.find(
      (item) => item?.grade?.toLowerCase() === name?.toLowerCase()
    );
    return grade?._id;
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);
    ArrayFormFunction(selectedFile);
  };

  const ArrayFormFunction = (selectedFile) => {
    let arrayOfObjects;
    console.log("selectedFile", selectedFile);
    const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
    const maxSizeInMB = 24;

    if (fileSizeInMB > maxSizeInMB) {
      alert(`File size exceeds the maximum allowed size of ${maxSizeInMB} MB.`);
      // You can clear the input or handle it in another way based on your requirements
      setFiles(null); // Set files to null;
      return;
    }
    const fileName = selectedFile.name.split(".").pop();
    if (fileName === "xlsx" || fileName === "xls") {
      if (selectedFile && userInfo) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract headers (keys) from the first row
          const headers = dataArray[0];

          // Remove the first row (headers) and convert the rest to objects
          arrayOfObjects = await dataArray.slice(1).map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              const transformedKey = header.toLowerCase().replaceAll(" ", "_");
              obj[transformedKey] = row[index];
            });
            return obj;
          });
          const newCustomisedArray = await arrayOfObjects.map((item) => {
            // const grade = getGradeId(
            //   item?.grade?.toUpperCase()?.replaceAll(" ", "_")
            // );
            // const subscription = getSubscriptionId(item?.subscription_type);

            // Check if any key has an undefined value
            // if (
            //   item?.student_id === undefined ||
            //   item?.student_name === undefined ||
            //   item?.age === undefined ||
            //   item?.subscribed_by === undefined ||
            //   subscription === undefined ||
            //   grade === undefined ||
            //   item?.interest_section === undefined ||
            //   item?.parent_name === undefined ||
            //   item?.parent_email_id === undefined ||
            //   item?.parent_mobile_number === undefined ||
            //   item?.class === undefined ||
            //   userInfo?.schoolInfo?._id === undefined ||
            //   GenerateBulkPassword(item?.student_name) === undefined
            // ) {
            //   return null; // Skip this item by returning null
            // }

            let studentNameArray = item?.student_name.split(" ");

            return {
              student_id: item?.student_id?.toString(),
              name:
                studentNameArray.length > 1
                  ? item?.student_name
                  : `${item.student_name} ${item.parent_name}`,
              age: item?.age,
              subscribed_by: item?.subscribed_by?.toUpperCase(),
              subscription: item?.subscription_type,
              gradeId: item?.grade,
              interest: item?.interest_section
                ?.toLowerCase()
                .replaceAll(" ", "_"),
              parent_name: item?.parent_name,
              email: item?.parent_email_id,
              phone: item?.parent_mobile_number,
              section: item?.class?.toUpperCase().replaceAll(" ", "_"),
              school: userInfo?.schoolInfo?._id,
              password:
                item?.student_name && GenerateBulkPassword(item?.student_name),
            };
          });

          // Filter out null values from the array
          const filteredArray = newCustomisedArray.filter(
            (item) => item !== null
          );

          console.log(filteredArray);

          console.log("newCustomisedArray", filteredArray);
          const responce = {
            file: selectedFile,
            array: filteredArray,
          };
          dispatch(SaveSelectedFile(responce));
          callback(selectedFile, filteredArray);
        };
        reader.readAsBinaryString(selectedFile);
      }
    } else {
      setDefaultErrorMessage("Please upload file with extension .xlsx or .xls");
      setErrorAlert(true);
    }
  };

  useEffect(() => {
    console.log("FileSelectedNotImage", image);
    if (image) {
      setFiles(image || null);
      ArrayFormFunction(image);
    }
  }, [image]);

  const handleRemove = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFiles(null);
    callback(null, null);
  };

  return (
    <div>
      <div>
        <Dropzone onDrop={onDrop} multiple={false} noClick>
          {({ getRootProps }) => (
            <div {...getRootProps({ className: classes.dragAndDropArea })}>
              <input
                // {...getInputProps()}
                ref={fileInputRef} // Attach the ref to the file input
                onChange={handleFileInputChange}
                type="file"
                style={{ opacity: 0, position: "absolute", zIndex: 22 }}
                accept=".xls, .xlsx, .csv"
              />
              <Button className={classes.browseButton}>Browse...</Button>
              <Typography className={classes.dragDropText}>
                or drop file here
              </Typography>
            </div>
          )}
        </Dropzone>
        <Typography className={`mt-2 ${classes.someNoteText}`}>
          (Supported format: Xls / CSV only, max 24MB)
        </Typography>
      </div>
      {image && files && (
        <div>
          {type && type === "studentFile" ? (
            <div
              className={`d-flex align-items-center mt-3 ${classes.FileContainer}`}
            >
              <div className={`d-flex align-items-center ${classes.fileCard}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ExcelIcon />
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 10 }}
                  >
                    {files.name || name}
                  </Typography>
                </div>
                <Button
                  variant="text"
                  onClick={() => handleRemove(null)}
                  style={{ minWidth: 0 }}
                >
                  <FileDeleteBinIcon />
                </Button>
              </div>
              <Button
                variant="outlined"
                className={classes.previewButton}
                onClick={handlePreviewClick}
              >
                Preview
              </Button>
            </div>
          ) : null}
        </div>
      )}
      {errorAlert && defaultErrorMessage && (
        <AlertBox
          type="error"
          message={defaultErrorMessage}
          stateName={CloseAlert}
        />
      )}
    </div>
  );
};

export default CommanDropDown;
