import { authApi, getAuthorizationHeader } from "../../Api/api";
import { pageLoader } from "./userActions";


export const initiatePayment =
    (userEntry, SetInitiatePaySuccess, SetInitiatePayError,navigate) => async (dispatch) => {
        try {
            const { data } = await authApi.post(`/customer-subscription/initiate-payment`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                SetInitiatePaySuccess(data?.data);
                localStorage.setItem("secret_id",data?.data?.clientSecret);
                localStorage.setItem("coupon_code",userEntry?.coupon_code);
                navigate("/payment-method")
            }
        } catch (error) {
            SetInitiatePayError(error.response && error.response.data.message
                ? error.response.data.message
                : error.message);
            dispatch(pageLoader(false));
        }
    };