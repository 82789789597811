import {
  Card,
  TextField,
  InputAdornment,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import classes from "./chat.module.css";
import {
  ChatFileAttachmentIcon,
  ChatSendIcon,
  MicRecoredIcon,
  SearchIcon,
} from "../../../Assets/Icons/icons";
import ContactCard from "./contactCard";
import { chatConstants } from "../../../Utilities/CommonConstants";

const ChatIndex = () => {
  const [nameSearch, setNameSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState(chatConstants[0]);
  return (
    <div>
      <Card className={classes.ChatMainDiv}>
        <div className={classes.ContactListMainContainer}>
          <div className={classes.ContactListHeader}>
            <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              fullWidth
              placeholder="Search By"
              className={`${classes.TextInput} ${classes.searchBar}`}
              id="Searchinput"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.ContactListBody}>
            {chatConstants.map((item, index) => (
              <ContactCard
                item={item}
                key={index}
                index={index}
                length={chatConstants.length - 1}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            ))}
          </div>
        </div>
        <div className={classes.ChatContainer}>
          <div className={classes.ChatHeader}>
            <div
              className={`${classes.ContactcontenrCard} ${classes.chatHeaderProfileDiv}`}
            >
              <Avatar
                alt="Profile"
                src={selectedItem?.profile}
                className={classes.chatHeaderProfile}
              />
              <Typography className={classes.CardName}>
                {selectedItem?.name}
              </Typography>
              <Typography className={`${classes.chipText}`}>New</Typography>
            </div>
          </div>
          <div className={classes.ChatBody}></div>
          <div className={classes.ChatFooter}>
            <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              fullWidth
              placeholder="Type Something..."
              className={`${classes.ChatInput}`}
              id="Searchinput"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.InputAdornmentContainer}
                  >
                    <IconButton
                      onClick={() => console.log("Mic Clicked")}
                      edge="end"
                    >
                      <MicRecoredIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => console.log("AttachmentClicked Clicked")}
                      edge="end"
                    >
                      <ChatFileAttachmentIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => console.log("Send Clicked")}
                      edge="end"
                    >
                      <ChatSendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ChatIndex;
