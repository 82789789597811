import React, { useEffect, useState } from "react";
import classes from "./layout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import SideNavBar from "./SideNavBar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { getUserByToken } from "../Redux/actions/userActions";
import AlertBox from "../Components/AlertBox";
import { getCustomerSubscriptionList } from "../Redux/actions/subscriptionAction";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const token = localStorage.getItem("userToken");
  const { loading, toggleSideBar, userInfo, userRole, error } = useSelector(
    (state) => state.userLogin
  );
  const { customerSubscriptionList } = useSelector(
    (state) => state.subscriptionData
  );
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getCustomerSubscriptionList(userInfo?.schoolInfo?.user, setErrorAlert)
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      userRole !== "TEACHER" &&
      userInfo &&
      userInfo?.customerSubscriptionCount === 0 &&
      !window.location.pathname.includes("payment")
    ) {
      navigate("/profile");
    }
  }, [userInfo, userRole, window.location.pathname]);

  useEffect(() => {
    // console.log("token", token);
    if (token) {
      dispatch(getUserByToken(setErrorAlert, navigate));
    }
  }, [token, dispatch]);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  return (
    <div>
      {userRole && (
        <div className={classes.DefaultLayoutMaindiv}>
          <div
            className={classes.sideNavBarParent}
            style={{
              width: toggleSideBar ? "240px" : "72px",
              background:
                userRole === "TEACHER"
                  ? "var(--side-nav-background-color)"
                  : "var(--school-side-nav-background-color)",
            }}
          >
            <SideNavBar />
          </div>
          <div
            className={
              toggleSideBar
                ? classes.headerParent
                : `${classes.headerParent} ${classes.toggledHeaderParent}`
            }
          >
            <Header />
          </div>
          <div
            className={
              toggleSideBar
                ? classes.MainBodyChildDiv
                : `${classes.MainBodyChildDiv} ${classes.toggledMainBodyChildDiv}`
            }
          >
            <div>
              <Outlet />
            </div>
          </div>
          {errorAlert && error && (
            <AlertBox type="error" message={error} stateName={CloseAlert} />
          )}
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      )}
    </div>
  );
};

export default DefaultLayout;
