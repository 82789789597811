import {
  TableCell,
  TableRow,
  Typography,
  Chip,
  Button,
  MenuItem,
  Menu,
  Checkbox,
} from "@mui/material";
import classes from "./courses.module.css";
import React, { useEffect, useState } from "react";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  EditPenIcon,
  InactiveItemIcon,
  ReminderClockIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { StyledMenu } from "../../../Components/CommonMenu";
import SchoolActionModals from "../../../Components/SchoolActionModals";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  changeCourseStatus,
  deleteCourse,
  getAllCourses,
} from "../../../Redux/actions/courseAction";
import AlertBox from "../../../Components/AlertBox";

const CourseListItem = ({
  item,
  status,
  existGames,
  from,
  handleSingleChange,
  selectedCourses,
}) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState(0);
  const { error, success, piechartData, teacherCoursesPieChart } = useSelector(
    (state) => state.courseDatas
  );
  const { userInfo,userRole } = useSelector((state) => state.userLogin);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditCourseDetails = (e) => {
    handleClose(e);
    dispatch(setHeaderName("edit-game-details"));
    navigate(`/edit-game-details/${item?._id}`);
  };

  useEffect(() => {
    setChecked(status);
  }, [status]);

  const handleDelete = (event) => {
    handleClose(event);
    setType("delete");
    setHeight(205);
    setModalToggle(true);
  };

  const handleActive = (event) => {
    handleClose(event);
    setType("active/inactive");
    setHeight(205);
    setModalToggle(true);
  };

  const handleReloadDatas = () => {
    if (userInfo) {
      dispatch(
        getAllCourses(
          setErrorAlert,
          item?.courseInfo?.topic,
          userInfo?.schoolInfo?._id
        )
      );
    }
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleViewSchoolDetails = () => {
    // dispatch(setHeaderName("view-game"));
    // navigate(`/view-game/${item._id}`);
  };

  const handleDeleteCourse = () => {
    modalClose();
    dispatch(pageLoader(true));
    dispatch(
      deleteCourse(
        item._id,
        item?.courseInfo?.topic,
        setErrorAlert,
        setSuccessAlert,
        userInfo?.schoolInfo?._id,
        piechartData,
        teacherCoursesPieChart
      )
    );
    modalClose();
  };

  const handleActiveCourse = () => {
    modalClose();
    dispatch(pageLoader(true));
    dispatch(
      changeCourseStatus(
        item._id,
        item?.courseInfo?.topic,
        setSuccessAlert,
        setErrorAlert,
        userInfo?.schoolInfo?._id,
        piechartData,
        teacherCoursesPieChart
      )
    );
    modalClose();
  };

  const handleChange = (event) => {
    event.stopPropagation();
    handleSingleChange(event.target.checked, item._id);
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <TableRow
        key={item.id}
        className={classes.TableBodyRow}
        onClick={() => handleViewSchoolDetails()}
      >
        {from === "add" && (
          <TableCell flex={1} className={classes.firstCell}>
            <Checkbox
              checked={
                existGames.includes(item._id) ||
                selectedCourses.includes(item._id)
              }
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TableCell>
        )}
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.gameInfo?.name}
          </Typography>
        </TableCell>
        {from !== "add" && (
          <TableCell flex={1}>
            <Typography
              className={classes.TableBodyText}
              style={{ textTransform: "capitalize" }}
            >
              {item.gradeInfo?.grade?.charAt(0).toUpperCase() +
                item.gradeInfo?.grade
                  ?.slice(1)
                  .toLowerCase()
                  .replaceAll("_", " ")}
            </Typography>
          </TableCell>
        )}
        {from !== "add" && (
          <TableCell flex={1}>
            <Typography
              className={classes.TableBodyText}
              style={{ textTransform: "capitalize" }}
            >
              {item?.section?.charAt(0).toUpperCase() +
                item?.section?.slice(1).toLowerCase().replaceAll("_", " ")}
            </Typography>
          </TableCell>
        )}
        {from !== "add" && (
          <TableCell flex={1}>
            <Typography className={classes.TableBodyText}>
              {item?.subscriptionInfo?.subscription_name}
            </Typography>
          </TableCell>
        )}
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.gameInfo?.diamonds}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {`${item.level.length} level`}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Chip
            icon={item.isActive ? <ActiveItemIcon /> : <InactiveItemIcon />}
            style={{
              background: item.isActive
                ? "var(--active-chip-background)"
                : "var(--inactive-chip-background)",
            }}
            label={item.isActive ? "Active" : "Inactive"}
            className={classes.StatusChip}
          />
        </TableCell>
        <TableCell style={{ width: "fit-content" }}>
            { from !== "add" && (
            <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>)}
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem
              onClick={handleEditCourseDetails}
              disableRipple
              style={{ height: "40px" }}
            >
              <EditPenIcon />
              <span className={classes.MenuItemText}>Edit Details</span>
            </MenuItem> */}
            { ( userRole === "SCHOOL" || userInfo?.permissions?.includes("course&games:delete")) &&
              <MenuItem
              onClick={handleDelete}
              disableRipple
              style={{ height: "40px" }}
            >
              <DeleteBinIcon />
              <span className={classes.MenuItemText}>Remove from Topic</span>
            </MenuItem>}
            <MenuItem
              onClick={handleActive}
              disableRipple
              style={{ height: "40px" }}
            >
              <SwitchIcon />
              <span className={classes.MenuItemText}>Active/Inactive</span>
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          item={item}
          modalClose={modalClose}
          name={item.gameInfo.name}
          callback={
            type === "delete"
              ? handleDeleteCourse
              : type === "active/inactive"
              ? handleActiveCourse
              : null
          }
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleReloadDatas}
        />
      )}
    </React.Fragment>
  );
};

export default CourseListItem;
