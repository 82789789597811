import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";
import classes from "./index.module.css";
import React from "react";
import {
  ActiveItemIcon,
  InactiveItemIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const SubscriptionTableHistory = ({ data }) => {
  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.userLogin);
  return (
    <div>
      <Card>
        <div className={`${classes.TableContainer}`}>
          <Table className={classes.TableClass}>
            <TableHead>
              <TableRow className={classes.TableHeaderRow}>
                <TableCell
                  flex={1}
                  className={classes.firstCell}
                  style={{ minWidth: "75px" }}
                >
                  <span className={classes.TableHeaderText}>#No</span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "100px" }}>
                  <span className={classes.TableHeaderText}>Level</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "age")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("age") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "100px" }}>
                  <span className={classes.TableHeaderText}>Amount</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "gradeInfo.grade")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("gradeInfo.grade") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "155px" }}>
                  <span className={classes.TableHeaderText}>
                    Subscribed date
                  </span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "section")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("section") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "135px" }}>
                  <span className={classes.TableHeaderText}>End date</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "parent.phone")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("parent.phone") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1}>
                  <span className={classes.TableHeaderText}>Status</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "parent.email")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("parent.email") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell flex={1}>{`${(index + 1).toLocaleString(
                      "en-US",
                      {
                        minimumIntegerDigits: 3,
                        useGrouping: false,
                      }
                    )}`}</TableCell>
                    <TableCell flex={1}>{item?.name}</TableCell>
                    <TableCell flex={1}>{item?.amount}</TableCell>
                    <TableCell flex={1}>
                      {moment(item?.subscribed_date).format("D MMM YYYY")}
                    </TableCell>
                    <TableCell flex={1}>
                      {" "}
                      {moment(item?.end_date).format("D MMM YYYY")}
                    </TableCell>
                    <TableCell flex={1}>
                      <Chip
                        icon={
                          item.isActive ? (
                            <ActiveItemIcon />
                          ) : (
                            <InactiveItemIcon />
                          )
                        }
                        style={{
                          background: item.isActive
                            ? "var(--active-chip-background)"
                            : "var(--inactive-chip-background)",
                        }}
                        label={item.isActive ? "Active" : "Expired"}
                        className={classes.StatusChip}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default SubscriptionTableHistory;
