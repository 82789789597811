import { authApi, getAuthorizationHeader } from "../../Api/api";
import { ADD_CLASS_FAIL, ADD_CLASS_SUCCESS, GET_CLASSES_FAIL, GET_CLASSES_SUCCESS, GET_ONE_CLASS_FAIL, GET_ONE_CLASS_SUCCESS } from "../contants/classConstants";
import { pageLoader } from "./userActions";

export const addNewClass =
    (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.post("/class-admin/class-create", userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_CLASS_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
            }
        } catch (error) {
            dispatch({
                type: ADD_CLASS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const updateClass =
    (id, userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.patch(`/class-admin/class-update/${id}`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_CLASS_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
            }
        } catch (error) {
            dispatch({
                type: ADD_CLASS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const changeSectionStatus =
    (id, section, school, setErrorAlert, setSuccessAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.patch(`/class-admin/status-change/${id}?section=${section}`, {}, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_CLASS_SUCCESS,
                    payload: data.message,
                });
                dispatch(getAllClasses(setErrorAlert, "", school));
                setSuccessAlert(true);
            }
        } catch (error) {
            dispatch({
                type: ADD_CLASS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const deleteClass =
    (id, section, school, setSuccessAlert, setErrorAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.delete(`/class-admin/class-delete/${id}?section=${section}`,{},{
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_CLASS_SUCCESS,
                    payload: data.message,
                });
                dispatch(getAllClasses(setErrorAlert, "", school));
                setSuccessAlert(true);
            }
        } catch (error) {
            dispatch({
                type: ADD_CLASS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const getAllClasses = (setErrorAlert, search, school, selectedClass, sort, page) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/class-admin/class-all/${school}?search=${search || ""}&grade=${selectedClass || ""}&page=${page || 1}&limit=10&sort=${sort || ''}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_CLASSES_SUCCESS,
                payload: data.data[0],
            });
        }
    } catch (error) {
        dispatch({
            type: GET_CLASSES_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const getOneClass = (id, setErrorAlert) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/class-admin/class-me/${id}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_ONE_CLASS_SUCCESS,
                payload: data.data[0][0],
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ONE_CLASS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}