import { api, authApi, getAuthorizationHeader } from "../../Api/api";
import {
  ADD_ONE_SCHOOL_PIECHART_FAIL,
  ADD_ONE_SCHOOL_PIECHART_SUCCESS,
  FORGOT_PASSWORD_OTP,
  HEADER_NAME,
  LOGGEDIN_USER,
  PAGE_LOADER,
  PAGINATION_VALUE,
  PAYMENT_FAIL,
  PAYMENT_SUCCESS,
  SORT_BY_VALUE,
  TOGGLE_SIDEBAR,
  USER_CHANGE_PASSWORD_FAIL,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
} from "../contants/userConstants";

export const loginUser =
  (userEntry, navigate, errorAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/school/login", {
        ...userEntry,
      });
      localStorage.setItem("userToken", JSON.stringify(data?.data));
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data?.data?.data[0],
      });
      dispatch(pageLoader(false));
      navigate("/dashboard");
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const forgotPasswordEmailsend =
  (userEntry, setOtpScreen, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/school/forgot-password", {
        ...userEntry,
      });
      console.log("forget data we are getting", data);
      if (data.success) {
        dispatch(pageLoader(false));
        setOtpScreen(true);
        dispatch({
          type: FORGOT_PASSWORD_OTP,
          payload: data.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const VerifyOtp =
  (userEntry, setOtpScreen, setCreatepasswordScreen, setErrorAlert) =>
    async (dispatch) => {
      try {
        const { data } = await api.post("/school/verifyOtp", {
          ...userEntry,
        });
        dispatch(pageLoader(false));
        if (data.success) {
          localStorage.setItem("passwordToken", JSON.stringify(data.data));
          setOtpScreen(false);
          setCreatepasswordScreen(true);
        }
      } catch (error) {
        console.log("error", error);
        dispatch({
          type: USER_LOGIN_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
        setErrorAlert(true);
        dispatch(pageLoader(false));
      }
    };

export const resetForgetPassword =
  (userEntry, setSuccessModal, setErrorAlert) => async (dispatch) => {
    try {
      const token = JSON.parse(localStorage.getItem("passwordToken"));
      const { data } = await api.post(
        "/school/reset-password",
        { ...userEntry },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        localStorage.removeItem("passwordToken");
        setSuccessModal(true);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const setHeaderName = (name) => async (dispatch) => {
  dispatch({
    type: HEADER_NAME,
    payload: name,
  });
};

export const pageLoader = (value) => async (dispatch) => {
  dispatch({
    type: PAGE_LOADER,
    payload: value,
  });
};

export const setToggleSidebar = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: value,
  });
};

export const resetPassword = (userEntry, setErrorAlert) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("passwordToken"));
    const { data } = await authApi.post(
      "/super-admin/reset-user-password",
      userEntry,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data.message,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const changePassword =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        "/school/change-password",
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: USER_CHANGE_PASSWORD_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_CHANGE_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const setSortName = (value) => async (dispatch) => {
  dispatch({
    type: SORT_BY_VALUE,
    payload: value,
  });
};

export const setPageValue = (value) => async (dispatch) => {
  dispatch({
    type: PAGINATION_VALUE,
    payload: value,
  });
};

export const getUserByToken = (setErrorAlert, navigate) => async (dispatch) => {
  try {
    const { data } = await authApi.get("/auth/get-user", {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    // console.log("data", data);
    dispatch(pageLoader(false));
    if (data.success) {
      const responce = {
        user: data.data,
        role: data.data?.role[0],
      };
      dispatch({
        type: LOGGEDIN_USER,
        payload: responce,
      });
    }
  } catch (error) {
    console.log("error", error);
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("userToken");
      dispatch(pageLoader(false));
      navigate("/login");
    }
    else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }

  }
};

export const getOneSchoolsPieChart =
  (id, setErrorAlert, array) => async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/school-admin/one-school-piechart/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      let newData = [];
      Object.keys(data.data)?.map((item) => {
        const obj = array.find((element) => {
          if (element.key === item) {
            return element;
          } else {
            return null;
          }
        });
        newData.push({
          ...obj,
          number: data.data[item],
        });
        return null;
      });

      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_ONE_SCHOOL_PIECHART_SUCCESS,
          payload: newData,
        });
      }
    } catch (error) {
      dispatch({
        type: ADD_ONE_SCHOOL_PIECHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const paymentSuccessApi =
  (userEntry,setErrorAlert, setModalToggle) => async (dispatch) => {
    try {
      const { data } = await authApi.post(
        `/customer-subscription/payment-success`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        setModalToggle(true);
        dispatch({
          type: PAYMENT_SUCCESS,
          payload: data.data[0],
        });
        dispatch(getUserByToken(setErrorAlert));
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };
