import React, { useEffect, useState } from "react";
import classes from "./payment.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName } from "../../../Redux/actions/userActions";
import {
  BrudCrumsNextIcon,
  CheckCircleIcon,
  CloseIcon,
} from "../../../Assets/Icons/icons";
import { Col, Row } from "react-bootstrap";
import {
  Card,
  Divider,
  Typography,
  Grow,
  TextField,
  Button,
} from "@mui/material";
import Footer from "../../../Components/Footer";
import {
  getCouponWithCode,
  getOrderDetails,
} from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { initiatePayment } from "../../../Redux/actions/paymentAction";


const PaymentIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [coupon, setCoupon] = useState("");
  const { userInfo } = useSelector((state) => state.userLogin);
  const [applyCouponChecked, setApplyCouponChecked] = useState(false);
  const [couponInfo, setCouponInfo] = useState("");
  const [myTotal, setMyTotal] = useState("");
  const [couponErr, setCouponErr] = useState("");
  const [initiatePaySuccess, SetInitiatePaySuccess] = useState("");
  const [initiatePayError, SetInitiatePayError] = useState("");
  const { orderDetails } = useSelector((state) => state.subscriptionData);
  

  const convenianceFee = 10;

  const [clientSecret, setClientSecret] = useState("");
  // const stripe = useStripe();
  // const elements = useElements();
  const options = {
    clientSecret: process.env.REACT_APP_SECRET_KEY,
  };

  const handleNavigatePayment = () => {
    dispatch(setHeaderName("payment-details"));
    navigate("/payment-details");
  };

  const handleApplycoupon = () => {
    localStorage.setItem("coupon", coupon);
    if (coupon) {
      dispatch(
        getCouponWithCode(
          coupon,
          userInfo?._id,
          setApplyCouponChecked,
          applyCouponChecked,
          setCouponInfo,
          setCouponErr
        )
      );
    }

    // setApplyCouponChecked(!applyCouponChecked);
  };

  const removeCoupon = () => {
    console.log("remove coupon");
    localStorage.removeItem("coupon");
    setApplyCouponChecked(false);
    setCoupon("");
    setCouponInfo("");
  };

  const handleNavigatePaymentMethod = async () => {
    console.log("84")
     // ----------- three ----------------

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
    // });

    // if (error) {
    //   console.log("93",error)
    // } else {
      console.log("95")
          let userEntry = {
      user_id: userInfo?._id,
      order_id: orderDetails?._id,
      subscribed_by: "SCHOOL",
      coupon_code: coupon,
      amount: myTotal,
    };
      dispatch(
          initiatePayment(userEntry, SetInitiatePaySuccess, SetInitiatePayError,navigate)
         );
    // }

 // ----------- two ----------------
    // let userEntry = {
    //   user_id: userInfo?._id,
    //   order_id: orderDetails?._id,
    //   subscribed_by: "SCHOOL",
    //   coupon_code: coupon,
    // };
    // dispatch(
    //   initiatePayment(userEntry, SetInitiatePaySuccess, SetInitiatePayError)
    // );

    // ----------- one ----------------
    // dispatch(setHeaderName("payment-method"));
    // navigate("/payment-method");
    // const userEntry = {
    //   user_id: userInfo?._id,
    //   order_id: orderDetails?._id,
    //   subscribed_by: "SCHOOL",
    //   coupon_code:coupon,
    // };
    // dispatch(paymentSuccessApi(userEntry, setErrorAlert, setModalToggle));
  };

  useEffect(() => {
    if (userInfo && userInfo?._id) {
      dispatch(getOrderDetails(userInfo?._id, setErrorAlert));
    }
  }, [userInfo]);
  const getTotalAmount = () => {
    console.log("orderDetails =",orderDetails);
    const Amount = orderDetails?.subscriptionInfo?.price + convenianceFee;
    const discount1 = orderDetails?.subscriptionInfo?.price * 0.1;
    const discount2 = orderDetails?.subscriptionInfo?.price * 0.05;
    console.log(Amount , discount1 , discount2,'------------ ')
    const total = Amount - discount1 - discount2;
    // if (localStorage.getItem("coupon")) {
    //   return total - orderDetails?.subscriptionInfo?.price * 0.05;
    // }
    if (localStorage.getItem("coupon") && couponInfo) {
      let c_discound =
        (couponInfo?.discount / 100) * orderDetails?.subscriptionInfo?.price;
      // return total - orderDetails?.subscriptionInfo?.price * 0.05;
      return total - c_discound;
    }
    return total;
  };

  const handleNavProfile = () => {
    localStorage.removeItem("secret_id");
    localStorage.removeItem("coupon_code");
    localStorage.removeItem("coupon_info");
    localStorage.removeItem("coupon");
    navigate("/profile");
    dispatch(setHeaderName("Profile"));
  };

  function handleClientSecret() {
    let userEntry = {
      user_id: userInfo?._id,
      order_id: orderDetails?._id,
      subscribed_by: "SCHOOL",
      coupon_code: coupon,
    };
    dispatch(
      initiatePayment(userEntry, SetInitiatePaySuccess, SetInitiatePayError)
    );
  }
  console.log(coupon,applyCouponChecked ,'------------- 172')
  useEffect(() => {
    if (localStorage.getItem("coupon")) {
      setCoupon(localStorage.getItem("coupon"));
      setApplyCouponChecked(false);
      // handleApplycoupon();
    }
    if (localStorage.getItem("coupon_info")) {
      
      let a = {
        discount : localStorage.getItem("coupon_info")
      };
      setCouponInfo(a)
    }
  }, []);

  useEffect(() => {
    if (orderDetails) {
      let _total = getTotalAmount();

      console.log("_total =",_total)
      setMyTotal(_total);
    }

  }, [couponInfo,orderDetails]);

  useEffect(() => {
    // handleClientSecret();
  }, []);
  return (
    <div>
      {/* <Elements stripe={stripePromise} options={options}> */}
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigatePayment()}
          >
            Payment
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
            Payment Details
          </span>
        </div>
        <Row className="mt-3" style={{ rowGap: "20px", marginBottom: "70px" }}>
          <Col md={6}>
            <Card>
              <div style={{ padding: "15px 20px" }}>
                <Typography className={classes.cardHeader1}>
                  Level Details
                </Typography>
              </div>
              <div className={classes.cardHeaderContainer1}>
                <Typography
                  className={classes.cardHeader2}
                  style={{ textTransform: "capitalize" }}
                >
                  {orderDetails?.subscriptionInfo?.subscription_name}
                </Typography>
                <Typography className={classes.cardHeader2}>
                  £{orderDetails?.subscriptionInfo?.price}{" "}
                  <span className={classes.headerSubText}>
                    / {orderDetails?.subscriptionInfo?.duration}
                  </span>
                </Typography>
              </div>
              <div style={{ padding: "15px 20px" }}>
                <Typography className={`mt-2 ${classes.cardHeader3}`}>
                  What Inclued?
                </Typography>
                <Row className="mt-4" style={{ rowGap: "15px" }}>
                  {Array.isArray(orderDetails?.subscriptionInfo?.features) &&
                    orderDetails?.subscriptionInfo?.features?.map((datas) => (
                      <Col xs={6}>
                        <Typography className={classes.headerSubText}>
                          <span style={{ marginRight: "10px" }}>
                            <CheckCircleIcon />
                          </span>
                          {datas}
                        </Typography>
                      </Col>
                    ))}

                  {/* <Col xs={6}>
                  <Typography className={classes.headerSubText}>
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Col>
                <Col xs={6}>
                  <Typography className={classes.headerSubText}>
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Col>
                <Col xs={6}>
                  <Typography className={classes.headerSubText}>
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Col> */}
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={{ padding: "15px 20px" }}>
              <Typography className={classes.cardHeader1}>
                Payment Information
              </Typography>
              <div className={`mt-3 mb-2 ${classes.paymentDetailsMainDiv}`}>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Payment Method
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    £{orderDetails?.subscriptionInfo?.price}.00
                  </Typography>
                </div>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Discount Amount (5%)
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.05}.00
                  </Typography>
                </div>
                {localStorage.getItem("coupon") ? (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      {localStorage.getItem("coupon")} ({couponInfo?.discount}%)
                      <span
                        className={`${classes.applyButton} ${classes.remove}`}
                        onClick={() => removeCoupon()}
                      >
                        Remove
                      </span>
                    </Typography>
                    <Typography
                      className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                    >
                      - £
                      {(couponInfo?.discount / 100) *
                        orderDetails?.subscriptionInfo?.price}
                      .00
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      Coupon Discount
                    </Typography>
                    <Typography
                      className={`${classes.applyButton}`}
                      onClick={() => setApplyCouponChecked(!applyCouponChecked)}
                    >
                      Apply Coupon
                    </Typography>
                  </div>
                )}
                <Grow
                  in={applyCouponChecked}
                  style={{
                    transformOrigin: "0 0 0",
                  }}
                  {...(applyCouponChecked ? { timeout: 1000 } : {})}
                >
                  <div
                    className={classes.Couponcontainer}
                    style={{ display: applyCouponChecked ? "flex" : "none" }}
                  >
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                        width: "310px",
                      }}
                      id="descriptionInput"
                      fullWidth
                      size="small"
                      placeholder="Enter Coupon Code here"
                      className={classes.CouponInput}
                      value={coupon}
                      onChange={(e) => {
                        setCoupon(e.target.value);
                      }}
                    />
                    <div className={`d-flex align-items-center`}>
                      <Typography
                        className={`${classes.applyButton}`}
                        onClick={handleApplycoupon}
                      >
                        Apply
                      </Typography>
                      <Button
                        variant="text"
                        className={classes.closeButton}
                        onClick={() => {
                          setApplyCouponChecked(!applyCouponChecked);
                          removeCoupon();
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                </Grow>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Convenience Fee
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    £{convenianceFee}.00
                  </Typography>
                </div>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    10% Extra off
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.1}.00
                  </Typography>
                </div>
              </div>
              <Divider />
              <div className={`mt-2`}>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.cardHeader1}>
                    Total Amount (Inc. Tax)
                  </Typography>
                  <Typography className={`${classes.cardHeader1}`}>
                    £{myTotal}.00
                  </Typography>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {/* <CardElement /> */}
        <Footer
          callback={handleNavProfile}
          from={"payment"}
          edit={window.location.pathname.includes("edit")}
          submitCallBack={handleNavigatePaymentMethod}
        />
        {couponErr && (
          <AlertBox
            type="error"
            message={couponErr}
            stateName={() => setCouponErr("")}
          />
        )}
      {/* </Elements> */}
    </div>
  );
};

export default PaymentIndex;
