import React, { useEffect, useState } from "react";
import classes from "./courses.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { BrudCrumsNextIcon, SearchIcon } from "../../../Assets/Icons/icons";
import {
  Card,
  TextField,
  Typography,
  MenuItem,
  Box,
  Tabs,
  Tab,
  InputAdornment,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddCourseTable from "./addCourseTable";
import {
  AddCourseToSchool,
  getAllCourses,
  getSchoolCoureses,
} from "../../../Redux/actions/courseAction";
import _debounce from "lodash/debounce";
import Footer from "../../../Components/Footer";
import AlertBox from "../../../Components/AlertBox";
import { getAllClasses } from "../../../Redux/actions/classActions";

const AddCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [grade, setGrade] = useState("select");
  const [gradeError, setGradeError] = useState(false);
  const [section, setSection] = useState("select");
  const [sectionError, setSectionError] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState("select");
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [defaultErrorMessage, setDefaultErrorMessage] = useState();
  const [value, setValue] = useState(0);
  const [topic, setTopic] = useState("Computer Programming");
  const [existGames, setExistGames] = useState([]);
  const [search, setSearch] = useState("");
  const { userInfo, sortBy, page } = useSelector((state) => state.userLogin);
  const { coursesList, schoolCourseList, error, success } = useSelector(
    (state) => state.courseDatas
  );
  const [selectedCourses, setSelectedCourses] = useState([]);
  const { classesList } = useSelector((state) => state.classDatas);
  const { customerSubscriptionList } = useSelector(
    (state) => state.subscriptionData
  );
  const [dbCourses, setDbCourses] = useState([]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        index === value
          ? `${classes.selectedTab} ${classes.TabsLableHeader}`
          : classes.TabsLableHeader,
    };
  }

  useEffect(() => {
    if (coursesList && coursesList.length > 0) {
      const existingSchoolGames = coursesList.map((item) => {
        // console.log(item);
        return item?.courseInfo?._id;
      });
      console.log("Existing School Games", existingSchoolGames);
      setExistGames(existingSchoolGames);
    }
  }, [coursesList]);

  useEffect(() => {
    switch (value) {
      case 0:
        console.log("Computer Programming");
        setTopic("computer_programming");
        break;
      case 1:
        console.log("Algorithms");
        setTopic("algorithms");
        break;
      case 2:
        console.log("Digital Literacy");
        setTopic("digital_literacy");
        break;
      case 3:
        console.log("Safety");
        setTopic("safety");
        break;
      case 4:
        console.log("Computer Programming-2");
        setTopic("computer_programming-2");
        break;
      case 5:
        console.log("Information Technology");
        setTopic("information_technology");
        break;
      default:
        console.log("Computer Programming");
        setTopic("computer_programming");
        break;
    }
  }, [value]);

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
    setDefaultErrorMessage();
  };

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo) {
        dispatch(
          getSchoolCoureses(
            userInfo?.schoolInfo?._id,
            topic,
            setErrorAlert,
            search,
            page,
            sortBy,
            grade,
            subscriptionLevel
          )
        );
        dispatch(getAllClasses(setErrorAlert, "", userInfo?.schoolInfo?._id));
        dispatch(
          getAllCourses(
            setErrorAlert,
            topic,
            userInfo?.schoolInfo?._id,
            null,
            sortBy,
            search,
            "",
            grade
          )
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [dispatch, userInfo, topic, search, grade, subscriptionLevel, sortBy]);

  const handleCourseGames = () => {
    dispatch(setHeaderName("Course/Games"));
    navigate("/cources");
  };

  const handleChange = (event, newValue) => {
    console.log(event);
    setValue(newValue);
  };

  const handleSubmit = () => {
    if (grade === "select") {
      setGradeError(true);
    }
    if (section === "select") {
      setSectionError(true);
    }
    if (subscriptionLevel === "select") {
      setSubscriptionError(true);
    }
    if (
      grade !== "select" &&
      section !== "select" &&
      subscriptionLevel !== "select" &&
      selectedCourses.length < 1
    ) {
      setErrorAlert(true);
      setDefaultErrorMessage("Please select any of the courses");
    }
    if (
      grade !== "select" &&
      section !== "select" &&
      subscriptionLevel !== "select" &&
      selectedCourses.length > 0
    ) {
      const userEntry = {
        grade_id: grade,
        section: section,
        subscription_id: subscriptionLevel,
        courses: selectedCourses,
      };
      dispatch(pageLoader(true));
      dispatch(AddCourseToSchool(userEntry, setSuccessAlert, setErrorAlert));
    }
  };

  const handleSingleChange = (event, id) => {
    console.log("From Single in parent");
    if (event) {
      console.log([...selectedCourses, id]);
      setSelectedCourses([...selectedCourses, id]);
    } else {
      console.log(selectedCourses.filter((item) => item !== id));
      setSelectedCourses(selectedCourses.filter((item) => item !== id));
    }
  };

  const handleMainChange = (event) => {
    console.log("From Main in parent", event);
    if (event) {
      const courses = schoolCourseList.map((item) => {
        return item._id;
      });
      console.log(courses);
      setSelectedCourses(courses);
    } else {
      setSelectedCourses([]);
    }
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleCourseGames()}
        >
          Courses/Games
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          Add Game
        </span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={classes.LoginForm}
      >
        <Card style={{ padding: "20px" }} className="mt-4">
          <Typography className={classes.headingText}>Add Game for</Typography>
          <Row className="mt-3">
            <Col md={4}>
              <Typography className={classes.formLable}>
                Select Grade
              </Typography>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "15px",
                }}
                id="otpInput"
                select
                fullWidth
                placeholder="Enter OTP code"
                className={
                  grade === "select"
                    ? `${classes.TextInput} ${classes.defaultMenuItem}`
                    : classes.TextInput
                }
                value={grade}
                onChange={(e) => {
                  setGrade(e.target.value);
                  if (e.target.value !== "select") {
                    setGradeError(false);
                  }
                }}
                size="small"
                error={gradeError}
                helperText={gradeError && "Please Select Designation"}
              >
                <MenuItem key={"select"} value={"select"}>
                  Select Grade
                </MenuItem>
                {classesList &&
                  classesList.length > 0 &&
                  classesList.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.grade?.charAt(0).toUpperCase() +
                          item.grade
                            ?.slice(1)
                            .toLowerCase()
                            .replaceAll("_", " ")}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Col>
            <Col md={4}>
              <Typography className={classes.formLable}>
                Select Section
              </Typography>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "15px",
                }}
                id="otpInput"
                select
                fullWidth
                placeholder="Enter OTP code"
                className={
                  section === "select"
                    ? `${classes.TextInput} ${classes.defaultMenuItem}`
                    : classes.TextInput
                }
                value={section}
                onChange={(e) => {
                  setSection(e.target.value);
                  if (e.target.value !== "select") {
                    setSectionError(false);
                  }
                }}
                size="small"
                error={sectionError}
                helperText={sectionError && "Please Select Designation"}
              >
                <MenuItem
                  key={"select"}
                  value={"select"}
                  style={{ display: "none" }}
                >
                  Select Section
                </MenuItem>
                {classesList
                  .find((item) => item._id === grade)
                  ?.terminology?.filter((item) => item.isActive === true)
                  ?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.section}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.section?.charAt(0).toUpperCase() +
                          item.section
                            ?.slice(1)
                            .toLowerCase()
                            .replaceAll("_", " ")}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Col>
            <Col md={4}>
              <Typography className={classes.formLable}>
                Select Subscription Level
              </Typography>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "15px",
                }}
                id="otpInput"
                select
                fullWidth
                placeholder="Enter OTP code"
                className={
                  subscriptionLevel === "select"
                    ? `${classes.TextInput} ${classes.defaultMenuItem}`
                    : classes.TextInput
                }
                value={subscriptionLevel}
                onChange={(e) => {
                  setSubscriptionLevel(e.target.value);
                  if (e.target.value !== "select") {
                    setSubscriptionError(false);
                  }
                }}
                size="small"
                error={subscriptionError}
                helperText={subscriptionError && "Please Select Designation"}
              >
                <MenuItem
                  key={"select"}
                  value={"select"}
                  style={{ display: "none" }}
                >
                  Select Subscription Level
                </MenuItem>
                {customerSubscriptionList &&
                  customerSubscriptionList.length > 0 &&
                  customerSubscriptionList.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item?.subscriptionInfo?._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.subscriptionInfo?.subscription_name}
                      </MenuItem>
                    );
                  })}
                {/* <MenuItem
                  key={1}
                  value={userInfo?.subscriptionInfo?._id}
                  style={{ textTransform: "capitalize" }}
                >
                  {userInfo?.subscriptionInfo?.subscription_name}
                </MenuItem> */}
              </TextField>
            </Col>
          </Row>
        </Card>
        <Card className="mt-3" style={{ marginBottom: "60px" }}>
          <div style={{ padding: "20px" }}>
            <Typography className={classes.headingText}>
              Add Topic and Game
            </Typography>
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className={classes.TabsHeaderDiv}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "var(--forgot-password-text-color)",
                      height: "4px",
                    },
                  }}
                >
                  <Tab label="Computer Programming" {...a11yProps(0)} />
                  <Tab label="Algorithms" {...a11yProps(1)} />
                  <Tab label="Digital Literacy" {...a11yProps(2)} />
                  <Tab label="Safety" {...a11yProps(3)} />
                  <Tab label="Computer Programming-2" {...a11yProps(4)} />
                  <Tab label="Information Technology" {...a11yProps(5)} />
                </Tabs>
              </Box>
            </Box>
            <div className={`mt-3`}>
              <TextField
                sx={{
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
                fullWidth
                placeholder="Search by Student Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.searchBar}
                id="firstNameinput"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
              />
            </div>
          </div>
          <AddCourseTable
            value={value}
            data={schoolCourseList}
            existGames={existGames}
            handleSingleChange={handleSingleChange}
            handleMainChange={handleMainChange}
            selectedCourses={selectedCourses}
          />
        </Card>
        <Footer
          callback={handleCourseGames}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : errorAlert && defaultErrorMessage ? (
        <AlertBox
          type="error"
          message={defaultErrorMessage}
          stateName={CloseAlert}
        />
      ) : null}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleCourseGames}
        />
      )}
    </div>
  );
};

export default AddCourse;
