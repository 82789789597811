import React, { useState } from "react";
import _debounce from "lodash/debounce";
import {
  Card,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
} from "@mui/material";
import classes from "./classManagement.module.css";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  pageLoader,
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import ClassListItem from "./classListItem";
import DefaultPagination from "../../../Components/Pagination";
import AlertBox from "../../../Components/AlertBox";
import { useEffect } from "react";
import { getAllClasses } from "../../../Redux/actions/classActions";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import NodataFound from "../../../Components/nodataFound";

const ClassManagementIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [classlist, setClassList] = useState([]);
  const [selectedClass, setSelectedClass] = useState("select");
  const [errorAlert, setErrorAlert] = useState(false);
  const { classesList, error, totalCount } = useSelector(
    (state) => state.classDatas
  );
  const { sortBy, page, userInfo } = useSelector((state) => state.userLogin);
  const CloseAlert = () => {
    setErrorAlert(false);
  };
  console.log("classesList =", classesList);
  useEffect(() => {
    dispatch(setPageValue(1));
  }, [dispatch]);

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo !== undefined) {
        dispatch(pageLoader(true));
        dispatch(
          getAllClasses(
            setErrorAlert,
            search.replaceAll(" ", "_"),
            userInfo?.schoolInfo?._id,
            selectedClass,
            sortBy,
            page
          )
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [dispatch, userInfo, sortBy, page, search, selectedClass]);

  useEffect(() => {
    if (classesList && classesList.length === totalCount) {
      setClassList(classesList);
    }
  }, [classesList, dispatch, classlist.length]);

  const handleSeachOption = () => {
    dispatch(pageLoader(true));
    dispatch(
      getAllClasses(
        setErrorAlert,
        search,
        userInfo?.schoolInfo?._id,
        sortBy,
        page
      )
    );
  };

  const handleAddClassClick = () => {
    dispatch(setHeaderName("add-class"));
    navigate(`/add-class/${userInfo?.schoolInfo?._id}`);
  };

  return (
    <div>
      <Card className={classes.CardContainer}>
        <div style={{ padding: "20px" }}>
          <Typography className={classes.PageMaindiv}>
            All Class Details
          </Typography>
          <div className={`mt-4 ${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Class name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={`${classes.TextInput} ${classes.searchBar}`}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className={classes.searchButton}
                  onClick={handleSeachOption}
                >
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
              <div>
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  select
                  id="gradeInput"
                  fullWidth
                  placeholder="Select Grade Level"
                  className={
                    selectedClass === "select"
                      ? `${classes.TextInput} ${classes.defaultMenuItem}`
                      : classes.TextInput
                  }
                  value={selectedClass}
                  onChange={(e) => {
                    setSelectedClass(e.target.value);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Grade is required"]}
                >
                  <MenuItem
                    key={"select"}
                    value={"select"}
                    className={classes.defaultMenuItem}
                  >
                    Select{" "}
                    {classlist?.length > 0
                      ? classlist[0].type.toLowerCase()
                      : "grade"}
                  </MenuItem>
                  {classlist?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item._id}>
                        {item.grade.charAt(0).toUpperCase() +
                          item.grade
                            .slice(1)
                            .toLowerCase()
                            .replaceAll("_", " ")}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
            <div
              className={`d-flex align-items-center ${classes.btnContainer}`}
              style={{
                columnGap: "10px",
              }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddClassClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add New</span>
              </Button>
            </div>
          </div>
        </div>
        {totalCount && totalCount > 0 ? (
          <div className={`mt-3 ${classes.TableContainer}`}>
            <Table className={classes.TableClass}>
              <TableHead>
                <TableRow className={classes.TableHeaderRow}>
                  <TableCell
                    flex={1}
                    className={classes.firstCell}
                    style={{ minWidth: 105 }}
                  >
                    <span className={classes.TableHeaderText}>Grade ID</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "gradeId")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("gradeId") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 125 }}>
                    <span className={classes.TableHeaderText}>
                      {classesList[0]?.type === "CLASS"
                        ? "Class Name"
                        : classesList[0]?.type === "GRADE"
                        ? "Grade Name"
                        : "YEARS"}
                    </span>
                    <span
                      onClick={() => handleSortClick(dispatch, sortBy, "grade")}
                    >
                      {sortBy?.includes("desc") && sortBy?.includes("grade") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 130 }}>
                    <span className={classes.TableHeaderText}>
                      Section Name
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "terminology.section")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("terminology.section") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 130 }}>
                    <span className={classes.TableHeaderText}>
                      Total Student
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(
                          dispatch,
                          sortBy,
                          "terminology.numberOfStudent"
                        )
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("terminology.numberOfStudent") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>Status</span>
                    <span
                      onClick={() =>
                        handleSortClick(
                          dispatch,
                          sortBy,
                          "terminology.isActive"
                        )
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("terminology.isActive") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={0}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classesList
                  .filter((item) => item.schoolId === userInfo?.schoolInfo?._id)
                  .map((item, index) => {
                    return (
                      <ClassListItem item={item} key={index} index={index} />
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div>
            <NodataFound text={"Please add Class to view"} />
          </div>
        )}
        {totalCount && totalCount > 0 ? (
          <div className={`mt-3`}>
            <DefaultPagination count={totalCount} />
          </div>
        ) : null}
      </Card>
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : null}
    </div>
  );
};

export default ClassManagementIndex;
