import React, { useState } from "react";
import classes from "./index.module.css";
import Carousel from "react-material-ui-carousel";
import { CardContent, Button, Typography, Paper, Card } from "@mui/material";
import { CheckCircleIcon } from "../../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import image from "../../../Assets/Images/Img_Avatar.png";
import { useNavigate } from "react-router";
import moment from "moment";
import { GenerateOrder } from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";

const SubscriptionCorosel = () => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { customerSubscriptionList, error } = useSelector(
    (state) => state.subscriptionData
  );
  const [index, setIndex] = useState(0);
  return (
    <Carousel>
      {customerSubscriptionList &&
        customerSubscriptionList.length > 0 &&
        customerSubscriptionList.map((item, i) => (
          <Item key={i} item={item} userInfo={userInfo} />
        ))}
    </Carousel>
  );
};

function Item({ userInfo, item }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const { error } = useSelector((state) => state.subscriptionData);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleRenewSubscription = (item) => {
    console.log(item);
    const userEntry = {
      user_id: item?.user,
      subscription_id: item?.subscription_id,
      subscribed_by: "SCHOOL",
      type: "renew",
    };
    dispatch(GenerateOrder(userEntry, setErrorAlert, navigate));
  };
  return (
    <Paper>
      <Card className={classes.subcontainer2}>
        <Typography className={classes.SubMainHeading}>Subscription</Typography>
        <Typography className={`mt-3 ${classes.SubMainHeading2}`}>
          {item?.subscriptionInfo?.subscription_name}
        </Typography>
        <div className={`mt-3 ${classes.descriptioncontainer}`}>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            <span style={{ marginRight: "10px" }}>
              <CheckCircleIcon />
            </span>
            All Unlocked Topics
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            <span style={{ marginRight: "10px" }}>
              <CheckCircleIcon />
            </span>
            Lorem ipsum dolor sit amet
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            <span style={{ marginRight: "10px" }}>
              <CheckCircleIcon />
            </span>
            Lorem ipsum dolor sit amet, conse ctetur adipiscing
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            <span style={{ marginRight: "10px" }}>
              <CheckCircleIcon />
            </span>
            Elit sed do eiusmod tempor incididunt ut
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            <span style={{ marginRight: "10px" }}>
              <CheckCircleIcon />
            </span>
            Lorem ipsum dolor sit amet
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            <span style={{ marginRight: "10px" }}>
              <CheckCircleIcon />
            </span>
            Elit sed do eiusmod tempor incididunt ut
          </Typography>
        </div>
        <div className={classes.subscriptionFooter}>
          <Typography className={classes.MenuItemText}>
            End Date:{" "}
            <span className={classes.ExtrafontWeight}>
              {moment(item.end_date).format("D MMMM YYYY")}
            </span>
          </Typography>
          {userInfo?.customerSubscriptionCount > 0 ? (
            <div className={classes.subscriptionBtnSection}>
              <Button
                variant="outlined"
                style={{ color: "var(--forgot-password-text-color)" }}
                disabled={moment(item?.start_date).isAfter(moment())}
                onClick={() => handleRenewSubscription(item)}
              >
                {moment(item?.start_date).isAfter(moment())
                  ? "Already Renewed"
                  : "Renew Plan"}
              </Button>
              <Button
                variant="contained"
                style={{ background: "var(--forgot-password-text-color)" }}
                onClick={() => navigate("/select-subscription")}
              >
                View Plan
              </Button>
            </div>
          ) : (
            <div className={classes.subscriptionBtnSection}>
              <Button
                variant="contained"
                style={{ background: "var(--forgot-password-text-color)" }}
                onClick={() => navigate("/payment-details")}
              >
                Active Plan
              </Button>
            </div>
          )}
        </div>
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </Paper>
  );
}

export default SubscriptionCorosel;
