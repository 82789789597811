import React, { useEffect, useRef, useState } from "react";
import classes from "./calendar.module.css";
import { Typography, Card, Popper } from "@mui/material";
import moment from "moment";
import { Button } from "react-bootstrap";
import {
  CalendarLeftIcon,
  CalendarRightIcon,
} from "../../../Assets/Icons/icons";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventModal from "./eventModal";
import EventViewModal from "./eventViewModal";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../../Components/AlertBox";
import { getAllEventsList } from "../../../Redux/actions/eventsActions";

const CalendarIndex = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [viewType, setViewType] = useState("dayGridMonth");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const { success, error, eventsList } = useSelector(
    (state) => state.eventsData
  );
  console.log("eventsList", eventsList);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [eventsData, setEventsData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [modalToggle1, setModalToggle1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const dayHeaderFormatOption = {
    weekday: "short",
    day: "numeric",
  };

  useEffect(() => {
    if (eventsList && eventsList.length > 0) {
      setEventsData(
        eventsList.map((event) => {
          return {
            title: event.title,
            _id: event._id,
            description: event.description,
            start: new Date(event.date_time),
            backgroundColor: event.bg_color,
          };
        })
      );
    }
  }, [eventsList]);

  const handleDateClick = (info) => {
    // console.log("Cliceked Date Info", info);
    setSelectedDate(info.dateStr); // Save the selected date
    setAnchorEl(info.jsEvent.target);
    setModalToggle1(false);
    setEditClicked(false);
    setModalToggle(true);
  };

  const handleEditClick = () => {
    setModalToggle1(false);
    setModalToggle(true);
    setEditClicked(true);
  };

  const handleEventClick = (info) => {
    // console.log("Cliceked Event Info", info);
    setAnchorEl(info.jsEvent.target);
    console.log("Selected Event Id", info.event._def.extendedProps._id);
    const oneEvent = eventsList.find(
      (event) => event._id === info.event._def.extendedProps._id
    );
    console.log("Selected Event", oneEvent);
    setSelectedEvent(oneEvent);
    setModalToggle(false);
    setEditClicked(false);
    setModalToggle1(true);
  };

  const modalClose = () => {
    setAnchorEl(null);
    setModalToggle(false);
    setModalToggle1(false);
    setEditClicked(false);
    getEventsDataFunction();
  };

  const handleNext = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      console.log(calendarRef.current.getApi());
      setCurrentMonth(calendarRef.current.getApi().currentData.viewTitle);
    }
  };

  const getEventsDataFunction = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const currentView = calendarApi.view;

      let intervalStart, intervalEnd;

      if (currentView.type === "dayGridMonth") {
        intervalStart = moment(currentView.currentStart).startOf("month");
        intervalEnd = moment(currentView.currentStart).endOf("month");
      } else {
        intervalStart = currentView.activeStart;
        intervalEnd = currentView.activeEnd;
      }

      if (intervalEnd && intervalStart && userInfo) {
        dispatch(
          getAllEventsList(
            userInfo?._id,
            moment(intervalStart).format("YYYY-MM-DD"),
            moment(intervalEnd).format("YYYY-MM-DD"),
            setErrorAlert
          )
        );
      }
    }
  };

  useEffect(() => {
    getEventsDataFunction();
  }, [dispatch, viewType, currentMonth, userInfo]);

  const handlePrev = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      setCurrentMonth(calendarRef.current.getApi().currentData.viewTitle);
    }
  };

  const handleViewChange = (viewType) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(viewType);
      setViewType(viewType);
      setCurrentMonth(calendarRef.current.getApi().currentData.viewTitle);
    }
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
    getEventsDataFunction();
  };

  return (
    <div>
      <Card className={classes.headerCardContainer}>
        <div className={classes.headerLeftContainer}>
          <Typography className={classes.calendarDateTextMain}>
            {currentMonth ? currentMonth : moment().format("MMMM YYYY")}
          </Typography>
          <Button
            variant="text"
            className={classes.leftRightBtns}
            onClick={handlePrev}
          >
            <CalendarLeftIcon />
          </Button>
          <Button
            variant="text"
            className={classes.leftRightBtns}
            onClick={handleNext}
          >
            <CalendarRightIcon />
          </Button>
        </div>
        <div className={classes.headerRightContainer}>
          <Button
            variant="text"
            onClick={() => handleViewChange("timeGridDay")}
            className={
              viewType === "timeGridDay"
                ? `${classes.headerbtns} ${classes.activebtn}`
                : classes.headerbtns
            }
          >
            Today
          </Button>
          <Button
            variant="text"
            onClick={() => handleViewChange("timeGridWeek")}
            className={
              viewType === "timeGridWeek"
                ? `${classes.headerbtns} ${classes.activebtn}`
                : classes.headerbtns
            }
          >
            Week
          </Button>
          <Button
            variant="text"
            onClick={() => handleViewChange("dayGridMonth")}
            className={
              viewType === "dayGridMonth"
                ? `${classes.headerbtns} ${classes.activebtn}`
                : classes.headerbtns
            }
          >
            Month
          </Button>
        </div>
      </Card>

      <Card className={`mt-3 ${classes.CalendarContainer}`}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={viewType}
          headerToolbar={false}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          events={eventsData}
          views={{
            timeGridWeek: {
              dayHeaderContent: (arg) => {
                return `${moment(arg.date).format("ddd D")}`;
              },
            },
            timeGridDay: {
              dayHeaderContent: (arg) => {
                return `${moment(arg.date).format("ddd D")}`;
              },
            },
          }}
        />
      </Card>
      {modalToggle && anchorEl && (
        <EventModal
          modalClose={modalClose}
          position={anchorEl}
          viewType={viewType}
          isEdit={editClicked}
          oneEvent={selectedEvent}
          events={eventsData}
          height={280}
          width={237}
          setSuccessAlert={setSuccessAlert}
          setErrorAlert={setErrorAlert}
          selectedDate={selectedDate}
        />
      )}
      {modalToggle1 && anchorEl && (
        <EventViewModal
          modalClose={modalClose}
          position={anchorEl}
          viewType={viewType}
          events={[]}
          handleEditClick={handleEditClick}
          oneEvent={selectedEvent}
          height={280}
          width={237}
          setSuccessAlert={setSuccessAlert}
          setErrorAlert={setErrorAlert}
          selectedDate={selectedDate}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default CalendarIndex;
