import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classes from "./roleManagement.module.css";
import {
  Card,
  InputAdornment,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { setHeaderName } from "../../../Redux/actions/userActions";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import RoleManagementListItem from "./roleManagementListItem";
import DefaultPagination from "../../../Components/Pagination";
import NodataFound from "../../../Components/nodataFound";
import { getAllRoles } from "../../../Redux/actions/roleManagementAction";
import _debounce from "lodash/debounce";
import AlertBox from "../../../Components/AlertBox";
const RoleManagementIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const [search, setSearch] = useState("");
  const { sortBy, page, userInfo } = useSelector((state) => state.userLogin);
  const { error, rolesList, totalCount } = useSelector(
    (state) => state.roleDatas
  );
  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo) {
        dispatch(
          getAllRoles(
            setErrorAlert,
            "schools",
            userInfo?.schoolInfo?._id,
            search,
            sortBy,
            page
          )
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [dispatch, search, sortBy, page, userInfo]);

  const handleAddRoleClick = () => {
    dispatch(setHeaderName("add-new-role"));
    navigate(`/add-new-role/school-${userInfo?.schoolInfo?._id}`);
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  return (
    <div>
      <Card>
        <div style={{ padding: "20px" }}>
          <div className={`${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Designation, Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={`${classes.TextInput} ${classes.searchBar}`}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button variant="contained" className={classes.searchButton}>
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                columnGap: "10px",
              }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddRoleClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add Role</span>
              </Button>
            </div>
          </div>
        </div>
        {totalCount && totalCount > 0 ? (
          <div>
            <div className={`${classes.TableContainer}`}>
              <Table className={classes.TableClass}>
                <TableHead>
                  <TableRow className={classes.TableHeaderRow}>
                    <TableCell
                      flex={1}
                      className={classes.firstCell}
                      style={{ minWidth: 85 }}
                    >
                      <span className={classes.TableHeaderText}>#No</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "createdAt")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("createdAt") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        Designation
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "designationInfo.name"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("designationInfo") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        Access Level
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "accessInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("accessInfo") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell style={{ width: "60px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rolesList &&
                    rolesList.length > 0 &&
                    rolesList?.map((item, index) => {
                      console.log(
                        "index",
                        index + 1 + (page - 1) * 10,
                        "Page ===> ",
                        page
                      );
                      return (
                        <RoleManagementListItem
                          item={item}
                          key={index}
                          index={index + 1 + (page - 1) * 10}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <div className={`mt-3`}>
              <DefaultPagination count={rolesList.length} />
            </div>
          </div>
        ) : (
          <div>
            <NodataFound text={"Please add roles to view"} />
          </div>
        )}
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default RoleManagementIndex;
