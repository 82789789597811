import { Card, Typography } from "@mui/material";
import classes from "./index.module.css";
import React from "react";
import { Col, Row } from "react-bootstrap";

const TeacherStatisticCard = ({ data }) => {
  return (
    <Row style={{ rowGap: "20px" }}>
      {data?.map((item, index) => (
        <Col md={4}>
          <Card
            style={{ background: item.color }}
            className={classes.cardConatainerforPie}
          >
            <Typography
              className={classes.reminderHeading}
              style={{ textAlign: "left" }}
            >
              {item.number}
            </Typography>
            <Typography className={classes.statisticsTitleText}>
              {item.title}
            </Typography>
            <span className={classes.iconSpan}>{item.icon}</span>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default TeacherStatisticCard;
