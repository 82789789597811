import { Button } from "@mui/material";
import React from "react";
import classes from "./index.module.css";
import { useSelector } from "react-redux";

const Footer = ({ callback, edit, from, submitCallBack }) => {
  const { toggleSideBar } = useSelector((state) => state.userLogin);
  return (
    <div
      className={classes.FooterMainDiv}
      style={{
        width: toggleSideBar ? "calc(100% - 240px)" : "calc(100% - 72px)",
        left: toggleSideBar ? "240px" : "72px",
        boxShadow: "0px 0px 10px 0px rgba(0, 56, 99, 0.20)",
      }}
    >
      <Button
        variant="outlined"
        className={classes.FooterBtn}
        onClick={() => callback()}
        style={{
          color: "var(--forgot-password-text-color)",
          borderColor: "var(--forgot-password-text-color)",
        }}
      >
        Cancel
      </Button>
      {/* {from && from === "payment" && (
        <Button
          variant="outlined"
          className={classes.FooterBtn}
          style={{
            color: "var(--forgot-password-text-color)",
            borderColor: "var(--forgot-password-text-color)",
          }}
        >
          Free Trial
        </Button>
      )} */}
      {from === "payment" ? (
        <Button
          variant="contained"
          className={classes.FooterBtn}
          onClick={() => submitCallBack()}
          style={{
            background: "var(--forgot-password-text-color)",
          }}
        >
          Make a Payment
        </Button>
      ) : (
        <Button
          variant="contained"
          className={classes.FooterBtn}
          type="submit"
          style={{
            background: "var(--forgot-password-text-color)",
          }}
        >
          {edit ? "Save" : "Submit"}
        </Button>
      )}
    </div>
  );
};

export default Footer;
