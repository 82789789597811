import { Button, Card } from "@mui/material";
import classes from "./student.module.css";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterIcon,
  RoundAddIcon,
  RoundAddPurpleIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import DefaultPagination from "../../../Components/Pagination";
import { getAllStudents } from "../../../Redux/actions/studentAction";
import _debounce from "lodash/debounce";
import StudentTableListItem from "./StudentTableListItem";
import { useNavigate } from "react-router-dom";
import { setHeaderName } from "../../../Redux/actions/userActions";

const StudentTeacherTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.userLogin);
  const [search, setSearch] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const { error, studentsList, totalCount } = useSelector(
    (state) => state.studentDatas
  );
  const { page, userInfo,userRole } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo && userInfo?.schoolInfo?._id) {
        const userEntry = {
          schools: [userInfo?.schoolInfo?._id],
          classes: [],
        };
        dispatch(
          getAllStudents(setErrorAlert, search, userEntry, sortBy, page)
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [dispatch, userInfo, sortBy, page, search]);

  const handleAddStudentClick = () => {
    dispatch(setHeaderName("add-student"));
    navigate("/add-student/school/1");
  };

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
    renderTabledata();
  };

  const renderTabledata = () => {
    if (studentsList && studentsList.length > 0) {
      return studentsList?.map((item, index) => {
        return (
          <StudentTableListItem
            item={item}
            key={index}
            value={0}
            from={"TEACHER"}
            status={checked}
          />
        );
      });
    }
  };

  return (
    <Card>
      <div className={classes.Searchbackground}>
        <TextField
          sx={{
            "& legend": {
              display: "none",
            },
            "& fieldset": {
              top: 0,
            },
          }}
          fullWidth
          placeholder="Search by"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={`${classes.searchBar} ${classes.toggleSearchBar}`}
          id="firstNameinput"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
        />
        <div className={classes.filterBtnsContainer}>
          {/* <Button variant="text" className={classes.filterBtn}>
            <FilterIcon />
            <span>Filter</span>
          </Button> */}
          {/* <span className={classes.dividerClass} /> */}
         {( userRole === "SCHOOL" || userInfo?.permissions?.includes("student:add")) &&
          <Button
            variant="text"
            className={classes.filterBtn}
            onClick={handleAddStudentClick}
          >
            <RoundAddPurpleIcon />
            <span style={{ width: "max-content" }}>Add Student</span>
          </Button>}
        </div>
      </div>
      <div>
        <div className={`mb-3 ${classes.TableContainer}`}>
          <Table className={classes.TableClass}>
            <TableHead>
              <TableRow className={classes.TableHeaderRow1}>
                <TableCell flex={1} className={classes.firstCell}>
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                <TableCell
                  flex={1}
                  className={classes.firstCell}
                  style={{ minWidth: "135px" }}
                >
                  <span className={classes.TableHeaderText}>Student Name</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "first_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("first_name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "80px" }}>
                  <span className={classes.TableHeaderText}>Age</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "age")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("age") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "90px" }}>
                  <span className={classes.TableHeaderText}>Grade</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "gradeInfo.grade")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("gradeInfo.grade") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>

                <TableCell flex={1} style={{ minWidth: "95px" }}>
                  <span className={classes.TableHeaderText}>Section</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "section")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("section") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "185px" }}>
                  <span className={classes.TableHeaderText}>
                    Parent Contact number
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "parent.phone")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("parent.phone") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "185px" }}>
                  <span className={classes.TableHeaderText}>
                    Parent Email ID
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "parent.email")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("parent.email") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "165px" }}>
                  <span className={classes.TableHeaderText}>
                    SubscriptionLevel
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "isActive")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("isActive") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "115px" }}>
                  <span className={classes.TableHeaderText}>Status</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "isActive")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("isActive") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTabledata()}</TableBody>
          </Table>
        </div>
        <DefaultPagination count={totalCount} />
      </div>
    </Card>
  );
};

export default StudentTeacherTable;
