import { authApi, getAuthorizationHeader } from "../../Api/api";
import {
  CLEAR_NOTIFICATIONS_FAIL,
  CLEAR_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,
  MARK_ALL_NOTIFICATION_READ_FAIL,
  MARK_ALL_NOTIFICATION_READ_SUCCESS,
  MARK_NOTIFICATION_READ_FAIL,
  MARK_NOTIFICATION_READ_SUCCESS,
} from "../contants/notificationConstants";
import { pageLoader } from "./userActions";

export const getAllNotification = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/notification/get-notifications/${id}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const updateNotificationStatus =
  (id, body, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/notification/update-notification/${id}`,
        body,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: MARK_NOTIFICATION_READ_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: MARK_NOTIFICATION_READ_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateAllNotificationStatus =
  (id, body, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/notification/update-all-user-notification/${id}`,
        body,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: MARK_ALL_NOTIFICATION_READ_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: MARK_ALL_NOTIFICATION_READ_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const deleteNotification =
  (id, user_id, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/notification/delete-notification/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: DELETE_NOTIFICATION_SUCCESS,
          payload: data.message,
        });
        dispatch(getAllNotification(user_id, setErrorAlert));
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: DELETE_NOTIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const deleteAllNotification =
  (id, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/notification/delete-all-user-notification/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: CLEAR_NOTIFICATIONS_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: CLEAR_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };
