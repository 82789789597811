import image1 from "../Assets/Images/image1.png";
import image2 from "../Assets/Images/image2.png";
import image3 from "../Assets/Images/image3.png";
import image4 from "../Assets/Images/image4.png";
import profile1 from "../Assets/Images/profile1.png";
import profile2 from "../Assets/Images/profile2.png";
import profile3 from "../Assets/Images/profile3.png";
import profile4 from "../Assets/Images/profile4.png";
import profile5 from "../Assets/Images/profile5.png";

export const dashboardConstants = [
  {
    name: "Desirae Aminoff",
    profile: image1,
    progress: 15,
  },
  {
    name: "Jaxson Schleifer",
    profile: image2,
    progress: 54,
  },
  {
    name: "Marilyn Culhane",
    profile: image3,
    progress: 24,
  },
  {
    name: "Cristian Ponce",
    profile: image4,
    progress: 39,
  },
  {
    name: "Kianna Rosser",
    profile: image1,
    progress: 62,
  },
];

export const chatConstants = [
  {
    name: "Jesica Aminoff",
    profile: profile1,
  },
  {
    name: "Jaxson Schleifer",
    profile: profile2,
  },
  {
    name: "Marilyn Culhane",
    profile: profile3,
  },
  {
    name: "Cristian Ponce",
    profile: profile4,
  },
  {
    name: "Kianna Rosser",
    profile: profile5,
  },
];

export const teacherDashboardGraph = {
  series: [
    {
      name: "Active",
      data: [0, 3, 1.5, 4, 2.5, 6, 2, 1],
      color: "#6AD7E0",
    },
  ],

  options: {
    chart: {
      height: 210,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "text",
      categories: ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val; // "h" for hours
        },
      },
      title: {
        text: "Hours",
      },
      tickAmount: 6, // Set the number of ticks to the total number of hours in a day
      forceNiceScale: false,
    },
    legend: {
      show: false,
    },
  },
};
