import { Avatar, Divider, Typography } from "@mui/material";
import React from "react";
import classes from "./chat.module.css";

const ContactCard = ({
  item,
  index,
  length,
  selectedItem,
  setSelectedItem,
}) => {
  return (
    <div
      key={index}
      className={classes.ContactCard}
      onClick={() => setSelectedItem(item)}
    >
      <div
        className={
          item === selectedItem
            ? `${classes.ContactcontenrCard} ${classes.ContactcontenrCardActive}`
            : `${classes.ContactcontenrCard}`
        }
      >
        <Avatar
          alt="Profile"
          src={item?.profile}
          className={classes.cardProfile}
        />
        <div>
          <Typography className={classes.CardName}>{item?.name}</Typography>
          <Typography className={`mt-1 ${classes.CardTime}`}>
            Today 11.10 AM
          </Typography>
        </div>
      </div>
      {index !== length && <Divider className={classes.Divider} />}
    </div>
  );
};

export default ContactCard;
