import {
  CLEAR_NOTIFICATIONS_FAIL,
  CLEAR_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,
  MARK_ALL_NOTIFICATION_READ_FAIL,
  MARK_ALL_NOTIFICATION_READ_SUCCESS,
  MARK_NOTIFICATION_READ_FAIL,
  MARK_NOTIFICATION_READ_SUCCESS,
} from "../contants/notificationConstants";

const initialState = {
  error: null,
  success: null,
  notificationsList: [],
  //   oneEvent: null,
  totalCount: 0,
  unseenCount: 0,
};

export const notificationReducers = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_NOTIFICATIONS_FAIL:
      return { ...state, error: action.payload };
    case CLEAR_NOTIFICATIONS_SUCCESS:
      return { ...state, error: null, notificationsList: action.payload };
    case DELETE_NOTIFICATION_FAIL:
      return { ...state, error: action.payload };
    case DELETE_NOTIFICATION_SUCCESS:
      return { ...state, error: null, notificationsList: action.payload };
    case GET_NOTIFICATION_FAIL:
      return { ...state, error: action.payload };
    case GET_NOTIFICATION_SUCCESS:
      return { ...state, error: null, notificationsList: action.payload };
    case MARK_ALL_NOTIFICATION_READ_FAIL:
      return { ...state, error: action.payload };
    case MARK_ALL_NOTIFICATION_READ_SUCCESS:
      return { ...state, error: null, notificationsList: action.payload };
    case MARK_NOTIFICATION_READ_FAIL:
      return { ...state, error: action.payload };
    case MARK_NOTIFICATION_READ_SUCCESS:
      return { ...state, error: null, notificationsList: action.payload };

    default:
      return state;
  }
};
