import React, { useState, useEffect } from "react";
import StatisticsCard from "../../../Components/statisticsCard";
import { Card, Typography, Box, Divider } from "@mui/material";
import PropTypes from "prop-types";
import classes from "./student.module.css";
import StudentsTable from "./StudentsTable";
import { setHeaderName } from "../../../Redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllClasses } from "../../../Redux/actions/classActions";
import AlertBox from "../../../Components/AlertBox";
import {
  getStudentPiechart,
  getTeacherStudentPiechart,
} from "../../../Redux/actions/studentAction";
import _debounce from "lodash/debounce";
import TeacherStatisticCard from "../../../Components/teacherStatisticCard";
import StudentTeacherTable from "./studentTeacherTable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StudentsIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, piechartData, teacherStudentPieChart } = useSelector(
    (state) => state.studentDatas
  );
  const { classesList } = useSelector((state) => state.classDatas);
  const { userInfo, userRole } = useSelector((state) => state.userLogin);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo?.schoolInfo?._id) {
        // dispatch(pageLoader(true));
        if (classesList && classesList.length === 0) {
          dispatch(getAllClasses(setErrorAlert, "", userInfo?.schoolInfo?._id));
        }
        dispatch(
          getStudentPiechart(
            setErrorAlert,
            userInfo?.schoolInfo?._id,
            piechartData
          )
        );
        dispatch(
          getTeacherStudentPiechart(
            setErrorAlert,
            userInfo?.schoolInfo?._id,
            teacherStudentPieChart
          )
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [userInfo]);

  return (
    <div>
      {userRole === "TEACHER" ? (
        <div>
          <TeacherStatisticCard data={teacherStudentPieChart} />
          <Divider className="mt-4 mb-3" />
          <StudentTeacherTable />
        </div>
      ) : (
        <div>
          <StatisticsCard data={piechartData} />
          <Card className={`mt-3`}>
            <div style={{ padding: "24px 24px 0" }}>
              <Typography className={classes.StudentPageMainHeading}>
                All student Data
              </Typography>
            </div>
            <StudentsTable value={value} />
            {errorAlert && error && (
              <AlertBox type="error" message={error} stateName={CloseAlert} />
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

export default StudentsIndex;
