module.exports = {
  GET_NOTIFICATION_SUCCESS: "ADD_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAIL: "GET_NOTIFICATION_FAIL",
  MARK_ALL_NOTIFICATION_READ_SUCCESS: "MARK_ALL_NOTIFICATION_READ_SUCCESS",
  MARK_ALL_NOTIFICATION_READ_FAIL: "MARK_ALL_NOTIFICATION_READ_FAIL",
  MARK_NOTIFICATION_READ_SUCCESS: "MARK_NOTIFICATION_READ_SUCCESS",
  MARK_NOTIFICATION_READ_FAIL: "MARK_NOTIFICATION_READ_FAIL",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAIL: "DELETE_NOTIFICATION_FAIL",
  CLEAR_NOTIFICATIONS_SUCCESS: "CLEAR_NOTIFICATIONS_SUCCESS",
  CLEAR_NOTIFICATIONS_FAIL: "CLEAR_NOTIFICATIONS_FAIL",
};
