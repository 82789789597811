import React, { useEffect, useState } from "react";
import classes from "./dashboard.module.css";
import StatisticsCard from "../../../Components/statisticsCard";
import { useDispatch, useSelector } from "react-redux";
import { getOneSchoolsPieChart } from "../../../Redux/actions/userActions";
import AlertBox from "../../../Components/AlertBox";
import _debounce from "lodash/debounce";
import { Col, Row } from "react-bootstrap";
import {
  Avatar,
  Button,
  Card,
  Chip,
  Typography,
  MenuItem,
} from "@mui/material";
import { dashboardConstants } from "../../../Utilities/CommonConstants";
import ProgressBar from "react-bootstrap/ProgressBar";
import { VictoryPie } from "victory";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  DiamondIcon,
  EditPenIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import elephant from "../../../Assets/Images/elephant.png";
import deerbackground from "../../../Assets/Images/deerbackground.png";
import deer from "../../../Assets/Images/deer.png";
import giraffebackground from "../../../Assets/Images/giraffeBackground.png";
import giraffe from "../../../Assets/Images/giraffe.png";
import { StyledMenu } from "../../../Components/CommonMenu";

const SchoolAdminDashboard = () => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { userInfo, userRole } = useSelector((state) => state.userLogin);
  const { error, schoolPieChart } = useSelector((state) => state.schoolDatas);
  const token = JSON.parse(localStorage.getItem("userToken"));

  const data = [
    { x: "Expired", y: 140 },
    { x: "Expiring", y: 60 },
  ];

  useEffect(() => {
    console.log(schoolPieChart);
  }, [schoolPieChart]);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo && userInfo.schoolInfo && userInfo.schoolInfo._id && token) {
        dispatch(
          getOneSchoolsPieChart(
            userInfo?.schoolInfo?._id,
            setErrorAlert,
            schoolPieChart
          )
        );
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [userInfo]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <StatisticsCard data={schoolPieChart} />
      <Row className="mt-3" style={{ rowGap: "15px" }}>
        <Col md={6}>
          <Card style={{ padding: "15px" }}>
            <Typography
              className={`${classes.cardMainHeading} ${classes.chartCardMainHeading}`}
            >
              Students
            </Typography>
            <div className={classes.doughnutChartContainer}>
              <svg viewBox="0 0 500 500" width="275px" height="275px">
                <VictoryPie
                  standalone={false}
                  width={500}
                  height={500}
                  data={data}
                  startAngle={90}
                  endAngle={-270}
                  innerRadius={125}
                  colorScale={["#6AD7E0", "#BA9BFF"]}
                  labels={() => null} // Hide labels
                />
              </svg>
              <div
                style={{ margin: "auto" }}
                className={classes.legendContainer}
              >
                <div>
                  <div
                    className="d-flex align-items-center"
                    style={{ columnGap: "5px" }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: `#6AD7E0`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <Typography className={classes.chartLabel}>
                      Male Students
                    </Typography>
                  </div>
                  <Typography
                    className={`mt-1 ${classes.chartCountNumber}`}
                    style={{ marginLeft: "17px" }}
                  >
                    140
                  </Typography>
                </div>
                <div>
                  <div
                    className="d-flex align-items-center"
                    style={{ columnGap: "5px" }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: `#BA9BFF`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <Typography className={classes.chartLabel}>
                      Female Students
                    </Typography>
                  </div>
                  <Typography
                    className={`mt-1 ${classes.chartCountNumber}`}
                    style={{ marginLeft: "17px" }}
                  >
                    60
                  </Typography>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ padding: "15px" }}>
            <div className={`d-flex justify-content-between`}>
              <Typography className={classes.cardMainHeading}>
                Students Progress
              </Typography>
              <Typography className={classes.viewAllBtn}>View All</Typography>
            </div>
            <div className={`mt-3 ${classes.progressBarContainer}`}>
              {dashboardConstants?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="d-flex align-items-center"
                      style={{ columnGap: "10px" }}
                    >
                      <Avatar src={item?.profile} height={32} width={32} />
                      <Typography className={classes.memberNameText}>
                        {item.name}
                      </Typography>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ columnGap: "10px" }}
                    >
                      <ProgressBar
                        now={item.progress}
                        className={classes.progressBarStyles}
                      />
                      <Typography className={classes.someOtherText}>
                        {item.progress}%
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        </Col>
      </Row>
      <Typography className={`mt-3 ${classes.cardMainHeading}`}>
        Students Progress
      </Typography>
      <Row className="mt-3" style={{ rowGap: "15px" }}>
        <Col md={4}>
          <Card className={classes.gameCard}>
            <Typography className={classes.GameHeading}>
              Picture puzzles
            </Typography>
            <Typography className={`mt-3 ${classes.GameDiamond}`}>
              Total Diamond: <span style={{ fontWeight: 700 }}>150</span>{" "}
              <DiamondIcon />{" "}
            </Typography>
            <Typography className={`mt-3 ${classes.GameDiamond}`}>
              No. of Level:: <span style={{ fontWeight: 700 }}>4</span>
            </Typography>
            <Chip
              icon={<ActiveItemIcon />}
              style={{
                background: "var(--active-chip-background)",
                width: "75px",
                height: "24px",
              }}
              label={"Active"}
              className={`mt-3 ${classes.StatusChip}`}
            />
            <img
              src={elephant}
              alt={"Elphant"}
              style={{ width: "175px", height: "166px" }}
              className={classes.GameMainImage}
            />
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              className={classes.MenuButton}
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              variant="text"
              style={{
                minWidth: 0,
              }}
            >
              <ThreeDotMenuIcon />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => console.log("Edit Details")}
                disableRipple
                style={{ height: "40px" }}
              >
                <EditPenIcon />
                <span className={classes.MenuItemText}>Edit Details</span>
              </MenuItem>
              <MenuItem
                onClick={() => console.log("Delete Details")}
                disableRipple
                style={{ height: "40px" }}
              >
                <DeleteBinIcon />
                <span className={classes.MenuItemText}>Delete</span>
              </MenuItem>
              <MenuItem
                onClick={() => console.log("Active/Inactive")}
                disableRipple
                style={{ height: "40px" }}
              >
                <SwitchIcon />
                <span className={classes.MenuItemText}>Active/Inactive</span>
              </MenuItem>
            </StyledMenu>
          </Card>
        </Col>
        <Col md={4}>
          <Card className={classes.gameCard}>
            <Typography className={classes.GameHeading}>
              Join the dots
            </Typography>
            <Typography className={`mt-3 ${classes.GameDiamond}`}>
              Total Diamond: <span style={{ fontWeight: 700 }}>150</span>{" "}
              <DiamondIcon />{" "}
            </Typography>
            <Typography className={`mt-3 ${classes.GameDiamond}`}>
              No. of Level:: <span style={{ fontWeight: 700 }}>5</span>
            </Typography>
            <Chip
              icon={<ActiveItemIcon />}
              style={{
                background: "var(--active-chip-background)",
                width: "75px",
                height: "24px",
              }}
              label={"Active"}
              className={`mt-3 ${classes.StatusChip}`}
            />
            <img
              src={deerbackground}
              alt={"Deer"}
              style={{ width: "175px", height: "142px" }}
              className={classes.GameMainImage}
            />
            <img
              src={deer}
              alt={"Deer"}
              style={{ width: "121px", height: "170px" }}
              className={classes.GameMainImage}
            />
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              className={classes.MenuButton}
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              variant="text"
              style={{
                minWidth: 0,
              }}
            >
              <ThreeDotMenuIcon />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => console.log("Edit Details")}
                disableRipple
                style={{ height: "40px" }}
              >
                <EditPenIcon />
                <span className={classes.MenuItemText}>Edit Details</span>
              </MenuItem>
              <MenuItem
                onClick={() => console.log("Delete Details")}
                disableRipple
                style={{ height: "40px" }}
              >
                <DeleteBinIcon />
                <span className={classes.MenuItemText}>Delete</span>
              </MenuItem>
              <MenuItem
                onClick={() => console.log("Active/Inactive")}
                disableRipple
                style={{ height: "40px" }}
              >
                <SwitchIcon />
                <span className={classes.MenuItemText}>Active/Inactive</span>
              </MenuItem>
            </StyledMenu>
          </Card>
        </Col>
        <Col md={4}>
          <Card className={classes.gameCard}>
            <Typography className={classes.GameHeading}>
              Picture puzzles
            </Typography>
            <Typography className={`mt-3 ${classes.GameDiamond}`}>
              Total Diamond: <span style={{ fontWeight: 700 }}>150</span>{" "}
              <DiamondIcon />{" "}
            </Typography>
            <Typography className={`mt-3 ${classes.GameDiamond}`}>
              No. of Level:: <span style={{ fontWeight: 700 }}>4</span>
            </Typography>
            <Chip
              icon={<ActiveItemIcon />}
              style={{
                background: "var(--active-chip-background)",
                width: "75px",
                height: "24px",
              }}
              label={"Active"}
              className={`mt-3 ${classes.StatusChip}`}
            />
            <img
              src={giraffebackground}
              alt={"Giraffe"}
              style={{ width: "175px", height: "142px" }}
              className={classes.GameMainImage}
            />
            <img
              src={giraffe}
              alt={"Giraffe"}
              style={{ width: "134px", height: "164px" }}
              className={classes.GameMainImage}
            />
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              className={classes.MenuButton}
              onClick={handleClick}
              variant="text"
              style={{
                minWidth: 0,
              }}
            >
              <ThreeDotMenuIcon />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => console.log("Edit Details")}
                disableRipple
                style={{ height: "40px" }}
              >
                <EditPenIcon />
                <span className={classes.MenuItemText}>Edit Details</span>
              </MenuItem>
              <MenuItem
                onClick={() => console.log("Delete Details")}
                disableRipple
                style={{ height: "40px" }}
              >
                <DeleteBinIcon />
                <span className={classes.MenuItemText}>Delete</span>
              </MenuItem>
              <MenuItem
                onClick={() => console.log("Active/Inactive")}
                disableRipple
                style={{ height: "40px" }}
              >
                <SwitchIcon />
                <span className={classes.MenuItemText}>Active/Inactive</span>
              </MenuItem>
            </StyledMenu>
          </Card>
        </Col>
      </Row>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default SchoolAdminDashboard;
