import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducers } from "./reducers/userReducers";
import { schoolReducers } from "./reducers/schoolReducers";
import { classReducers } from "./reducers/classRedusers";
import { studentReducers } from "./reducers/studentReducers";
import { teacherReducer } from "./reducers/teacherReducers";
import { courseReducer } from "./reducers/courseReducers";
import { roleManagementReducers } from "./reducers/roleManagementReducers";
import { subscriptionReducer } from "./reducers/subscriptionReducers";
import { eventReducers } from "./reducers/eventsReducers";
import { notificationReducers } from "./reducers/notificationReducers";

const reducer = combineReducers({
  userLogin: userReducers,
  schoolDatas: schoolReducers,
  classDatas: classReducers,
  studentDatas: studentReducers,
  teacherDatas: teacherReducer,
  courseDatas: courseReducer,
  roleDatas: roleManagementReducers,
  subscriptionData: subscriptionReducer,
  eventsData: eventReducers,
  notificationsData: notificationReducers,
});

const userInfoFromStorage = localStorage.getItem("userToken")
  ? JSON.parse(localStorage.getItem("userToken"))
  : null;

const initialState = {
  userLogin: {
    loggedUser: userInfoFromStorage?.data[0],
    userRole: userInfoFromStorage?.data[0].role,
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
