import { authApi, getAuthorizationHeader } from "../../Api/api";
import { ADD_ACCESS_LEVEL_FAIL, ADD_ACCESS_LEVEL_SUCCESS, ADD_ROLES_FAIL, ADD_ROLES_SUCCESS, GET_ALL_ACCESS_LEVEL_FAIL, GET_ALL_ACCESS_LEVEL_SUCCESS, GET_ALL_ROLES_FAIL, GET_ALL_ROLES_SUCCESS, GET_ONE_ROLE_FAIL, GET_ONE_ROLE_SUCCESS, SET_PIECHART_FAIL, SET_PIECHART_SUCCESS } from "../contants/roleManagementConstants";
import { pageLoader } from "./userActions";

export const addNewAccessLevel =
    (userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.post(`/access-level/create-access-level`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_ACCESS_LEVEL_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
                dispatch(getAllAccessLevel(setErrorAlert));
            }
        } catch (error) {
            dispatch({
                type: ADD_ACCESS_LEVEL_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const getAllAccessLevel = (setErrorAlert) => async (dispatch) => {
    try {
        const { data } = await authApi.get("/access-level/access-level-all", {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_ALL_ACCESS_LEVEL_SUCCESS,
                payload: data.data,
            });
            console.log("success", data);
        }
    } catch (error) {
        console.log("error", error);
        dispatch({
            type: GET_ALL_ACCESS_LEVEL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const addNewRole =
    (userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.post(`/roles-admin/create-role`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_ROLES_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
                dispatch(getAllAccessLevel(setErrorAlert));
            }
        } catch (error) {
            dispatch({
                type: ADD_ROLES_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const getAllRoles = (setErrorAlert, topic, school, search, sort, page) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/roles-admin/role-all/${topic}?school=${school || ""}&search=${search || ""}&page=${page || 1}&limit=10&sort=${sort || ''}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_ALL_ROLES_SUCCESS,
                payload: data.data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ALL_ROLES_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const getOneRoles = (id, setErrorAlert) => async (dispatch) => {
    try {
        console.log("id", id);
        const { data } = await authApi.get(`/roles-admin/role-one/${id}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_ONE_ROLE_SUCCESS,
                payload: data.data[0],
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ONE_ROLE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const updateRole = (id, userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
        const { data } = await authApi.patch(`/roles-admin/role-update/${id}`, userEntry, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: ADD_ROLES_SUCCESS,
                payload: data.message,
            });
            setSuccessAlert(true);
        }
    } catch (error) {
        dispatch({
            type: ADD_ROLES_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const changeRoleStatus = (id, setErrorAlert, setSuccessAlert, topic, school) => async (dispatch) => {
    try {
        const { data } = await authApi.patch(`/roles-admin/role-change-status/${id}`, {}, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: ADD_ROLES_SUCCESS,
                payload: data.message,
            });
            setSuccessAlert(true);
            dispatch(getAllRoles(setErrorAlert, topic, school));
        }
    } catch (error) {
        dispatch({
            type: ADD_ROLES_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const getRolesPiechart = (setErrorAlert, array) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/roles-admin/roles-piechart`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        console.log(data);
        console.log(array);
        let newData = [];
        Object.keys(data.data)?.map((item) => {
            const obj = array.find((element) => {
                if (element.key === item) {
                    return element
                } else {
                    return null
                }
            })
            newData.push({
                ...obj,
                number: data.data[item]
            })
            return null
        })

        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: SET_PIECHART_SUCCESS,
                payload: newData,
            });
        }
    } catch (error) {
        dispatch({
            type: SET_PIECHART_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}