import { setSortName } from "../Redux/actions/userActions";
import seedrandom from "seedrandom";

export const Numeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (x > 47 && x < 58) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};
export const Alphabetic = (event) => {
  var x = event.charCode || event.keyCode;
  if (
    (x > 96 && x < 123) ||
    (x > 64 && x < 91) ||
    x === 32 ||
    x === 46 || // dot "."
    x === 44 || // comma ","
    x === 95 || // underscore "_"
    x === 45
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};
export const AlphaNumeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (
    (x > 96 && x < 123) ||
    (x > 64 && x < 91) ||
    x === 32 ||
    (x > 47 && x < 58) ||
    x === 46 || // dot "."
    x === 44 || // comma ","
    x === 95 || // underscore "_"
    x === 45
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const generatePassword = (name, setPassword) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$&*?";

  let password = "";

  // Ensure at least one uppercase letter, one lowercase letter, one symbol, and one number
  password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"); // Uppercase letter
  password += getRandomChar("abcdefghijklmnopqrstuvwxyz"); // Lowercase letter
  password += getRandomChar("!@$&*?"); // Symbol
  password += getRandomChar("0123456789"); // Number

  // Add the user's name to the password
  password += name.slice(0, 3); // Add the first three characters of the name (adjust as needed)

  // Fill the rest of the password with random characters
  for (let i = password.length; i < 8; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  // Shuffle the password to randomize the order
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  console.log(password);
  setPassword(password);
};

export const GenerateBulkPassword = (name) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$&*?";

  let password = "";

  // Ensure at least one uppercase letter, one lowercase letter, one symbol, and one number
  password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"); // Uppercase letter
  password += getRandomChar("abcdefghijklmnopqrstuvwxyz"); // Lowercase letter
  password += getRandomChar("!@$&*?"); // Symbol
  password += getRandomChar("0123456789"); // Number

  // Add the user's name to the password
  password += name.slice(0, 3); // Add the first three characters of the name (adjust as needed)

  // Fill the rest of the password with random characters
  for (let i = password.length; i < 8; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  // Shuffle the password to randomize the order
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  console.log(password);
  return password;
};

export function generateRandomLightColor() {
  // Generate random values for red, green, and blue components
  const red = Math.floor(Math.random() * (255 - 150 + 1)) + 150; // Adjusted to ensure a lighter color
  const green = Math.floor(Math.random() * (255 - 150 + 1)) + 150;
  const blue = Math.floor(Math.random() * (255 - 150 + 1)) + 150;

  // Format the RGB values as a CSS-style color code
  const colorCode = `rgb(${red}, ${green}, ${blue})`;

  return colorCode;
}

function getRandomChar(characters) {
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

export const calculatePasswordStrength = (password) => {
  let strength = 0;
  if (password.length >= 10) {
    strength += 10;
  }
  if (password.length >= 9) {
    strength += 10;
  }
  if (password.length >= 8) {
    strength += 10;
  }
  if (password.length >= 7) {
    strength += 10;
  }
  if (password.length >= 6) {
    strength += 10;
  }
  if (password.length >= 5) {
    strength += 10;
  }
  if (/[A-Z]/.test(password)) {
    strength += 10;
  }
  if (/[a-z]/.test(password)) {
    strength += 10;
  }
  if (/[0-9]/.test(password)) {
    strength += 10;
  }
  if (/[!@#$%^&*]/.test(password)) {
    strength += 10;
  }
  return strength;
};

// Function to determine the strength label
export const getStrengthLabel = (strength) => {
  if (strength < 20) {
    return "Very Poor";
  } else if (strength < 40) {
    return "Poor";
  } else if (strength < 60) {
    return "Average";
  } else if (strength < 80) {
    return "Better";
  } else if (strength < 100) {
    return "Strong";
  } else {
    return "Too Strong";
  }
};

// Function to determine the color based on strength
export const getStrengthColor = (strength) => {
  if (strength < 10) {
    return "red";
  } else if (strength < 20) {
    return "darkred";
  } else if (strength < 30) {
    return "orangered";
  } else if (strength < 40) {
    return "darkorange";
  } else if (strength < 50) {
    return "orange";
  } else if (strength < 60) {
    return "gold";
  } else if (strength < 70) {
    return "darkkhaki";
  } else if (strength < 80) {
    return "lightgreen";
  } else {
    return "green";
  }
};

export const handleSortClick = (dispatch, sortBy, name) => {
  // console.log(name);
  const namearr = name?.split("-");
  const sortByarr = sortBy?.split("-");
  // console.log(sortByarr);
  if (sortByarr && sortByarr.length > 0 && namearr[0] === sortByarr[0]) {
    if (sortByarr[1] === "asc") {
      dispatch(setSortName(`${namearr[0]}-desc`));
    } else {
      console.log("condition 3");
      dispatch(setSortName(`${namearr[0]}-asc`));
    }
  } else {
    dispatch(setSortName(`${name}-desc`));
  }
};

export const stringAvatar = (name) => {
  if (name) {
    if (
      name.trimStart().trimEnd().includes(" ") &&
      name?.split(" ")[1].length > 1
    ) {
      return {
        children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
      };
    } else {
      return {
        children: `${name[0]}${name[1] ? name[1] : ""}`,
      };
    }
  } else {
    return {
      children: "",
    };
  }
};

export const generateColor = (text) => {
  const randomst = generateRandomString(text);
  const cancat = text.replace(" ", "") + randomst;
  const hashCode = (s) => {
    let h = 0;
    for (let i = 0; i < s.length; i++) {
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    }
    return h;
  };

  const hash = hashCode(cancat);

  const redComponent = (hash & 0xff0000) >> 16;
  const greenComponent = (hash & 0x00ff00) >> 16;
  const blueComponent = hash & 0x0000ff;

  const color1 = `#${redComponent.toString(16).padStart(2, "0")}${greenComponent
    .toString(16)
    .padStart(2, "0")}${blueComponent.toString(16).padStart(2, "0")}`;

  // If you want to generate a second color with reduced opacity (e.g., 40%), you can do this:
  const color2 = `${color1}66`; // 66 represents the alpha value (opacity) in hexadecimal

  return color1;
};

export const generateRandomString = (inputText) => {
  const alphabet =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const rng = seedrandom(inputText);
  // Create a seeded random number generator

  let randomString = "";

  for (let i = 0; i < inputText.length + 20; i++) {
    const randomIndex = Math.floor(rng() * alphabet.length);
    randomString += alphabet[randomIndex];
  }

  return randomString;
};
