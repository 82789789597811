import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./courses.module.css";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import StatisticsCard from "../../../Components/statisticsCard";
import { Col, Row } from "react-bootstrap";
import {
  TextField,
  Typography,
  MenuItem,
  Button,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { RoundAddIcon } from "../../../Assets/Icons/icons";
import CourseTablePage from "./CourseTablePage";
import { useNavigate } from "react-router-dom";
import { getCoursePiechart } from "../../../Redux/actions/courseAction";
import AlertBox from "../../../Components/AlertBox";
import { getAllClasses } from "../../../Redux/actions/classActions";
import TeacherStatisticCard from "../../../Components/teacherStatisticCard";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CousesGamesIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gradeLevel, setGradeLevel] = useState("select");
  const [selectedClass, setSelectedClass] = useState("select");
  const [errorAlert, setErrorAlert] = useState(false);
  const [value, setValue] = useState(0);
  const { error, piechartData, teacherCoursesPieChart } = useSelector(
    (state) => state.courseDatas
  );
  const { classesList } = useSelector((state) => state.classDatas);
  const { userRole, userInfo } = useSelector((state) => state.userLogin);
  const [classList, setClassList] = useState([]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        index === value
          ? `${classes.selectedTab} ${classes.TabsLableHeader}`
          : classes.TabsLableHeader,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getAllClasses(setErrorAlert, "", userInfo?.schoolInfo?._id));
    if (userInfo?.schoolInfo?._id) {
      dispatch(
        getCoursePiechart(
          setErrorAlert,
          userInfo?.schoolInfo?._id,
          piechartData,
          teacherCoursesPieChart
        )
      );
    }
  }, [userInfo]);

  const handleAddCourse = () => {
    dispatch(setHeaderName("Add Game"));
    navigate("/add-game");
  };

  return (
    <div>
      {userRole === "TEACHER" && teacherCoursesPieChart ? (
        <TeacherStatisticCard data={teacherCoursesPieChart} />
      ) : (
        <StatisticsCard data={piechartData} />
      )}

      <Row className={`mt-4`}>
        <Col md={3}>
          <Typography className={classes.formLable}>
            Select Grade Level
          </Typography>
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              marginBottom: "15px",
            }}
            select
            id="gradeInput"
            fullWidth
            placeholder="Select Grade Level"
            className={
              gradeLevel === "select"
                ? `${classes.TextInput} ${classes.defaultMenuItem}`
                : classes.TextInput
            }
            value={gradeLevel}
            onChange={(e) => {
              setGradeLevel(e.target.value);
            }}
            size="small"
            validators={["required"]} // Add this line
            errorMessages={["Grade is required"]}
          >
            <MenuItem
              key={"select"}
              value={"select"}
              className={classes.defaultMenuItem}
            >
              Select Grade Level
            </MenuItem>
            {classesList &&
              classesList.length > 0 &&
              classesList?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item?._id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item?.grade.charAt(0).toUpperCase() +
                      item?.grade.slice(1).toLowerCase().replaceAll("_", " ")}
                  </MenuItem>
                );
              })}
          </TextField>
        </Col>
        <Col md={3}>
          <div className={classes.FilterBtnContainer}>
            <Button variant="outlined" className={classes.FilterFindBtn}>
              Find
            </Button>
          </div>
        </Col>
        <Col md={3}>
          {/* <Typography className={classes.formLable}>Select Class</Typography>
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              marginBottom: "15px",
            }}
            select
            id="classInput"
            fullWidth
            placeholder="Select Class"
            className={
              selectedClass === "select"
                ? `${classes.TextInput} ${classes.defaultMenuItem}`
                : classes.TextInput
            }
            value={selectedClass}
            onChange={(e) => {
              setSelectedClass(e.target.value);
            }}
            size="small"
            validators={["required"]} // Add this line
            errorMessages={["Class is required"]}
          >
            <MenuItem key={"select"} value={"select"}>
              Select Class
            </MenuItem>
            {["class A", "class B", "class C"].map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField> */}
        </Col>
        <Col md={3}>
          { ( userRole === "SCHOOL" || userInfo?.permissions?.includes("course&games:add")) &&
            <div className={`${classes.FilterSubContainer}`}>
            <div
              className={`${classes.DividerClasses} ${classes.vertical}`}
            ></div>
            <Button
              variant="contained"
              className={`d-flex align-items-center ${classes.searchButton}`}
              onClick={() => handleAddCourse()}
            >
              <span style={{ marginRight: "10px" }}>
                <RoundAddIcon />
              </span>
              <span className={classes.mainBtnText}>Add Games</span>
            </Button>
          </div>}
        </Col>
      </Row>
      <Box sx={{ width: "100%", marginTop: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.TabsHeaderDiv}
            TabIndicatorProps={{
              style: {
                backgroundColor: "var(--forgot-password-text-color)",
                height: "4px",
              },
            }}
          >
            <Tab label="Computer Programming" {...a11yProps(0)} />
            <Tab label="Algorithms" {...a11yProps(1)} />
            <Tab label="Digital Literacy" {...a11yProps(2)} />
            <Tab label="Safety" {...a11yProps(3)} />
            <Tab label="Computer Programming-2" {...a11yProps(4)} />
            <Tab label="Information Technology" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
          className={`${classes.tabPanel} mb-5`}
        >
          <CourseTablePage
            topic={"computer_programming"}
            gradeLevel={gradeLevel}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          className={`${classes.tabPanel} mb-5`}
        >
          <CourseTablePage topic={"algorithms"} gradeLevel={gradeLevel} />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
          className={`${classes.tabPanel} mb-5`}
        >
          <CourseTablePage topic={"digital_literacy"} gradeLevel={gradeLevel} />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={3}
          className={`${classes.tabPanel} mb-5`}
        >
          <CourseTablePage topic={"safety"} gradeLevel={gradeLevel} />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={4}
          className={`${classes.tabPanel} mb-5`}
        >
          <CourseTablePage
            topic={"computer_programming-2"}
            gradeLevel={gradeLevel}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={5}
          className={`${classes.tabPanel} mb-5`}
        >
          <CourseTablePage
            topic={"information_technology"}
            gradeLevel={gradeLevel}
          />
        </CustomTabPanel>
      </Box>
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : null}
    </div>
  );
};

export default CousesGamesIndex;
