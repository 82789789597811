module.exports = {
    ADD_STUDENT_SUCCESS: "ADD_STUDENT_SUCCESS",
    ADD_STUDENT_FAIL: "ADD_STUDENT_FAIL",
    GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
    GET_STUDENTS_FAIL: "GET_STUDENTS_FAIL",
    GET_ONE_STUDENT_SUCCESS: "GET_ONE_STUDENT_SUCCESS",
    GET_ONE_STUDENT_FAIL: "GET_ONE_STUDENT_FAIL",
    ADD_STUDENT_PIECHART_SUCCESS: "ADD_STUDENT_PIECHART_SUCCESS",
    ADD_STUDENT_PIECHART_FAIL: "ADD_STUDENT_PIECHART_FAIL",
    DOWNLOAD_EMAIL_TEMPLATE_SUCCESS: "DOWNLOAD_EMAIL_TEMPLATE_SUCCESS",
    DOWNLOAD_EMAIL_TEMPLATE_FAIL: "DOWNLOAD_EMAIL_TEMPLATE_FAIL",
    SUBSCRIPTION_LIST_SUCCESS: "SUBSCRIPTION_LIST_SUCCESS",
    SUBSCRIPTION_LIST_FAIL: "SUBSCRIPTION_LIST_FAIL",
    SAVE_SELECTED_FILE: "SAVE_SELECTED_FILE",
    ADD_BULK_STUDENT_SUCCESS: "ADD_BULK_STUDENT_SUCCESS",
    TEACHER_STUDENT_PIECHART_SUCCESS: "TEACHER_STUDENT_PIECHART_SUCCESS",
    TEACHER_STUDENT_PIECHART_FAIL: "TEACHER_STUDENT_PIECHART_FAIL",
};