import React, { useState } from "react";
import classes from "./index.module.css";
import { Avatar, Divider, Typography, Card, Button } from "@mui/material";
import profilePic from "../../../Assets/Images/Ellipse 6128.png";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { CheckCircleIcon } from "../../../Assets/Icons/icons";
import SubscriptionTableHistory from "./subscriptionTableHistory";
import { useNavigate } from "react-router";
import SubscriptionCorosel from "./SubscriptionCorosel";
import moment from "moment";
import { GenerateOrder } from "../../../Redux/actions/subscriptionAction";
import { setHeaderName } from "../../../Redux/actions/userActions";

const dummyData = [
  {
    name: "level 2",
    amount: 100,
    subscribed_date: "2023-12-05",
    end_date: "2024-02-04",
    isActive: true,
  },
  {
    name: "level 2",
    amount: 100,
    subscribed_date: "2023-09-13",
    end_date: "2023-12-012",
    isActive: false,
  },
  {
    name: "level 3",
    amount: 150,
    subscribed_date: "2023-06-15",
    end_date: "2023-09-14",
    isActive: false,
  },
  {
    name: "level 1",
    amount: 50,
    subscribed_date: "2023-03-27",
    end_date: "2023-06-26",
    isActive: false,
  },
];

const Profile = () => {
  const dispatch = useDispatch();
  const { loggedUser, userInfo, userRole } = useSelector(
    (state) => state.userLogin
  );
  const { customerSubscriptionList, error } = useSelector(
    (state) => state.subscriptionData
  );
  const [errorAlert, setErrorAlert] = useState(false);
  const navigate = useNavigate();

  const handleRenewSubscription = (item) => {
    console.log(item);
    const userEntry = {
      user_id: item?.user,
      subscription_id: item?.subscription_id,
      subscribed_by: "SCHOOL",
      type: "renew",
    };
    dispatch(GenerateOrder(userEntry, setErrorAlert, navigate));
  };

  const handleClickUpdate = () => {
    dispatch(setHeaderName("Change Password"));
    navigate("/change-password");
  };

  return (
    <div>
      <div className={classes.firstContainer}>
        <div className={classes.subcontainer1}>
          <div style={{ padding: "15px" }}>
            <Avatar
              alt="Profile"
              src={profilePic}
              sx={{ width: 112, height: 112 }}
            />
            <Typography className={classes.mainheading}>
              {loggedUser?.name}
            </Typography>
          </div>
          <Divider />
          <div style={{ padding: "15px" }}>
            <Typography className={classes.SubMainHeading}>
              Basic Details
            </Typography>
            <Row style={{ rowGap: "15px" }} className="mt-3 mb-2">
              <Col md={6}>
                <Typography className={classes.profileLable}>
                  Contact number
                </Typography>
              </Col>
              <Col md={6}>
                <Typography className={classes.profileValueText}>
                  {userInfo?.phone}
                </Typography>
              </Col>
              <Col md={6}>
                <Typography className={classes.profileLable}>
                  Email ID
                </Typography>
              </Col>
              <Col md={6}>
                <Typography className={classes.profileValueText}>
                  {userInfo?.email}
                </Typography>
              </Col>
              <Col md={6}>
                <Typography className={classes.profileLable}>
                  Address
                </Typography>
              </Col>
              <Col md={6}>
                <Typography className={classes.profileValueText}>
                  {userInfo?.schoolInfo?.address}
                </Typography>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          {userRole === "TEACHER" ? (
            <div>
              <Card style={{ padding: "20px" }}>
                <Typography className={classes.SubMainHeading}>
                  Login Info
                </Typography>
                <Row className="mt-3">
                  <Col>
                    <Typography className={`mb-2 ${classes.TableBodyText}`}>
                      UserName
                    </Typography>
                    <Typography className={classes.TableBodyText}>
                      Password
                    </Typography>
                  </Col>
                  <Col>
                    <Typography className={`mb-2 ${classes.profileValueText}`}>
                      {loggedUser?.name}
                    </Typography>
                    <Typography
                      className={classes.updatePassBtnfromProfile}
                      onClick={handleClickUpdate}
                    >
                      update
                    </Typography>
                  </Col>
                </Row>
              </Card>
              <Card className={`mt-3`} style={{ padding: "20px" }}>
                <Typography className={classes.SubMainHeading}>
                  School Details
                </Typography>
                <Row className="mt-3">
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.TableBodyText}`}>
                      School Name
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.profileValueText}`}>
                      {userInfo?.schoolInfo?.name}
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.TableBodyText}`}>
                      Phone Number
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.profileValueText}`}>
                      {userInfo?.schoolInfo?.phone}
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.TableBodyText}`}>
                      School Email
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.profileValueText}`}>
                      {userInfo?.schoolInfo?.email}
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.TableBodyText}`}>
                      Address
                    </Typography>
                  </Col>
                  <Col md={6}>
                    <Typography className={`mb-2 ${classes.profileValueText}`}>
                      {userInfo?.schoolInfo?.address}
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </div>
          ) : (
            <div>
              {userInfo?.customerSubscriptionCount > 1 ? (
                <SubscriptionCorosel />
              ) : (
                <Card className={classes.subcontainer2}>
                  <Typography className={classes.SubMainHeading}>
                    Subscription
                  </Typography>
                  <Typography className={`mt-3 ${classes.SubMainHeading2}`}>
                    {userInfo?.subscriptionInfo?.subscription_name}
                  </Typography>
                  <div className={`mt-3 ${classes.descriptioncontainer}`}>
                    {Array.isArray(userInfo?.subscriptionInfo?.features) &&
                    userInfo?.subscriptionInfo?.features?.map((datas)=>(
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <CheckCircleIcon />
                      </span>
                      {datas}
                    </Typography>
                    ))
                    }
                    
                    {/* <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <CheckCircleIcon />
                      </span>
                      Lorem ipsum dolor sit amet
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <CheckCircleIcon />
                      </span>
                      Lorem ipsum dolor sit amet, conse ctetur adipiscing 33
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <CheckCircleIcon />
                      </span>
                      Elit sed do eiusmod tempor incididunt ut
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <CheckCircleIcon />
                      </span>
                      Lorem ipsum dolor sit amet
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <CheckCircleIcon />
                      </span>
                      Elit sed do eiusmod tempor incididunt ut
                    </Typography> */}
                  </div>
                  <div className={classes.subscriptionFooter}>
                    {customerSubscriptionList &&
                      customerSubscriptionList.length > 0 && (
                        <Typography className={classes.MenuItemText}>
                          End Date:{" "}
                          <span className={classes.ExtrafontWeight}>
                            {moment(
                              customerSubscriptionList[0]?.end_date
                            ).format("D MMMM YYYY")}
                          </span>
                        </Typography>
                      )}
                    {userInfo?.customerSubscriptionCount > 0 ? (
                      <div className={classes.subscriptionBtnSection}>
                        <Button
                          variant="outlined"
                          style={{ color: "var(--forgot-password-text-color)" }}
                          onClick={() =>
                            handleRenewSubscription(customerSubscriptionList[0])
                          }
                        >
                          Renew Plan
                          {moment(
                            customerSubscriptionList[0]?.start_date
                          ).isAfter(moment())}
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            background: "var(--forgot-password-text-color)",
                          }}
                          onClick={() => navigate("/select-subscription")}
                        >
                          View Plan
                        </Button>
                      </div>
                    ) : (
                      <div className={classes.subscriptionBtnSection}>
                        <Button
                          variant="contained"
                          style={{
                            background: "var(--forgot-password-text-color)",
                          }}
                          onClick={() => navigate("/payment-details")}
                        >
                          Active Plan
                        </Button>
                      </div>
                    )}
                  </div>
                </Card>
              )}
            </div>
          )}
        </div>
      </div>
      {userRole === "TEACHER" ? (
        <div>
          <Card className="mt-3" style={{ padding: "20px" }}>
            <Typography className={classes.SubMainHeading}>
              Class Information
            </Typography>
            <Row className="mt-3">
              <Col md={4}>
                <Typography
                  className={`mb-1 ${classes.TableBodyText}`}
                  style={{ textTransform: "capitalize" }}
                >
                  {userInfo?.schoolInfo?.batch?.toLowerCase()}
                </Typography>
                <Typography className={`${classes.profileValueText}`}>
                  {userInfo?.classInfo?.grade?.charAt(0).toUpperCase() +
                    userInfo?.classInfo?.grade
                      ?.slice(1)
                      .toLowerCase()
                      .replaceAll("_", " ")}
                </Typography>
              </Col>
              {/* <Col md={4}>
                <Typography className={`mb-1 ${classes.TableBodyText}`}>
                  Grade
                </Typography>
                <Typography className={`${classes.profileValueText}`}>
                  {userInfo?.schoolInfo?.address}
                </Typography>
              </Col> */}
            </Row>
          </Card>
        </div>
      ) : (
        <div>
          <Typography className={`mt-3 mb-2 ${classes.SubMainHeading}`}>
            Subscription History
          </Typography>
          <SubscriptionTableHistory data={dummyData} />
        </div>
      )}
    </div>
  );
};

export default Profile;
