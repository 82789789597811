import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Popper,
  Fade,
  Paper,
  Divider,
} from "@mui/material";
import {
  CloseIcon,
  DeleteBinIcon,
  EditPenIcon,
  EventModalCalendarIcon,
  EventModalTimePickerIcon,
  EventReminderIcon,
} from "../../../Assets/Icons/icons";
import classes from "./calendar.module.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { pageLoader } from "../../../Redux/actions/userActions";
import { deleteEvent } from "../../../Redux/actions/eventsActions";

const EventViewModal = ({
  modalClose,
  position,
  events,
  width,
  height,
  viewType,
  selectedDate,
  handleEditClick,
  oneEvent,
  setSuccessAlert,
  setErrorAlert,
}) => {
  const open = Boolean(position);
  const dispatch = useDispatch();
  useEffect(() => {
    // Update arrow position based on the selected date
    // if (arrowRef.current && selectedDate) {
    //   const dayOfWeek = moment(selectedDate).day(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    //   const arrowPosition = 100 - (dayOfWeek + 1) * 20; // Adjust this value based on your styling
    //   arrowRef.current.style.right = `${arrowPosition}px`;
    //   arrow1Ref.current.style.right = `${arrowPosition}px`;
    //   arrowRef.current.style.top = `unset !important`;
    //   arrow1Ref.current.style.top = `unset !important`;
    //   arrowRef.current.style.borderColor =
    //     "transparent transparent transparent #fff";
    //   arrow1Ref.current.style.borderColor =
    //     "transparent transparent transparent #c2c2c29d";
    // }
  }, [selectedDate]);

  const renderCustomContent = ({ date }) => {
    if (selectedDate && date.getTime() === selectedDate.getTime()) {
      // Render a button for the selected date in the top left corner
      return (
        <div className="custom-content">
          <button onClick={handleButtonClick} className="custom-button">
            Add Event
          </button>
        </div>
      );
    }
    return null;
  };

  const handleDeleteEvent = () => {
    dispatch(pageLoader(true));
    console.log("DeleteFunctionTriggers");
    dispatch(deleteEvent(oneEvent.id, setSuccessAlert, setErrorAlert));
    modalClose(false);
  };

  const handleButtonClick = () => {
    // Handle button click for the selected date (customize as needed)
    alert(`Button clicked for selected date: ${selectedDate.toISOString()}`);
  };

  const handleDayRender = (arg) => {
    arg.el.innerHTML = renderCustomContent(arg);
  };

  function closeModal(data) {
    modalClose(false);
  }

  return (
    <div>
      {
        <Popper
          // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
          open={open}
          placement={viewType === "dayGridMonth" ? "right" : "top"}
          anchorEl={position}
          disablePortal={false}
          transition
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              // options: {
              //   element: arrowRef.current, // Use the arrowRef here
              // },
            },
            {
              name: "arrow1",
              enabled: true,
              // options: {
              //   element: arrow1Ref.current, // Use the arrowRef here
              // },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "relative",
                    bgcolor: "transparent", // Make the background transparent
                    borderRadius: 1,
                  }}
                  style={{
                    minWidth: width,
                    height: "max-content",
                    padding: "20px",
                    position: "relative",
                  }}
                >
                  {/* <div className={`${classes.arrow}`} ref={arrowRef}></div> */}
                  {/* <div className={`${classes.arrow}`} ref={arrowRef}></div>
                  <div className={`${classes.arrow1}`} ref={arrow1Ref}></div> */}
                  <div className={`${classes.forgetPasswordmodalclose}`}>
                    <Button
                      variant="text"
                      sx={{ minWidth: "unset" }}
                      className={"closebtn"}
                      onClick={() => closeModal()}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                  <Typography className={classes.eventTitleText}>
                    {oneEvent.title}
                  </Typography>
                  <div className={`mt-3 ${classes.subcontentContainer}`}>
                    <div className={classes.subcontentItems}>
                      <span>
                        <EventReminderIcon />
                      </span>
                      <Typography className={classes.timeText}>
                        30 min before
                      </Typography>
                    </div>
                    <div className={classes.subcontentItems}>
                      <span>
                        <EventModalCalendarIcon />
                      </span>
                      <Typography className={classes.timeText}>
                        {moment(oneEvent.date_time).format("DD MMM YYYY")}
                      </Typography>
                    </div>
                    <div className={classes.subcontentItems}>
                      <span>
                        <EventModalTimePickerIcon />
                      </span>
                      <Typography className={classes.timeText}>
                        {" "}
                        {moment(oneEvent.date_time).format("h:mm a")}
                      </Typography>
                    </div>
                  </div>
                  <Divider className={`mt-3 ${classes.divider}`} />
                  <div className={`mt-3 ${classes.viewModalBtnContainer}`}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleDeleteEvent()}
                      className={classes.viewModalBtn}
                    >
                      <DeleteBinIcon />
                      <span>Remove</span>
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleEditClick()}
                      className={classes.viewModalBtn}
                    >
                      <EditPenIcon />
                      <span>Edit</span>
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      }
    </div>
  );
};

export default EventViewModal;
