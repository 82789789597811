import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Box,
  InputAdornment,
  Autocomplete,
  TextField,
  List,
  ListSubheader,
  Divider,
} from "@mui/material";
import { CloseIcon, DiamondIcon } from "../Assets/Icons/icons";
import classes from "./index.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  AlphaNumeric,
  Alphabetic,
  Numeric,
} from "../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getAllDesignations } from "../Redux/actions/teacherActions";
import AlertBox from "./AlertBox";
import { getAllAccessLevel } from "../Redux/actions/roleManagementAction";

const InputModal = ({ modalClose, type, callback }) => {
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const [errorAlert, setErrorAlert] = useState(false);
  const [gameName, setGameName] = useState("");
  const [description, setDescription] = useState("");
  const [accessLevel, setAccessLevel] = useState("");
  const [designation, setDesignation] = useState("");
  const [noOfDiamonds, setNoOfDiamonds] = useState();
  const [levelName, setLevelName] = useState("");
  const { error, designations } = useSelector((state) => state.teacherDatas);
  const { accessLevelList } = useSelector((state) => state.roleDatas);

  useEffect(() => {
    if (type === "addDesignation") {
      dispatch(getAllDesignations());
    } else if (type === "addAccessLevel") {
      dispatch(getAllAccessLevel());
    }
  }, [type, dispatch]);
  // const [errorAlert, setErrorAlert] = useState(false);
  // const [successAlert, setSuccessAlert] = useState(false);

  function closeModal(data) {
    modalClose(false);
    setModalToggle(false);
  }

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleSubmit = () => {
    let userEntry;
    if (type === "addGame") {
      userEntry = {
        name: gameName,
        diamonds: noOfDiamonds,
      };
    } else if (type === "addLevel") {
      userEntry = {
        name: levelName,
      };
    } else if (type === "addDesignation") {
      userEntry = {
        name: designation,
      };
    } else if (type === "addAccessLevel") {
      userEntry = {
        name: accessLevel,
        description: description,
      };
    }
    callback(userEntry);
    closeModal();
  };

  const handleAutocompleteChange = (e, value) => {
    // Store the Autocomplete value in a separate state
    setDesignation(value);
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ minWidth: 368, minHeight: "fit-content" }}
          className={"loginModalWidth"}
        >
          <Button
            variant="text"
            sx={{ minWidth: "unset" }}
            className={classes.closebtn}
            onClick={() => closeModal()}
          >
            <CloseIcon />
          </Button>
          <div style={{ padding: 32, height: "100%" }}>
            <ValidatorForm
              useref="form"
              onSubmit={handleSubmit}
              className={classes.formInputContainer}
            >
              {type === "addGame" && (
                <div>
                  <Typography className={classes.headingText}>
                    Add Game/Topic
                  </Typography>
                  <div className="mt-3">
                    <Typography className={`${classes.formLable} mt-1`}>
                      Game Name
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="gameNameInput"
                      fullWidth
                      placeholder="Enter game name"
                      className={classes.TextInput}
                      value={gameName}
                      size="small"
                      onChange={(e) => {
                        setGameName(e.target.value);
                      }}
                      validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                      errorMessages={[
                        "Game Name is required",
                        "Not a valid Game Name",
                      ]}
                    />
                  </div>
                  <div className="mt-2">
                    <Typography className={`${classes.formLable} mt-1`}>
                      Diamond
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="diamondInput"
                      fullWidth
                      placeholder="Enter no. of Diamond"
                      className={classes.TextInput}
                      value={noOfDiamonds}
                      onChange={(e) => {
                        setNoOfDiamonds(e.target.value);
                      }}
                      onKeyPress={(e) => Numeric(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DiamondIcon />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Diamond is required"]}
                    />
                  </div>
                </div>
              )}
              {type === "addLevel" && (
                <div>
                  <Typography className={classes.headingText}>
                    Add Game Level
                  </Typography>
                  <div className="mt-3">
                    <Typography className={`${classes.formLable} mt-1`}>
                      Game Level
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="gameNameInput"
                      fullWidth
                      placeholder="Enter Level name"
                      className={classes.TextInput}
                      value={levelName}
                      size="small"
                      onChange={(e) => {
                        setLevelName(e.target.value);
                      }}
                      validators={["required"]} // Add this line
                      errorMessages={["Game Level is required"]}
                    />
                  </div>
                </div>
              )}
              {type === "addDesignation" && (
                <div>
                  <Typography className={classes.headingText}>
                    Add Designation
                  </Typography>
                  <div className="mt-3">
                    <Typography className={`${classes.formLable} mt-1`}>
                      Designation
                    </Typography>
                    <Autocomplete
                      // inputValue={designation}
                      // onInputChange={handleAutocompleteChange}
                      disableClearable
                      options={designations.map((option) => option.name)}
                      fullWidth
                      id="free-solo-demo"
                      freeSolo
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          placeholder="Enter Designation Name"
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                          onKeyPress={(e) => {
                            Alphabetic(e);
                          }}
                          validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                          errorMessages={[
                            "Designation is required",
                            "Not a valid Designation Name",
                          ]}
                        />
                      )}
                      ListboxComponent={(props) => (
                        <List {...props}>
                          <ListSubheader className={classes.OptionsHEading}>
                            Available Options
                          </ListSubheader>
                          <Divider
                            style={{
                              margin: "5px 0",
                              borderColor: "var(--options-divider-color)",
                              borderWidth: "1px",
                            }}
                          />
                          {props.children}
                        </List>
                      )}
                      classes={{
                        option: `${classes.successModalSubHeading} ${classes.textRansform}`,
                      }}
                      getOptionLabel={(option) => option}
                    />
                  </div>
                </div>
              )}
              {type === "addAccessLevel" && (
                <div>
                  <Typography className={classes.headingText}>
                    Add Access Level
                  </Typography>
                  <div className="mt-3">
                    <Typography className={`${classes.formLable} mt-1`}>
                      Access Level
                    </Typography>
                    <Autocomplete
                      // inputValue={designation}
                      // onInputChange={handleAutocompleteChange}
                      disableClearable
                      id="free-solo-demo"
                      freeSolo
                      options={accessLevelList.map(
                        (option) => `${option.name} - ${option.description}`
                      )}
                      fullWidth
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          placeholder="Enter Access Level Name"
                          onKeyPress={(e) => {
                            AlphaNumeric(e);
                          }}
                          validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                          errorMessages={[
                            "Access Level is required",
                            "Not a valid Access Level",
                          ]}
                          value={accessLevel}
                          onChange={(e) => setAccessLevel(e.target.value)}
                        />
                      )}
                      ListboxComponent={(props) => (
                        <List {...props}>
                          <ListSubheader className={classes.OptionsHEading}>
                            Available Access Level
                          </ListSubheader>
                          <Divider
                            style={{
                              margin: "5px 0",
                              borderColor: "var(--options-divider-color)",
                              borderWidth: "1px",
                            }}
                          />
                          {props.children}
                        </List>
                      )}
                      classes={{
                        option: `${classes.successModalSubHeading} ${classes.textRansform}`,
                      }}
                      getOptionLabel={(option) => option}
                    />
                  </div>
                  <div className="mt-3">
                    <Typography className={`${classes.formLable} mt-1`}>
                      Description
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="descriptionInput"
                      fullWidth
                      placeholder="Enter Description"
                      className={classes.TextInput}
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                      errorMessages={[
                        "Desscription is required",
                        "Not a valid Description",
                      ]}
                    />
                  </div>
                </div>
              )}
              <div className={`${classes.InputModalBtnSection} mt-3`}>
                <Button
                  variant="outlined"
                  style={{ color: "#6B75E0" }}
                  onClick={() => closeModal()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ background: "#6B75E0" }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </Box>
      </Modal>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default InputModal;
