import React, { useState } from "react";
import classes from "./notification.module.css";
import { Avatar, Button, Divider, Typography } from "@mui/material";
import {
  generateColor,
  stringAvatar,
} from "../../../Utilities/commonFunctions";
import moment from "moment";
import { DeleteBinIcon } from "../../../Assets/Icons/icons";
import { deleteNotification } from "../../../Redux/actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../../Components/AlertBox";

const NotificationCard = ({ notification }) => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { success, error } = useSelector((state) => state.notificationsData);
  const { userInfo } = useSelector((state) => state.userLogin);

  const handleDeleteNotification = () => {
    console.log("notification = ", notification);
    dispatch(
      deleteNotification(
        notification._id,
        userInfo._id,
        setSuccessAlert,
        setErrorAlert
      )
    );
  };

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  return (
    <div className={classes.cardMaindiv}>
      {notification &&
      notification?.senderInfo?.profile &&
      notification?.senderInfo?.profile?.filename ? (
        <Avatar
          alt="Remy Sharp"
          src={`${process.env.REACT_APP_IMAGE_URL}/${notification?.senderInfo?.profile?.destination}${notification?.senderInfo?.profile?.filename}`}
        />
      ) : (
        <Avatar
          {...stringAvatar(
            `${
              notification?.senderInfo?.name
                ? notification?.senderInfo?.name.split("-")[0].trim()
                : "SUPER ADMIN"
            }`
          )}
          sx={{
            bgcolor: `${generateColor(
              `${
                notification?.senderInfo?.name
                  ? notification?.senderInfo?.name.split("-")[0].trim()
                  : "SUPER ADMIN"
              }`
            )}`,
            width: 60,
            height: 60,
            fontSize: 16,
            fontWeight: 600,
          }}
        />
      )}
      <div>
        <Typography className={classes.cardTopic}>
          <span>{notification?.subject}</span>
          <span className={classes.dividerClass} />
          <span className={classes.cardTimeText}>
            {moment(notification?.createdAt).format("DD MMM YYYY, h:mm a")}
          </span>
        </Typography>
        <Typography className={classes.cardMessage}>
          {notification?.message}
        </Typography>
      </div>
      <div className={classes.hover_button_container}>
        <Button
          variant="text"
          className={classes.markAsReadBtn}
          onClick={() => handleDeleteNotification(notification._id)}
        >
          <DeleteBinIcon />
        </Button>
      </div>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default NotificationCard;
