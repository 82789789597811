import { authApi, getAuthorizationHeader } from "../../Api/api";
import {
  DOWNLOAD_INVOICE_FAIL,
  DOWNLOAD_INVOICE_SUCCESS,
  GENERATE_ORDER_FAIL,
  GENERATE_ORDER_SUCCESS,
  GET_CUSTOMER_SUBSCRIPTION_LIST_FAIL,
  GET_CUSTOMER_SUBSCRIPTION_LIST_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  GET_ORDER_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
} from "../contants/subscriptionConstants";
import { PAYMENT_FAIL, PAYMENT_SUCCESS } from "../contants/userConstants";
import { pageLoader, paymentSuccessApi } from "./userActions";

export const getOrderDetails = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/customer-subscription/get-order-details/${id}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    console.log("data", data);
    dispatch(pageLoader(false));
    if (data.success && data.data.length > 0) {
      dispatch({
        type: GET_ORDER_DETAILS_SUCCESS,
        payload: data.data[0],
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: GET_ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const getInvoiceDetails = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/customer-subscription/get-one-invoice-details/${id}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    console.log("data", data);
    dispatch(pageLoader(false));
    if (data.success && data.data.length > 0) {
      dispatch({
        type: PAYMENT_SUCCESS,
        payload: data.data[0],
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: PAYMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const getCustomerSubscriptionList =
  (id, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/customer-subscription/get-customer-subscription/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      // console.log("data", data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GET_CUSTOMER_SUBSCRIPTION_LIST_SUCCESS,
          payload: data.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: GET_CUSTOMER_SUBSCRIPTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const getAllSubscriptionList = (setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/customer-subscription/get-all-subscriptions?type=B2B`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    console.log("data", data);
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_SUBSCRIPTION_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: GET_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const GenerateOrder =
  (userEntry, setErrorAlert, navigate) => async (dispatch) => {
    try {
      const { data } = await authApi.post(
        `/customer-subscription/generate-order`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log("data", data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GENERATE_ORDER_SUCCESS,
          payload: data.data,
        });
        dispatch(getOrderDetails(userEntry?.user_id, setErrorAlert));
        navigate("/payment-details");
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: GENERATE_ORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const downloadReceipt =
  (id, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/customer-subscription/get-invoicedownload/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      dispatch({
        type: DOWNLOAD_INVOICE_SUCCESS,
        payload: "Email template downloaded successfully",
      });
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      console.log(blob);
      const backendUrl = `${process.env.REACT_APP_API_URL}/customer-subscription/get-invoicedownload/${id}`;

      // Create a link element
      const a = document.createElement("a");
      a.href = backendUrl;
      a.download = "invoice.xlsx"; // Optional: Set the desired file name
      document.body.appendChild(a);

      // Trigger a click event on the link to initiate the download
      a.click();
      setSuccessAlert(true);
    } catch (error) {
      dispatch({
        type: DOWNLOAD_INVOICE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };


  export const getCouponWithCode = (coupon_id,user_id,setApplyCouponChecked,applyCouponChecked,setCouponInfo,setCouponErr) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/coupon/${coupon_id}/${user_id}`);
      console.log(data?.data);
      if (data?.success) {
        setApplyCouponChecked(false);
        setCouponInfo(data?.data[0]);
        localStorage.setItem("coupon_info",data?.data[0]?.discount);
      }
    } catch (error) {
      setCouponErr(error.response && error.response.data.message
        ? error.response.data.message
        : error.message);
      // dispatch({
      //   type: DOWNLOAD_INVOICE_FAIL,
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
      dispatch(pageLoader(false));
    }
  };