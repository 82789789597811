import React, { useState } from "react";
import classes from "./courses.module.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { SortIcon, SortIconOpposite } from "../../../Assets/Icons/icons";
import CourseListItem from "./CourseListItem";
import DefaultPagination from "../../../Components/Pagination";
import NodataFound from "../../../Components/nodataFound";

const AddCourseTable = ({
  value,
  data,
  existGames,
  handleSingleChange,
  handleMainChange,
  selectedCourses,
}) => {
  const dispatch = useDispatch();
  const { page, sortBy } = useSelector((state) => state.userLogin);
  const [checked, setChecked] = useState(false);
  const totalCount = 2;

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
    handleMainChange(event.target.checked);
    renderTabledata();
  };

  const renderTabledata = () => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <CourseListItem
            item={item}
            key={index}
            status={checked}
            existGames={existGames}
            from="add"
            handleSingleChange={handleSingleChange}
            selectedCourses={selectedCourses}
          />
        );
      });
    }
  };

  return (
    <div>
      {data && data.length > 0 ? (
        <div>
          <div className={`mb-3 ${classes.TableContainer}`}>
            <Table className={classes.TableClass}>
              <TableHead>
                <TableRow className={classes.TableHeaderRow}>
                  <TableCell
                    className={classes.firstCell}
                    style={{ width: 48 }}
                  >
                    <Checkbox
                      checked={selectedCourses.length === data.length}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 125 }}>
                    <span className={classes.TableHeaderText}>Game Name</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "gameInfo.name")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("gameInfo.name") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 135 }}>
                    <span className={classes.TableHeaderText}>
                      Total Diamond
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "gameInfo.diamonds")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("gameInfo.diamonds") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 125 }}>
                    <span className={classes.TableHeaderText}>
                      No. of Level
                    </span>
                    <span
                      onClick={() => handleSortClick(dispatch, sortBy, "level")}
                    >
                      {sortBy?.includes("desc") && sortBy?.includes("level") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>Status</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "isActive")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("isActive") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell style={{ width: "60px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTabledata()}</TableBody>
            </Table>
          </div>
        </div>
      ) : (
        <NodataFound text={"Please add Games to view in this Topic"} />
      )}
    </div>
  );
};

export default AddCourseTable;
