import React, { useState } from "react";
import classes from "./index.module.css";
import {
  TextField,
  InputAdornment,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SearchIcon } from "../../../Assets/Icons/icons";

const HelpPage = () => {
  const [search, setSearch] = useState("");
  return (
    <div>
      <TextField
        sx={{
          "& legend": {
            display: "none",
          },
          "& fieldset": {
            top: 0,
          },
        }}
        fullWidth
        placeholder="Search By"
        className={`${classes.TextInput} ${classes.searchBar}`}
        id="Searchinput"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography
        className={`mt-4 ${classes.SubMainHeading2}`}
        style={{ paddingLeft: "20px" }}
      >
        Problems
      </Typography>
      <div className={`mt-4 ${classes.AccordianContainer}`}>
        {[1, 2, 3, 4].map((item, index) => (
          <Accordion key={index} className={classes.AccordianTab}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.SubMainHeading2}>{`${
                index + 1
              }.   What is lorem ipsum? `}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.someNrmlText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque commodo mi dolor, sed viverra ipsum gravida vel.
                Morbi gravida viverra nisi, vel rhoncus enim suscipit. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                commodo mi dolor, sed viverra ipsum gravida vel. Morbi gravida
                viverra nisi, vel rhoncus enim suscipit.
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default HelpPage;
