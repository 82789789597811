import React, { useRef, useState } from "react";
import classes from "./student.module.css";
import {
  Modal,
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icons/icons";

const BulkUploadSuccessModal = ({ type, data, modalClose }) => {
  const [modalToggle, setModalToggle] = useState(true);
  const rootRef = useRef(null);

  const closeModal = () => {
    setModalToggle(false);
    modalClose();
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ padding: "35px 0 0" }}
          className={"loginModalWidth"}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={"closebtn"}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </Button>
          </div>
          <Typography
            className={`mb-3 ${classes.StudentPageMainHeading}`}
            style={{ textAlign: "center" }}
          >
            Upload Result
          </Typography>
          <div className={classes.successModalTableContainer}>
            <Table className={classes.TableClass}>
              <TableHead>
                <TableRow className={classes.TableHeaderRow}>
                  <TableCell
                    flex={1}
                    className={classes.firstCell}
                    style={{ minWidth: "135px" }}
                  >
                    <span className={classes.TableHeaderText}>
                      Student Name
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "80px" }}>
                    <span className={classes.TableHeaderText}>Message</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      flex={1}
                      className={classes.firstCell}
                      style={{ minWidth: "135px" }}
                    >
                      <span className={classes.TableBodyText}>{item.name}</span>
                    </TableCell>
                    <TableCell
                      flex={1}
                      style={{
                        minWidth: "80px",
                      }}
                    >
                      <span
                        className={classes.TableBodyText}
                        style={{
                          color: item.status === 201 ? "green" : "red",
                        }}
                      >
                        {item.message}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BulkUploadSuccessModal;
