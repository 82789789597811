import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./layout.module.css";
import mainLogo from "../Assets/Images/MainLogo.png";
import {
  CalendarIconWhite,
  ChatIcon,
  ClassIcon,
  CourseIcon,
  DashboardIcon,
  LogoutIcon,
  PaymentIcon,
  ProfileNavIcon,
  RoleIcon,
  StudentIcon,
  TeacherIcon,
  NotificationBellIcon,
  HelpNavIcon,
} from "../Assets/Icons/icons";
import profileIconImage from "../Assets/Images/Img_Avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Typography } from "@mui/material";
import { setHeaderName } from "../Redux/actions/userActions";
import { useEffect } from "react";
import SuccessModal from "../Components/successModal";
import smallLogo from "../Assets/Images/favicon-512x512.png";

const SideNavBar = ({ device, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const { toggleSideBar, userInfo, userRole } = useSelector(
    (state) => state.userLogin
  );
  const [currentRoute, setCurrentRoute] = useState();

  useEffect(() => {
    setCurrentRoute(window.location.pathname.toLowerCase().split("/")[1]);
  }, [window.location.pathname]);

  const handleNavigate = (name, route) => {
    if (name.includes("cources")) {
      dispatch(setHeaderName("Courses/Games"));
    } else {
      dispatch(setHeaderName(name));
    }
    setCurrentRoute(route);
    if (device === "mobile") {
      handleClose();
    }
  };

  const handleClickProfile = () => {
    dispatch(setHeaderName("Profile"));
    navigate("/profile");
  };

  const handleClickLogout = (e) => {
    setLogoutModal(true);
  };

  const modalClose = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
  };
  console.log("userRole =",userRole)
  return (
    <div>
      {toggleSideBar ? (
        <img
          src={mainLogo}
          alt="Metaverse Logo"
          className={`${classes.imageLogo} ${classes.sidenavLogoImage}`}
        />
      ) : (
        <img
          src={smallLogo}
          alt="Metaverse Logo"
          className={`${classes.smallImageLogo} ${classes.sidenavLogoImage}`}
        />
      )}
      <div className={`${classes.sidenavListMainCointainer}`}>
        <div
          className={`${toggleSideBar ? `mt-5` : `mt-2`} ${
            classes.nabBarContainer
          }`}
          style={{
            minHeight: toggleSideBar ? 420 : 480,
          }}
          // style={{ minHeight: "750px" }}
        >
          <Link
            to="/dashboard"
            className={
              currentRoute?.includes("dashboard") && userRole === "TEACHER"
                ? `${classes.activebtn}`
                : currentRoute?.includes("dashboard")
                ? `${classes.activebtn1}`
                : ``
            }
            onClick={() => handleNavigate("dashboard", "/dashboard")}
          >
            <li>
              <Button
                variant="text"
                className={
                  userRole === "TEACHER"
                    ? `${classes.linkburron}`
                    : `${classes.linkburron1}`
                }
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <DashboardIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Dashboard</span>
                ) : null}
              </Button>
            </li>
          </Link>
          {userRole === "SCHOOL" && (
            <Link
              to="/class-management"
              className={
                currentRoute?.includes("class") && userRole === "TEACHER"
                  ? `${classes.activebtn}`
                  : currentRoute?.includes("class")
                  ? `${classes.activebtn1}`
                  : ``
              }
              onClick={() =>
                handleNavigate("class-management", "/class-management")
              }
            >
              <li>
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.linkburron}`
                      : `${classes.linkburron1}`
                  }
                >
                  <span
                    className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                  >
                    <ClassIcon />
                  </span>{" "}
                  {toggleSideBar || device === "mobile" ? (
                    <span className={classes.sidebarText}>Class</span>
                  ) : null}
                </Button>
              </li>
            </Link>
          )}
          {( userRole === "SCHOOL" || userInfo?.permissions?.some(element => /student/.test(element)) ) &&
            
            <Link
            to="/students"
            className={
              currentRoute?.includes("student") && userRole === "TEACHER"
                ? `${classes.activebtn}`
                : currentRoute?.includes("student")
                ? `${classes.activebtn1}`
                : ``
            }
            onClick={() => handleNavigate("students", "/students")}
          >
            <li>
              <Button
                variant="text"
                className={
                  userRole === "TEACHER"
                    ? `${classes.linkburron}`
                    : `${classes.linkburron1}`
                }
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <StudentIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Students</span>
                ) : null}
              </Button>
            </li>
          </Link>}
          {userRole === "SCHOOL" && (
            <Link
              to="/teachers"
              className={
                currentRoute?.includes("teacher") && userRole === "TEACHER"
                  ? `${classes.activebtn}`
                  : currentRoute?.includes("teacher")
                  ? `${classes.activebtn1}`
                  : ``
              }
              onClick={() => handleNavigate("teachers", "/teachers")}
            >
              <li>
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.linkburron}`
                      : `${classes.linkburron1}`
                  }
                >
                  <span
                    className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                  >
                    <TeacherIcon />
                  </span>{" "}
                  {toggleSideBar || device === "mobile" ? (
                    <span className={classes.sidebarText}>Teachers</span>
                  ) : null}
                </Button>
              </li>
            </Link>
          )}
          { ( userRole === "SCHOOL" || userInfo?.permissions?.some(element => /course/.test(element)) ) &&
            <Link
            to="/cources"
            className={
              currentRoute?.includes("cource") && userRole === "TEACHER"
                ? `${classes.activebtn}`
                : currentRoute?.includes("cource")
                ? `${classes.activebtn1}`
                : ``
            }
            onClick={() => handleNavigate("cources", "/cources")}
          >
            <li>
              <Button
                variant="text"
                className={
                  userRole === "TEACHER"
                    ? `${classes.linkburron}`
                    : `${classes.linkburron1}`
                }
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <CourseIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Courses/Games</span>
                ) : null}
              </Button>
            </li>
          </Link>}
          {userRole === "SCHOOL" && (
            <Link
              to="/role-management"
              className={
                currentRoute?.includes("role") && userRole === "TEACHER"
                  ? `${classes.activebtn}`
                  : currentRoute?.includes("role")
                  ? `${classes.activebtn1}`
                  : ``
              }
              onClick={() =>
                handleNavigate("role-management", "/role-management")
              }
            >
              <li>
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.linkburron}`
                      : `${classes.linkburron1}`
                  }
                >
                  <span
                    className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                  >
                    <RoleIcon />
                  </span>{" "}
                  {toggleSideBar || device === "mobile" ? (
                    <span className={classes.sidebarText}>Role Management</span>
                  ) : null}
                </Button>
              </li>
            </Link>
          )}
          {/* {userRole === "SCHOOL" && (
            <Link
              to="/chat"
              className={
                currentRoute?.includes("chat") && userRole === "TEACHER"
                  ? `${classes.activebtn}`
                  : currentRoute?.includes("chat")
                  ? `${classes.activebtn1}`
                  : ``
              }
              onClick={() => handleNavigate("chat", "/chat")}
            >
              <li>
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.linkburron}`
                      : `${classes.linkburron1}`
                  }
                >
                  <span
                    className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                  >
                    <ChatIcon />
                  </span>{" "}
                  {toggleSideBar || device === "mobile" ? (
                    <span className={classes.sidebarText}>Chat</span>
                  ) : null}
                </Button>
              </li>
            </Link>
          )} */}
          {/* {userRole === "SCHOOL" && (
            <Link
              to="/payment-details"
              className={
                currentRoute?.includes("payment") && userRole === "TEACHER"
                  ? `${classes.activebtn}`
                  : currentRoute?.includes("payment")
                  ? `${classes.activebtn1}`
                  : ``
              }
              onClick={() => handleNavigate("payment", "/payment")}
            >
              <li>
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.linkburron}`
                      : `${classes.linkburron1}`
                  }
                >
                  <span
                    className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                  >
                    <PaymentIcon />
                  </span>{" "}
                  {toggleSideBar || device === "mobile" ? (
                    <span className={classes.sidebarText}>Payment</span>
                  ) : null}
                </Button>
              </li>
            </Link>
          )} */}
          {userRole === "TEACHER" && (
            <Link
              to="/calendar"
              className={
                currentRoute?.includes("calendar") && userRole === "TEACHER"
                  ? `${classes.activebtn}`
                  : currentRoute?.includes("calendar")
                  ? `${classes.activebtn1}`
                  : ``
              }
              onClick={() => handleNavigate("calendar", "/calendar")}
            >
              <li>
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.linkburron}`
                      : `${classes.linkburron1}`
                  }
                >
                  <span
                    className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                  >
                    <CalendarIconWhite />
                  </span>{" "}
                  {toggleSideBar || device === "mobile" ? (
                    <span className={classes.sidebarText}>Calendar</span>
                  ) : null}
                </Button>
              </li>
            </Link>
          )}
        </div>
        <Link
          to="/notification"
          className={
            currentRoute?.includes("notification") && userRole === "TEACHER"
              ? `${classes.activebtn}`
              : currentRoute?.includes("notification")
              ? `${classes.activebtn1}`
              : ``
          }
          onClick={() => handleNavigate("notification", "/notification")}
        >
          <li>
            <Button
              variant="text"
              className={
                userRole === "TEACHER"
                  ? `${classes.linkburron}`
                  : `${classes.linkburron1}`
              }
            >
              <span className={`${classes.activeIcon1} ${classes.navBarIcon}`}>
                <NotificationBellIcon />
              </span>{" "}
              {toggleSideBar || device === "mobile" ? (
                <span className={classes.sidebarText}>Notification</span>
              ) : null}
            </Button>
          </li>
        </Link>
        <Link
          to="/help"
          className={
            currentRoute?.includes("help") && userRole === "TEACHER"
              ? `${classes.activebtn}`
              : currentRoute?.includes("help")
              ? `${classes.activebtn1}`
              : ``
          }
          onClick={() => handleNavigate("help", "/help")}
        >
          <li>
            <Button
              variant="text"
              className={
                userRole === "TEACHER"
                  ? `${classes.linkburron}`
                  : `${classes.linkburron1}`
              }
            >
              <span className={`${classes.activeIcon1} ${classes.navBarIcon}`}>
                <HelpNavIcon />
              </span>{" "}
              {toggleSideBar || device === "mobile" ? (
                <span className={classes.sidebarText}>Help</span>
              ) : null}
            </Button>
          </li>
        </Link>
        {userRole === "TEACHER" && (
          <div className={classes.ProfileManagementBtnsContainer}>
            <Button
              variant="text"
              className={
                userRole === "TEACHER"
                  ? `${classes.linkburron}`
                  : `${classes.linkburron1}`
              }
              onClick={handleClickLogout}
            >
              <span className={`${classes.activeIcon1} ${classes.navBarIcon}`}>
                <LogoutIcon />
              </span>{" "}
              {toggleSideBar || device === "mobile" ? (
                <span className={classes.sidebarText}>Logout</span>
              ) : null}
            </Button>
            <Button
              variant="outlined"
              className={
                toggleSideBar || device === "mobile"
                  ? `d-flex align-items-center justify-content-between ${classes.ProfileBtn}`
                  : `d-flex align-items-center justify-content-between ${classes.ProfileBtnSmall}`
              }
              onClick={() => handleClickProfile()}
            >
              <div className="d-flex align-items-center">
                <Avatar alt="Remy Sharp" src={profileIconImage} />
                {toggleSideBar || device === "mobile" ? (
                  <Typography className={classes.ProfileAvatarBtnText}>
                    Admin
                  </Typography>
                ) : null}
              </div>
              {toggleSideBar || device === "mobile" ? (
                <span>
                  <ProfileNavIcon />
                </span>
              ) : null}
            </Button>
          </div>
        )}
      </div>
      {logoutModal && (
        <SuccessModal
          modalClose={modalClose}
          width={335}
          height={153}
          type={"logout"}
          callback={handleLogout}
        />
      )}
    </div>
  );
};

export default SideNavBar;
