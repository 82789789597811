import { TableRow, TableCell, Button, MenuItem, Chip } from "@mui/material";
import classes from "./classManagement.module.css";
import React, { useState } from "react";
import { StyledMenu } from "../../../Components/CommonMenu";
import { useNavigate } from "react-router-dom";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  EditPenIcon,
  InactiveItemIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import AlertBox from "../../../Components/AlertBox";
import {
  changeSectionStatus,
  deleteClass,
} from "../../../Redux/actions/classActions";
import SchoolActionModals from "../../../Components/SchoolActionModals";

const ClassListItem = ({ item, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const open = Boolean(anchorEl);
  const [selectedSection, setSelectedsection] = useState();
  const { error, success } = useSelector((state) => state.classDatas);

  const handleClick = (event, section) => {
    event.stopPropagation();
    console.log("SelectedSection", section);
    setAnchorEl(event.currentTarget);
    setSelectedsection(section);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleActive = (event) => {
    event.stopPropagation();
    handleClose(event);
    setType("active/inactive");
    setHeight(205);
    setModalToggle(true);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setType("delete");
    setHeight(205);
    setModalToggle(true);
    handleClose(event);
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleRowClick = () => {
    navigate(`/view-class/${item._id}`);
    dispatch(setHeaderName("view-class"));
  };

  const handleEditClassDetails = (event) => {
    handleClose(event);
    dispatch(setHeaderName("edit-class"));
    navigate(`/edit-class/${item._id}`);
    setSelectedsection();
  };

  const handleDeleteClick = () => {
    dispatch(pageLoader(true));
    dispatch(
      deleteClass(
        item._id,
        selectedSection.section,
        item.schoolId,
        setSuccessAlert,
        setErrorAlert
      )
    );
    setSelectedsection();
    modalClose();
  };

  const handleChangeStatus = (event) => {
    dispatch(pageLoader(true));
    dispatch(
      changeSectionStatus(
        item._id,
        selectedSection?.section,
        item.schoolId,
        setErrorAlert,
        setSuccessAlert
      )
    );
    setSelectedsection();
    modalClose();
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  return (
    <React.Fragment key={index}>
      <TableRow onClick={handleRowClick}>
        <TableCell rowSpan={item.terminology?.length + 1}>
          {item.gradeId}
        </TableCell>
        <TableCell
          rowSpan={item.terminology?.length + 1}
          style={{ textTransform: "capitalize" }}
        >
          {item.type === "YEARS"
            ? `${item.grade} Years`
            : item.grade.charAt(0).toUpperCase() +
              item.grade.slice(1).toLowerCase().replaceAll("_", " ")}
        </TableCell>
      </TableRow>
      {item?.terminology?.map((section, index) => (
        <TableRow key={section.section} onClick={handleRowClick}>
          <TableCell
            className={classes.SectionsRowFirstTab}
            style={{ textTransform: "capitalize" }}
          >
            {section.section.charAt(0).toUpperCase() +
              section.section.slice(1).toLowerCase().replaceAll("_", " ")}
          </TableCell>
          <TableCell>{section?.numberOfStudent}</TableCell>
          <TableCell>
            <Chip
              icon={
                section.isActive ? <ActiveItemIcon /> : <InactiveItemIcon />
              }
              style={{
                background: section.isActive
                  ? "var(--active-chip-background)"
                  : "var(--inactive-chip-background)",
              }}
              label={section.isActive ? "Active" : "Inactive"}
              className={classes.StatusChip}
            />
          </TableCell>
          <TableCell style={{ width: "40px" }}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={(e) => handleClick(e, section)}
              variant="text"
              style={{
                minWidth: 0,
              }}
            >
              <ThreeDotMenuIcon />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleEditClassDetails}
                disableRipple
                style={{ height: "40px" }}
              >
                <EditPenIcon />
                <span className={classes.MenuItemText}>Edit Details</span>
              </MenuItem>
              <MenuItem
                onClick={handleDelete}
                disableRipple
                style={{ height: "40px" }}
              >
                <DeleteBinIcon />
                <span className={classes.MenuItemText}>Delete</span>
              </MenuItem>
              <MenuItem
                onClick={handleActive}
                disableRipple
                style={{ height: "40px" }}
              >
                <SwitchIcon />
                <span className={classes.MenuItemText}>Active/Inactive</span>
              </MenuItem>
            </StyledMenu>
          </TableCell>
        </TableRow>
      ))}
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          modalClose={modalClose}
          item={selectedSection}
          name={
            selectedSection?.section.charAt(0).toUpperCase() +
            selectedSection?.section.slice(1).toLowerCase().replaceAll("_", " ")
          }
          callback={type === "delete" ? handleDeleteClick : handleChangeStatus}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </React.Fragment>
  );
};

export default ClassListItem;
