import {
    ADD_EVENT_FAIL,
    ADD_EVENT_SUCCESS,
    GET_EVENTS_FAIL,
    GET_EVENTS_SUCCESS,
    GET_ONE_EVENT_FAIL,
    GET_ONE_EVENT_SUCCESS,
} from "../contants/eventsConstants";

const initialState = {
    error: null,
    success: null,
    eventsList: [],
    oneEvent: null,
    totalCount: 0,
};

export const eventReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EVENT_FAIL:
            return { ...state, error: action.payload };
        case ADD_EVENT_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_EVENTS_SUCCESS:
            return { ...state, error: null, eventsList: action.payload };
        case GET_EVENTS_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_EVENT_SUCCESS:
            return { ...state, error: null, oneEvent: action.payload };
        case GET_ONE_EVENT_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};