import React, { useEffect, useState } from "react";
import classes from "./student.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderName } from "../../../Redux/actions/userActions";
import {
  BrudCrumsNextIcon,
  CheckCircleIcon,
  DownloadCertificateIcon,
  EditPenIcon,
} from "../../../Assets/Icons/icons";
import { Box, Card, Typography, LinearProgress } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ResetPasswordModal from "./resetPasswordModal";
import { getOneStudent } from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "110px", mr: 1 }}>
        <LinearProgress
          className={classes.Studentprogress}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography className={classes.TableBodyText}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const StudentsViewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetModal, setResetModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { error, oneStudent } = useSelector((state) => state.studentDatas);
  const { success, userRole } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    dispatch(getOneStudent(arr[arr.length - 1], setErrorAlert));
  }, []);

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  const handleNavigateStudent = () => {
    dispatch(setHeaderName("students"));
    navigate("/students");
  };

  const handleResetPassword = () => {
    setResetModal(true);
  };

  const handleEditStudentDetails = () => {
    dispatch(setHeaderName("edit-student-details"));
    navigate(`/edit-student-details/${oneStudent._id}`);
  };

  const ModalClose = () => {
    setResetModal(false);
  };

  return (
    <div>
      <div className={`${classes.BrudcrumbContainer}`}>
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateStudent()}
          >
            Students
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          <span
            className={`${classes.textLable}`}
          >{`${oneStudent?.first_name} ${oneStudent?.last_name}`}</span>
        </div>
        <div
          className={classes.brudcrumbs}
          onClick={() => {
            handleEditStudentDetails();
          }}
        >
          <span>
            <EditPenIcon />
          </span>
          <span className={`${classes.TableBodyText} ${classes.editText}`}>
            Edit Details
          </span>
        </div>
      </div>
      {oneStudent && (
        <div>
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.StudentPageMainHeading}>
              Basic Details
            </Typography>
            <Row style={{ marginTop: "10px", rowGap: "15px" }}>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Student ID
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.student_id}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Student First Name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {`${oneStudent?.first_name}`}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Student Last Name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {`${oneStudent?.last_name}`}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Age
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.age}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Grade
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneStudent?.gradeInfo?.grade?.charAt(0).toUpperCase() +
                      oneStudent?.gradeInfo?.grade
                        ?.slice(1)
                        .toLowerCase()
                        .replaceAll("_", " ")}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Section
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneStudent?.section?.charAt(0).toUpperCase() +
                      oneStudent?.section
                        ?.slice(1)
                        .toLowerCase()
                        .replaceAll("_", " ")}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Parent Name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.parent?.name}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Email ID
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.parent?.email}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Contact Number
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    +44 {oneStudent?.parent?.phone}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Interested Section
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    Block Coding
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Completed Game
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent.completed_game
                      ? oneStudent.completed_game
                      : "-"}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Progress
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    <LinearProgressWithLabel value={30} />
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
          {userRole === "TEACHER" && (
            <Card
              className={`mt-3`}
              style={{ padding: "20px", textAlign: "left" }}
            >
              <Typography className={classes.StudentPageMainHeading}>
                School Details
              </Typography>
              <Row>
                <Col md={4}>
                  <div className="mt-3">
                    <Typography className={`${classes.textLable}`}>
                      School Name
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      {oneStudent?.schoolInfo?.name}
                    </Typography>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mt-3">
                    <Typography className={`${classes.textLable}`}>
                      School Contact Number
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      {oneStudent?.schoolInfo?.phone}
                    </Typography>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mt-3">
                    <Typography className={`${classes.textLable}`}>
                      School Email ID
                    </Typography>
                    <Typography
                      className={classes.MenuItemText}
                      style={{ marginLeft: 0 }}
                    >
                      {oneStudent?.schoolInfo?.email}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Card>
          )}
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.StudentPageMainHeading}>
              Subscription Information
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Subscription Level
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.subscriptionInfo?.subscription_name}
                  </Typography>
                </div>
              </Col>
              <Col md={8}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Subscription Details
                  </Typography>
                  <div className={`mt-3 ${classes.certificatesContainer}`}>
                    <Row>
                      <Col md={4}>
                        <Typography
                          className={classes.MenuItemText}
                          style={{ marginLeft: 0 }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <CheckCircleIcon />
                          </span>
                          3 Unlocked Topics
                        </Typography>
                      </Col>
                      <Col md={4}>
                        <Typography
                          className={classes.MenuItemText}
                          style={{ marginLeft: 0 }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <CheckCircleIcon />
                          </span>
                          Lorem ipsum dolor sit amet
                        </Typography>
                      </Col>
                      <Col md={4}></Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Typography
                          className={classes.MenuItemText}
                          style={{ marginLeft: 0 }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <CheckCircleIcon />
                          </span>
                          Lorem ipsum dolor sit amet
                        </Typography>
                      </Col>
                      <Col md={4}>
                        <Typography
                          className={classes.MenuItemText}
                          style={{ marginLeft: 0 }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <CheckCircleIcon />
                          </span>
                          Lorem ipsum dolor sit amet
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      )}
      {resetModal && (
        <ResetPasswordModal
          modalClose={ModalClose}
          height={"434px"}
          width={"368px"}
          email={oneStudent?.parent?.email}
          setSuccessAlert={setSuccessAlert}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default StudentsViewPage;
